import React, { useState, useEffect, useContext } from 'react'

import NissForm from './NissForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Switch, Card, Tooltip, Divider, Button } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { getStudentsPrivacyInformations } from '../../../reducers/StudentsExtraInfos'
import { CARPOOL_TYPE, DATA_SHARING_TYPE, Optin } from '../../../utils/entities/optin'
import { connect } from 'react-redux'
import { createUserOptin, updateUserOptin } from '../../../utils/api/user'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { onError, onSuccess } from '../../../utils/apiHelper'
import CarpoolingRegionModal from '../CarpoolingRegionModal'
import { isObject } from 'lodash'
import { getStudentExtraInformations } from '../../../utils/api/student'
import { StudentExtraInfo, TYPE_REGION } from '../../../utils/entities/studentExtraInfo'

import '../../../assets/profile-page.scss'

const DATA_USAGE_TITLE = 'Use of my data'
const DATA_USAGE_TIPS_TEXT = "Manage the sharing of your Interneo's data with other parties."

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    privacyInformations: getStudentsPrivacyInformations(state.studentsExtraInformations)
  }
}

const PrivacyInformations = ({ t, user, privacyInformations }) => {
  const { optins, fetchOptins } = useContext(GlobalContext)

  const [dataSharingOptin, setDataSharingOptin] = useState(null)
  const [carpoolOptin, setCarPoolOptin] = useState(null)
  const [displayRegionModal, setDisplayRegionModal] = useState(false)
  const [carpoolingRegions, setCarpoolingRegions] = useState([])

  useEffect(() => {
    if (isObject(user.student)) {
      getStudentExtraInformations(user.student, user, { type: TYPE_REGION }).then(json => {
        if (json?.data) {
          setCarpoolingRegions(json.data.map(d => {
            const extra = new StudentExtraInfo(d)

            extra.deleted = false
            extra.dirty = false

            return extra
          }))
        }
      })
    } else {
      setCarpoolingRegions([])
    }
  }, [user.student?.id])

  useEffect(() => {
    optins.forEach(optin => {
      if (optin.type === DATA_SHARING_TYPE) {
        setDataSharingOptin(new Optin(optin))
      } else if (optin.type === CARPOOL_TYPE) {
        setCarPoolOptin(new Optin(optin))
      }
    })
  }, [optins])

  const onSwitchChange = (optin, type) => {
    if (optin === null) {
      const newOptin = new Optin({ accepted: true, text: t(type) })

      createUserOptin(newOptin, user).then(json => {
        if (json?.data) {
          onSuccess(t('The optin has been successfully created'))
        } else {
          onError(t('An error occured during the optin creation'))
        }

        fetchOptins(user)
      })
    } else {
      const newOptin = optin.clone()

      newOptin.accepted = !optin.accepted

      updateUserOptin(newOptin, user).then(json => {
        if (json?.data) {
          onSuccess(t('The optin has been successfully updated'))
        } else {
          onError(t('An error occured during the optin update'))
        }

        fetchOptins(user)
      })
    }
  }

  return (
    <div className='profile-page-personnal'>
      <div className='profile-page-card-div'>
        <Card
          title={t('Personal information')}
        >
          <div className='inner-margin'>
            <Card
              type='inner'
              title={[t(DATA_USAGE_TITLE), ' ',
                <Tooltip key='personal_section_tooltip' placement='right' title={t(DATA_USAGE_TIPS_TEXT)}>
                  <FontAwesomeIcon icon={faQuestionCircle} color='#1890ff' />
                </Tooltip>]}
            >
              <div>
                <Switch key='recruitement' checked={dataSharingOptin?.accepted} onChange={() => onSwitchChange(dataSharingOptin, 'data_sharing_optin_text')} />
                &nbsp;
                {t('for recruitment purposes')}
                &nbsp;
                <Tooltip key='carpool_tooltip' placement='right' title={t('data_sharing_optin_text')}>
                  <FontAwesomeIcon icon={faQuestionCircle} color='#1890ff' />
                </Tooltip>
              </div>
              <Divider />
              <div>
                <Switch key='carPool' checked={carpoolOptin?.accepted} onChange={() => onSwitchChange(carpoolOptin, 'carpool_optin_text')} />
                &nbsp;
                {t('for carpool purposes')}
                &nbsp;
                <Tooltip key='carpool_tooltip' placement='right' title={t('carpool_optin_text')}>
                  <FontAwesomeIcon icon={faQuestionCircle} color='#1890ff' />
                </Tooltip>
              </div>
            </Card>
          </div>
          <NissForm />
          {carpoolOptin?.accepted && (
            <div className='carpooling-region-container'>
              <div className='carpooling-region-title'>
                <div>
                  <h3> {t('Carpooling regions')} </h3>
                  <Tooltip key='personal_section_tooltip' placement='right' title={t('profile_page.carpooling_title.tooltip')}>
                    <FontAwesomeIcon icon={faQuestionCircle} color='#1890ff' />
                  </Tooltip>
                </div>
                <Button onClick={() => setDisplayRegionModal(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </div>
              <div className='carpooling-region-body'>
                {carpoolingRegions.length > 0 && (
                  <ul>
                    {carpoolingRegions.map((cr, index) => {
                      return (
                        <li key={'cr_' + index}>
                          {cr.value}
                        </li>
                      )
                    })}
                  </ul>
                )}
                {carpoolingRegions.length === 0 && (
                  <span className='empty-information-span'>
                    {t('You have not defined a preferred region for carpooling')}
                  </span>
                )}
              </div>
            </div>
          )}
        </Card>
      </div>
      <CarpoolingRegionModal
        regions={carpoolingRegions}
        visible={displayRegionModal}
        onCancel={() => setDisplayRegionModal(false)}
        onSave={resultingCarpoolRegions => {
          setDisplayRegionModal(false)
          setCarpoolingRegions(resultingCarpoolRegions)
        }}
      />
    </div>
  )
}

export default connect(mapStateToProps)(PrivacyInformations)
