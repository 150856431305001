import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'

const IconButton = ({ className = '', disabled, ghost, icon, size, text = '', type, onClick }) => {
  const localText = useMemo(() => (<span> &nbsp; {text} </span>), [text])

  return (
    <Button
      className={className}
      disabled={disabled ?? false}
      size={size ?? 'default'}
      type={type ?? 'default'}
      ghost={ghost}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />
      {localText}
    </Button>
  )
}

export default IconButton
