import React from 'react'

import { stringOptionFilter } from '../../utils/SelectOptionTools'
import { Col, InputNumber, Row, Select, Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const Option = Select.Option
const SELECT_STYLE = { width: '200px' }
const TOOLTIP_PLACEMENT = 'top'

const PLACES_TITLE = 'Allocated places'
const SECTION_TITLE = 'Section'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const PlaceAllocationForm = ({ section, disabled, places, sections, t, onPlacesChanged, onSectionSelected }) => {
  const handlePlacesChanging = places => {
    if (Number.isInteger(places)) {
      onPlacesChanged(places)
    }

    if (places === '') {
      onPlacesChanged(0)
    }
  }
  const renderSelectOptions = () => {
    const options = []

    if (sections) {
      sections.forEach((section, index) => {
        options.push(
          <Option key={`option_${index}`} value={section.abbreviation} data={section}>
            <Tooltip placement={TOOLTIP_PLACEMENT} title={section.name}>
              {section.abbreviation}
            </Tooltip>
          </Option>
        )
      })
    }

    return options
  }

  return (
    <div className='allocate-quota-form'>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {t(PLACES_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <InputNumber
            value={places ?? 0}
            onChange={handlePlacesChanging}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {t(SECTION_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <Select
            style={SELECT_STYLE}
            value={section ? section.abbreviation : '/'}
            showSearch
            onSelect={(value, option) => onSectionSelected(option.props.data)}
            filterOption={stringOptionFilter}
            disabled={disabled}
          >
            {renderSelectOptions()}
          </Select>
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps)(PlaceAllocationForm)
