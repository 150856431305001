import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'

import { getActiveLanguage } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../reducers/UserReducer'
import { recursiveIncludes } from '../utils'
import { getInstitution, getInstitutionSectors } from '../utils/api/institution'
import { ROLE_HOSPITAL_ADMIN, ROLE_NURSE } from '../utils/constants'
import useRequest from '../hooks/useRequest'
import { getAllStudents } from '../utils/api/student'
import { getAllActTypes } from '../utils/api/actType'

const mapStateToProps = state => {
  return {
    currentLanguage: getActiveLanguage(state.locale).code,
    user: getUser(state.getUser)
  }
}

export const InstitutionContext = createContext()

const InstitutionProvider = ({ children, currentLanguage, user }) => {
  const [institution, setInstitution] = useState(null)
  const [institutionGroup, setInstitutionGroup] = useState(null) // The group for which the current institution is a part
  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const students = useRequest({ request: getAllStudents, parameters: useMemo(() => [user, { paginate: false, dataUsage: true, contexts: ['niss'] }], [user?.id]) })
  const [schools, setSchools] = useState([])
  const [users, setUsers] = useState([])
  const [rawSectors, setRawSectors] = useState([])
  const [actTypes, setActTypes] = useState([])
  const [loading, setLoading] = useState(true)

  const sectors = useMemo(() => {
    const sectors = {
      active: [],
      archived: []
    }

    rawSectors.forEach(s => {
      if (s.archived) {
        sectors.archived.push(s)
      } else {
        sectors.active.push(s)
      }
    })

    return sectors
  }, [rawSectors])

  const fetchInstitution = useCallback(() => {
    getInstitution(user, user.institutions[0]).then(json => {
      if (json?.data) {
        setInstitution(json.data)
        setInstitutionGroup(json.data.institutionGroup)
        setSchools(json.data.schools ?? [])
        setUsers(json.data.users ?? [])
      }

      setLoading(false)
    })
  }, [user.id, user.roles, setInstitution, setInstitutionGroup, setLoading, setSchools])

  const fetchActTypes = useCallback(() => {
    getAllActTypes(user, { orderBy: 'acronym' }).then(json => {
      if (json?.data) {
        setActTypes(json.data)
      }
    })
  }, [user.id])

  useEffect(() => {
    if (user && recursiveIncludes(user.roles, [ROLE_NURSE, ROLE_HOSPITAL_ADMIN])) {
      setLoading(true)
      fetchInstitution()
    } else {
      setLoading(false)
    }
  }, [user.roles, fetchInstitution, setLoading])

  useEffect(() => {
    if (institution) {
      getInstitutionSectors(user, institution, { strictMode: 1 }).then(json => {
        if (json?.data) {
          setRawSectors(json.data)
        }
      })
    }
  }, [user.id, institution?.id])

  return (
    <InstitutionContext.Provider
      value={{
        actTypes,
        archivedSectors: sectors.archived,
        sectors: sectors.active,
        institution,
        institutionGroup,
        institutionStudents: students,
        loading,
        reload: fetchInstitution,
        loadActTypes: fetchActTypes,
        schools,
        users,
        selectedInstitution,
        setSchools,
        setUsers,
        setSelectedInstitution
      }}
    >
      {children}
    </InstitutionContext.Provider>
  )
}

export default connect(mapStateToProps)(InstitutionProvider)
