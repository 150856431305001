import React, { useState, useEffect } from 'react'

import QuotaAllocationForm from './QuotaAllocationForm'
import PopoverIconButton from '../shared/PopoverIconButton'
import TooltipIconButton from '../shared/TooltipIconButton'
import QuickDualDatePickers from '../shared/QuickDualDatePickers'
import { FULL_DATE_WITHOUT_TIMEZONE } from '../../../../utils/constants'
import { Card } from 'antd'
import { faEdit, faTrash, faSave, faBan, faCopy } from '@fortawesome/free-solid-svg-icons'
import { getFrontDate } from '../../../../utils/apiHelper'

const POPOVER_CONTENT = 'Are you sure you want to delete this quota?'

const QuotaCard = ({ quota, remainingPlaces, schools, schoolYears, onDelete, onDuplicate, onSave }) => {
  const [editionMode, setEditionMode] = useState(false)
  const [currentQuota, setCurrentQuota] = useState(null)
  const [initialQuota, setInitalQuota] = useState(null)

  useEffect(() => {
    if (quota) {
      setCurrentQuota({ ...quota })
      setInitalQuota(quota)
    }
  }, [quota])

  const handleQuotaSaving = () => {
    setInitalQuota({ ...currentQuota })
    setEditionMode(false)

    const { school, sectionToYear } = currentQuota

    onSave({
      ...currentQuota,
      school: { id: school.id },
      sectionToYear: sectionToYear ? { id: sectionToYear.id } : null
    })
  }

  const handleDatesChange = dates => {
    setCurrentQuota({
      ...currentQuota,
      startDate: dates[0].format(FULL_DATE_WITHOUT_TIMEZONE),
      endDate: dates[1].format(FULL_DATE_WITHOUT_TIMEZONE)
    })
  }

  const handleEditionCanceling = () => {
    setCurrentQuota({ ...initialQuota })
    setEditionMode(false)
  }

  const updateQuota = (value, property) => {
    const newQuota = { ...currentQuota }

    newQuota[property] = value.id === -1 ? null : value

    setCurrentQuota(newQuota)
  }

  const getCardActions = () => {
    if (editionMode) {
      return (
        [
          <TooltipIconButton
            key='card-save-button'
            title='Save'
            onClick={() => { handleQuotaSaving() }}
            disabled={false}
            icon={faSave}
          />,
          <TooltipIconButton
            key='card-cancel-button'
            title='Cancel'
            onClick={() => { handleEditionCanceling() }}
            icon={faBan}
          />
        ]
      )
    }

    return ([
      <TooltipIconButton
        key='card-edit-button'
        title='Edit'
        onClick={() => { setEditionMode(true) }}
        icon={faEdit}
      />,
      <TooltipIconButton
        key='card-duplicate-button'
        title='Duplicate'
        onClick={() => { onDuplicate(quota) }}
        icon={faCopy}
      />,
      <PopoverIconButton
        key='card-trash-button'
        title='Delete'
        tooltipTitle='Delete'
        content={POPOVER_CONTENT}
        onConfirm={() => { onDelete(quota) }}
        icon={faTrash}
      />
    ])
  }

  const getSchoolsById = id => {
    return id
      ? schools.find(school => school.id === id)
      : null
  }

  const getSchoolYearById = id => {
    return id
      ? schoolYears.find(year => year.id === id)
      : null
  }

  return (
    <Card
      style={{ width: '100%', marginBottom: '10px' }}
      actions={getCardActions()}
    >
      {currentQuota && (
        <div className='allocation-quota-card-body'>
          <QuickDualDatePickers
            startDate={getFrontDate(currentQuota.startDate)}
            endDate={getFrontDate(currentQuota.endDate)}
            onDatesChange={handleDatesChange}
            disabled={!editionMode}
          />
          <QuotaAllocationForm
            modification={editionMode}
            disabled={!editionMode}
            places={currentQuota.total}
            initialPlaces={initialQuota.total}
            remainingPlaces={remainingPlaces}
            schools={schools}
            schoolYears={schoolYears}
            selectedSchool={getSchoolsById(currentQuota.school?.id)}
            selectedSchoolYear={getSchoolYearById(currentQuota.sectionToYear?.id)}
            onPlacesChanged={places => updateQuota(places, 'total')}
            onSchoolSelected={(value, option) => updateQuota(option.props.data, 'school')}
            onSchoolYearSelected={(value, option) => updateQuota(option.props.data, 'sectionToYear')}
          />
        </div>
      )}
    </Card>
  )
}

export default QuotaCard
