import React from 'react'

import TooltipIconButton from '../shared/TooltipIconButton'
import QuickDualDatePickers from '../shared/QuickDualDatePickers'
import { Card, Col, Row } from 'antd'
import { faFile, faPlus } from '@fortawesome/free-solid-svg-icons'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'
import HintedSwitch from '../../../antd/switch/HintedSwitch'
import { QUOTA_PER_PAUSE } from '../../../../utils/constants'

const PLACES_TEXT = 'Available places'
const DEFAULT_PLUS_BUTTON_TEXT = 'Allocate places'
const PAUSE_PLUS_BUTTON_TEXT = 'Allocate places to pause'
const DETAILS_BUTTON_TEXT = 'Display details of global places'
const TOOLTIP_TEXT = 'Attribute places in function of pauses'

const GlobalQuotaCard = props => {
  const { pauseActivated } = props.getAppStates
  const institutionOptions = props.getInstitutionOptions
  let quotaPerPauseEnable = false

  institutionOptions.forEach(option => {
    if (option.optionType.type === QUOTA_PER_PAUSE) {
      quotaPerPauseEnable = true
    }
  })

  const handlePlacesAllocation = () => {
    if (typeof props.onPlacesAllocated === 'function') {
      props.onPlacesAllocated()
    }
  }

  const handleGlobalPlacesDisplaying = () => {
    if (typeof props.onGlobalPlacesDisplayed === 'function') {
      props.onGlobalPlacesDisplayed()
    }
  }

  const handleSwitchChecked = checked => {
    props.setPauseActivationState(checked)
  }

  const getCardActions = () => {
    const actions = [
      <TooltipIconButton
        key='card-allocate-button'
        title={pauseActivated ? PAUSE_PLUS_BUTTON_TEXT : DEFAULT_PLUS_BUTTON_TEXT}
        onClick={handlePlacesAllocation}
        disabled={false}
        icon={faPlus}
      />,
      <TooltipIconButton
        key='card-display-button'
        title={DETAILS_BUTTON_TEXT}
        onClick={handleGlobalPlacesDisplaying}
        icon={faFile}
      />
    ]

    if (quotaPerPauseEnable) {
      actions.push(
        <HintedSwitch
          onChange={handleSwitchChecked}
          checked={pauseActivated}
          tooltipTitle={TOOLTIP_TEXT}
        />
      )
    }

    return actions
  }

  const generateTotalPlaces = () => {
    if (typeof props.total === 'number' && typeof props.remaining === 'number') {
      return `${props.remaining} / ${props.total}`
    }

    return ''
  }

  const renderBody = () => {
    return (
      <div className='allocation-quota-card-body'>
        <QuickDualDatePickers
          startDate={props.startDate}
          endDate={props.endDate}
          disabled
        />
        <Row>
          <Col span={12}>
            <h4>
              <b>
                {props.t(PLACES_TEXT)}
              </b>
            </h4>
          </Col>
          <Col span={12}>
            <b>{generateTotalPlaces()}</b>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <Card
      style={{ width: '100%' }}
      actions={getCardActions()}
    >
      {renderBody()}
    </Card>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalQuotaCard)
