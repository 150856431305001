import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faBusinessTime, faCalendarAlt, faChartPie, faFileExport, faFilter, faPlus, faSort } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Dropdown, Menu, notification, Select, Tooltip } from 'antd'
import Search from 'antd/lib/input/Search'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { SCHOOL_CONTEXT } from '../../../utils/constants'
import NewMessagesNotification from '../NewMessagesNotification'
import InternshipsManagerActionsHeaderExportModal from '../InternshipsManagerActionsHeaderExportModal'
import { checkIfJsonIsCorrect, downloadFile, generalErrorHandler, requestWithPromise } from '../../../utils'
import { getAppStates } from '../../../reducers/AppStates/actions'
import { HTTP_INTERNAL_ERROR } from '../../../utils/http'
import InternshipStatesOrderBy from '../InternshipStatesOrderBy'
import PaginationHeader from './PaginationHeader'
import WeekNavigator from './WeekNavigator'
import CurrentWeekButton from './CurrentWeekButton'
import { DEFAULT_USER_STATUS, getUserStatusByRole } from './utils/utils'
import { DATE_FORMAT_SHOWN } from './utils/constants'
import { bindActionCreators } from 'redux'
import { toggleMessageList } from '../../../reducers/NewMessagesReducer'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { withRouter } from 'react-router-dom'
import routes from '../../../routes'
import PeriodSelector from './PeriodSelector'
import { COLORS_PALETTE } from '../../../config/colors'
import StatesSelector from './StatesSelector'

import './assets/internships-manager.scss'

const IN_ADD_MODE_TEXT = 'Hide the add internship buttons'
const NOT_ADD_MODE_TEXT = 'Show the internships adding buttons'
const CURRENT_WEEK_BUTTON_TEXT = 'Go back to current week'
const EXPORT_BUTTON_TEXT = 'Export timeline'

export const PRECISION_YEAR = 'year'
const PRECISION_SCHOOL = 'school'
const PRECISION_SECTION = 'section'
const PRECISION_SECTOR = 'sector'
const PRECISION_OPTIONS = [
  { value: PRECISION_SECTOR, label: 'Quotas per sector' },
  { value: PRECISION_SECTION, label: 'Quotas per section' },
  { value: PRECISION_SCHOOL, label: 'Quotas per school' },
  { value: PRECISION_YEAR, label: 'Default quotas' }
]

const SEARCH_FIELD_STYLE = { width: 175 }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    currentDate: getAppStates(state).date
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleMessages: bindActionCreators(toggleMessageList, dispatch)
  }
}

const InternshipsHeader = ({
  addMode, search, orderBy, sortDirection, states, weeksShownCount, currentDate, page, history, precision, metadata,
  loading, loadShiftsManager, user, onDateChange, onSearchChange, onOrderByChange, onPageChange,
  onPrecisionChange, onSortDirectionChange, onStatesChange, onWeeksShowChange, t, toggleAddMode,
  toggleMessages, onResetFilters
}) => {
  const { setCurrentDate } = useContext(GlobalContext)

  const [userStatus, setUserStatus] = useState(DEFAULT_USER_STATUS)
  const [displayAddingButton, setDisplayAddingButton] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [displayExportModal, setDisplayExportModal] = useState(false)
  const [precisionOptions, setPrecisionOptions] = useState([])

  const dropDownMenu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item key='state' disabled style={{ cursor: 'default' }}>
          <span><FontAwesomeIcon icon={faBusinessTime} color={COLORS_PALETTE.SECOND} /> {t('Internship')}</span>
          <StatesSelector onCheck={onStatesChange} values={states} />
        </Menu.Item>

        <div className='h-spacing' />

        <Menu.Item key='order' disabled style={{ cursor: 'default' }}>
          <span><FontAwesomeIcon icon={faSort} color={COLORS_PALETTE.SECOND} /> {t('Order by')}</span>
          <InternshipStatesOrderBy
            direction={sortDirection}
            value={orderBy}
            onChange={onOrderByChange}
            onDirectionChange={onSortDirectionChange}
          />
        </Menu.Item>

        {user.context.includes('HOSPITAL') && (
          <Menu.Item key='precision' disabled style={{ cursor: 'default' }}>
            <span><FontAwesomeIcon icon={faChartPie} color={COLORS_PALETTE.SECOND} /> {t('Quotas')}</span><br />
            <Select value={precision} onChange={onPrecisionChange} style={{ width: '210px' }}>
              {precisionOptions.map((o, index) => {
                return (
                  <Select.Option key={'opt_' + index} value={o.value}>
                    {o.label}
                  </Select.Option>
                )
              })}
            </Select>
          </Menu.Item>
        )}

        <div className='h-spacing' />

        <Menu.Item key='config' disabled style={{ cursor: 'default', paddingBottom: 0 }}>
          <span><FontAwesomeIcon icon={faCalendarAlt} color={COLORS_PALETTE.SECOND} /> {t('Period')}</span><br />
          <PeriodSelector
            currentWeeksQuantity={weeksShownCount}
            onWeeksQuantityChange={onWeeksShowChange}
          />
        </Menu.Item>
        <Divider style={{ marginTop: '9px', marginBottom: '9px' }} />
        <div className='center'>
          <Button
            type='primary'
            onClick={onResetFilters}
            disabled={loading}
            ghost
            style={{ marginBottom: '5px' }}
          >
            {t('Reset filters')}
          </Button>
        </div>
      </Menu>
    )
  }, [
    t, states, sortDirection, orderBy, precision, weeksShownCount, precisionOptions, user.context, onStatesChange, onOrderByChange, onSortDirectionChange,
    onPrecisionChange, onWeeksShowChange, onResetFilters, loading
  ])

  const exportTimer = useRef()
  const clearingTimeout = useRef()

  useEffect(() => {
    if (user) {
      setUserStatus(getUserStatusByRole(user))
    }
  }, [user])

  useEffect(() => {
    setDisplayAddingButton(
      userStatus.context === SCHOOL_CONTEXT &&
      !(userStatus.isSupervisor || userStatus.isValidator)
    )
  }, [userStatus])

  useEffect(() => {
    setPrecisionOptions(PRECISION_OPTIONS.map(o => {
      return { ...o, label: t(o.label) }
    }))
  }, [t])

  const handleNewMessageClick = () => {
    toggleMessages()
  }

  const handleExportTimeline = (startDate, endDate, checked, filter) => {
    setDisplayExportModal(false)
    setExportLoading(true)

    const body = {
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD'),
      language: user.language
    }

    if (checked) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          if (typeof body.filter === 'undefined') {
            body.filter = {}
          }

          body.filter[key] = filter[key]
        }
      })
    }

    requestWithPromise(
      '/internship/export-timeline',
      'POST',
      body,
      user
    ).then(json => {
      exportTimer.current = setInterval(() => getJobResult(json), 1500)
      clearingTimeout.current = setTimeout(() => {
        clearInterval(exportTimer.current)
        setExportLoading(false)
        notification.error({ message: t('Internships export failed'), placement: 'bottomRight' })
      }, 20000)
    })
  }

  const getJobResult = json => {
    // Security in case of export failed to prevent crash loop
    if (!json) {
      clearInterval(exportTimer.current)
      clearTimeout(clearingTimeout.current)
      setExportLoading(false)

      notification.error({ message: t('Internships export failed'), placement: 'bottomRight' })

      return
    }

    requestWithPromise(`/job-result/${json.job}`, 'GET', null, user, { catchError: false }
    ).then(json => {
      if (checkIfJsonIsCorrect(json)) {
        clearInterval(exportTimer.current)
        clearTimeout(clearingTimeout.current)
        setExportLoading(false)

        const fileName = json.data.result

        try {
          downloadFile(
            '/spreadsheet/download/' + fileName,
            fileName,
            user,
            'GET'
          )
        } catch (err) {
          generalErrorHandler(err)

          notification.error({ message: t('Spreadsheet download failed'), placement: 'bottomRight' })
        }
      }
    }).catch(response => {
      if (response.status === HTTP_INTERNAL_ERROR) {
        clearInterval(exportTimer.current)
        clearTimeout(clearingTimeout.current)
        setExportLoading(false)
      }
    })
  }

  return (
    <div className='internships-manager-header'>
      <div className='actions-and-filters'>
        <Search
          value={search}
          placeholder={t('Filter internships')}
          onChange={e => { onSearchChange(e.target.value) }}
          style={SEARCH_FIELD_STYLE}
          className='app-tour-2'
        />
        <div className='h-spacing' />
        <Dropdown overlay={dropDownMenu} trigger={['click']}>
          <Button>
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        </Dropdown>
        <NewMessagesNotification
          onClick={handleNewMessageClick}
        />
        <div className='flex-fill' />
        <Button
          type='primary'
          onClick={() => {
            setCurrentDate(currentDate)
            history.push(routes.SHIFTS_MANAGER)
          }}
          disabled={loading}
          ghost
        >
          <FontAwesomeIcon icon={faCalendar} />&nbsp;
          {t(userStatus.isValidator ? 'Validate schedules' : 'Edit schedules')}
        </Button>
        {displayAddingButton && <div className='h-spacing' />}
        {displayAddingButton && (
          <Tooltip
            key='internships'
            placement='top'
            title={t(addMode ? IN_ADD_MODE_TEXT : NOT_ADD_MODE_TEXT)}
          >
            <Button
              type='primary'
              className='app-tour-1'
              disabled={loading}
              onClick={toggleAddMode}
              ghost
            >
              <FontAwesomeIcon icon={faPlus} />
             &nbsp;
              {t('Add an internship')}
            </Button>
          </Tooltip>
        )}
      </div>
      <div className='table-modifier-buttons'>
        <PaginationHeader
          pageIndex={page}
          maxPageIndex={metadata.totalPages}
          dataType='Internship'
          dataCount={metadata.total}
          onPageChange={onPageChange}
        />
        <div className='flex-fill' />
        <WeekNavigator
          date={currentDate}
          dateFormat={DATE_FORMAT_SHOWN}
          onDateChange={onDateChange}
        />
        <div className='h-spacing' />
        <CurrentWeekButton
          title={t(CURRENT_WEEK_BUTTON_TEXT)}
          currentDate={currentDate}
          onClick={onDateChange}
          loading={loading}
        />
        <div className='h-spacing' />
        <Tooltip placement='top' title={t(EXPORT_BUTTON_TEXT)}>
          <Button
            onClick={() => setDisplayExportModal(true)}
            loading={exportLoading}
          >
            <FontAwesomeIcon icon={faFileExport} />
          </Button>
        </Tooltip>
      </div>
      {displayExportModal && (
        <InternshipsManagerActionsHeaderExportModal
          startDate={currentDate}
          weeksShownCount={weeksShownCount}
          filterSearch={search}
          filterStates={states}
          orderBy={orderBy}
          orderDirection={sortDirection}
          onCancel={() => setDisplayExportModal(false)}
          onExport={handleExportTimeline}
          visible
        />
      )}
    </div>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InternshipsHeader))
