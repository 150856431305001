import React, { useEffect, useRef, useState } from 'react'

import StandardMoveButton, { ADD_TIME, REMOVE_TIME } from './StandardMoveButton'
import { EUROPEAN_DATE } from '../../../../utils/constants'
import { DatePicker } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { numToWords } from '../../../../utils/string'
import { getDisplacementByPeriodType } from '../../../../utils/timePeriods'
import moment from 'moment'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const DisplacementDatePicker = ({ date, timePeriod, onDateChange, t }) => {
  const [verbosePeriod, setVerbosePeriod] = useState({ quantity: '', type: '' })
  const [localeDate, setLocaleDate] = useState(moment())

  const timer = useRef(null)

  useEffect(() => {
    if (date) {
      setLocaleDate(date.clone())
    }
  }, [date])

  useEffect(() => {
    if (timePeriod && typeof t === 'function') {
      const displacement = getDisplacementByPeriodType(timePeriod)
      const newVerbosePeriod = {
        quantity: numToWords(displacement.quantity),
        type: displacement.type
      }

      if (displacement.quantity > 1) {
        newVerbosePeriod.type += 's'
      }

      setVerbosePeriod(newVerbosePeriod)
    }
  }, [t, timePeriod])

  const handleDateChange = newDate => {
    if (timer.current !== null) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      onDateChange(newDate)
    }, 300)

    setLocaleDate(newDate)
  }

  return (
    <div className='flex-row'>
      <StandardMoveButton
        date={localeDate}
        move={REMOVE_TIME}
        periodType={timePeriod}
        tooltipTitle={t('Remove') + ' ' + t(`${verbosePeriod.quantity} ${verbosePeriod.type}`)}
        onClick={handleDateChange}
      />
      <div className='h-spacing' />
      <DatePicker
        format={EUROPEAN_DATE}
        value={localeDate}
        onChange={handleDateChange}
      />
      <div className='h-spacing' />
      <StandardMoveButton
        date={localeDate}
        move={ADD_TIME}
        periodType={timePeriod}
        tooltipTitle={t('Add') + ' ' + t(`${verbosePeriod.quantity} ${verbosePeriod.type}`)}
        onClick={handleDateChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(DisplacementDatePicker)
