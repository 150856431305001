import React, { useState, useEffect } from 'react'

import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING,
  DATA_TYPE_BOOLEAN,
  DATA_TYPE_TAGS_LIST
} from '../../Components/shared/SmartTable'
import { Drawer, Button, Modal } from 'antd'
import InstitutionSectorFilesManagement from './InstitutionSectorFilesManagement'
import { getTranslate } from 'react-localize-redux'
import { fetchTaggedEntities, getTaggedEntities, getTagsByEntityType, tagEntity } from '../../reducers/TagsReducer'
import { bindActionCreators } from 'redux'
import { getUser } from '../../reducers/UserReducer'
import { fetchSectorsFilesCount, getFilesCount } from '../../reducers/SectorsReducer/actions'
import { getSchools } from '../../reducers/SchoolsReducer'
import { connect } from 'react-redux'
import { validateFormInput, generalErrorHandler, requestWithPromise } from '../../utils'
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import ContactPersonSectors from './ContactPersonSectors'
import SectorNoteModal from './SectorNoteModal'
import { getInstitutionSectors } from '../../utils/api/institution'

const TAG_ENTITY_NAME = 'sector'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getFilesCount: getFilesCount(state),
    getSchools: getSchools(state.getSchools),
    getTaggedEntities: getTaggedEntities(state.tags),
    getUser: getUser(state.getUser)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSectorsFilesCount: bindActionCreators(fetchSectorsFilesCount, dispatch),
    fetchTaggedEntities: bindActionCreators(fetchTaggedEntities, dispatch),
    getTagsByEntityType: bindActionCreators(getTagsByEntityType, dispatch),
    tagEntity: bindActionCreators(tagEntity, dispatch)
  }
}

const InstitutionSectors = props => {
  const [institutionSectors, setInstitutionSectors] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedSectorFiles, setSelectedSectorFiles] = useState(null)
  const [tags, setTags] = useState([])
  const [taggedEntitiesReceived, setTaggedEntitiesReceived] = useState(false)
  const [forbiddenDeletionModalVisible, setForbiddenDeletionModalVisible] = useState(false)
  const [message, setMessage] = useState('')
  const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false)
  const [selectedContactPersonsSector, setSelectedContactPersonsSector] = useState(null)
  const [sectorToNote, setSectorToNote] = useState(null)

  useEffect(() => {
    setUserIsSuperAdmin(props.getUser.context === 'ADMIN')
    fetchTaggedEntities()
  }, [tags, props.getUser])

  useEffect(() => {
    if (props.getTagsByEntityType) {
      setTags(props.getTagsByEntityType(TAG_ENTITY_NAME))
    }
  }, [props.getTagsByEntityType])

  useEffect(() => {
    if (props.institution !== null) {
      setLoading(true)

      props.fetchSectorsFilesCount(props.institution.id, props.getUser)
      getInstitutionSectors(props.getUser, props.institution).then(json => {
        if (json?.data) {
          setInstitutionSectors(json.data)
        }

        setLoading(false)
      })
    } else {
      setInstitutionSectors([])
    }
  }, [props.institution, props.getUser])

  const fetchTaggedEntities = async () => {
    try {
      if (tags.length) {
        await props.fetchTaggedEntities(TAG_ENTITY_NAME)
        setTaggedEntitiesReceived(true)
      }
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  const createSector = sector => {
    const body = {
      institution: props.institution.id,
      name: sector.name,
      acronym: sector.name,
      comment: sector.comment,
      emplacement: sector.emplacement,
      places: 0,
      description: sector.description,
      archived: false,
      pausesEnabled: sector.pausesEnabled ?? false
    }

    if (userIsSuperAdmin) {
      body.genericSector = sector.genericSector
    }

    requestWithPromise('/api/Sector', 'POST', body, props.getUser).then(json => {
      if (json?.data) {
        setInstitutionSectors([...institutionSectors, json.data])
      }
    })
      .catch(err => {
        generalErrorHandler(err)
      })
  }

  const updateSector = sector => {
    const body = {
      institution: props.institution.id,
      name: sector.name,
      comment: sector.comment,
      emplacement: sector.emplacement,
      places: 0,
      description: sector.description
    }

    if (userIsSuperAdmin) {
      body.genericSector = sector.genericSector
    }

    requestWithPromise(`/api/Sector/${sector.id}`, 'PATCH', body, props.getUser).then(json => {
      setInstitutionSectors(institutionSectors.map(s => {
        if (s.id === sector.id) s = json.data

        return s
      }))
    })
      .catch(err => {
        generalErrorHandler(err)
      })
  }

  const deleteSector = sector => {
    requestWithPromise(`/api/Sector/${sector.id}`, 'DELETE', null, props.getUser, { catchError: false })
      .then(json => {
        if (json.code === 403) {
          displayForbiddenDeletionModal(json.message)
        } else if (json.code >= 400) {
          throw new Error(json.message)
        } else {
          setInstitutionSectors(institutionSectors.filter(s => s.id !== sector.id))
        }
      })
      .catch(err => {
        generalErrorHandler(err)
      })
  }

  const displayForbiddenDeletionModal = message => {
    setForbiddenDeletionModalVisible(true)
    setMessage(message)
  }

  const handleForbiddenDeletionModalClose = () => {
    setForbiddenDeletionModalVisible(false)
  }

  const handleTagToggle = (tagId, row) => {
    props.tagEntity({ tagId, entityName: TAG_ENTITY_NAME, entityId: row.id })
  }

  const render = () => {
    const columns = [
      { type: DATA_TYPE_ID, name: props.t('Id'), key: 'id', keepShown: true },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Name'),
        key: 'name',
        validate: data => validateFormInput('freeText', data, true)
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Road'),
        key: 'emplacement'
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Comment for the students'),
        key: 'comment'
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Description'),
        key: 'description'
      }
    ]
    let additionalActions = [
      {
        iconName: 'file',
        type: 'primary',
        title: props.t('Manage files'),
        customClassCallback: data => { return data.fileCount > 0 ? 'green-button' : '' },
        onClick: setSelectedSectorFiles
      },
      {
        iconName: faPeopleArrows,
        type: 'primary',
        title: props.t('The contact persons for this sector'),
        onClick: setSelectedContactPersonsSector
      }
    ]

    if (tags.length && taggedEntitiesReceived) {
      columns.push({ type: DATA_TYPE_TAGS_LIST, key: 'tags', name: props.t('Tags'), onTagToggle: handleTagToggle })
    }

    if (userIsSuperAdmin) {
      columns.push({
        type: DATA_TYPE_BOOLEAN,
        name: props.t('Generic sector'),
        key: 'genericSector'
      })
    }

    const sectors = institutionSectors.map(s => {
      const sector = {
        id: s.id,
        name: s.name,
        emplacement: s.emplacement,
        comment: s.comment,
        description: s.description,
        fileCount: props.getFilesCount[s.id] ?? 0,
        genericSector: userIsSuperAdmin ? s.genericSector : undefined
      }
      if (tags.length && taggedEntitiesReceived) {
        sector.tags = tags.map(tag => ({ ...tag, active: props.getTaggedEntities.sector && props.getTaggedEntities.sector.find(item => item.tagId === tag.id && item.entityId === s.id) !== undefined }))
      }
      return sector
    })
    let userCanCrud = false
    let showAddButtonDisabled = false

    if (props.getSchools.length > 0) {
      const { lockedInstitutionsCount } = props.getSchools[0]

      userCanCrud =
        (typeof lockedInstitutionsCount === 'undefined' ||
        lockedInstitutionsCount === null ||
        lockedInstitutionsCount === 0) &&
        props.getUser.roles.indexOf('ROLE_ENCODER') > -1
    }

    if (props.getUser.roles.indexOf('ROLE_ENCODER') > -1) {
      additionalActions = [{
        iconName: 'edit',
        type: 'primary',
        title: props.t('Add a note'),
        onClick: sector => {
          document.activeElement.blur()
          setSectorToNote(sector)
        }
      }].concat(additionalActions)

      if (props.institution && !userCanCrud) {
        if (props.institution.managed) {
          showAddButtonDisabled = true
        } else {
          userCanCrud = true
        }
      }
    }

    let userCanManageSectorFiles = true
    if (props.institution && props.institution.managed) {
      userCanManageSectorFiles = false
    }

    // if user is a super admin, he has full access
    if (userIsSuperAdmin) {
      userCanCrud = true
      userCanManageSectorFiles = true
    }

    return (
      <Drawer
        title={
          props.institution !== null ? props.institution.name : ''
        }
        width='80%'
        onClose={props.onClose}
        visible={props.institution !== null}
      >
        {props.institution && (
          <SmartTable
            columns={columns}
            data={sectors}
            showAddButtonDisabled={showAddButtonDisabled}
            onDataAdd={userCanCrud ? createSector : undefined}
            onDataDelete={userCanCrud ? deleteSector : undefined}
            onDataEdit={userCanCrud ? updateSector : undefined}
            addDataText={props.t('Add a care unit')}
            loading={loading}
            noDataText={props.t(
              'This institution does not have any internship sector.'
            )}
            additionalActions={additionalActions}
          />
        )}
        <ContactPersonSectors
          sector={selectedContactPersonsSector}
          onClose={() => setSelectedContactPersonsSector(null)}
        />
        <InstitutionSectorFilesManagement
          sector={selectedSectorFiles}
          onClose={() => setSelectedSectorFiles(null)}
          preventChanges={!userCanManageSectorFiles}
        />
        <Modal
          title={props.t('Forbidden deletion of a Care Unit')}
          visible={forbiddenDeletionModalVisible}
          footer={
            <Button
              onClick={handleForbiddenDeletionModalClose}
              type='primary'
            > OK
            </Button>
          }
        >
          {props.t(message)}
        </Modal>
        <SectorNoteModal
          institution={props.institution}
          sector={sectorToNote}
          onClose={() => setSectorToNote(null)}
        />
      </Drawer>
    )
  }

  return render()
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionSectors)
