import React, { useState, useEffect, useMemo } from 'react'
import { Button, Drawer, Tooltip } from 'antd'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { request, generalErrorHandler } from '../../../utils'
import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING
} from '../../../Components/shared/SmartTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import UsersAssignModal from './UsersAssignModal'
import { INSTITUTION_CONTEXT } from '../../../utils/constants'

const SectorSectionUsers = props => {
  const [title, setTitle] = useState('')
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const roleNames = useMemo(() => [
    {
      name: props.t('Coordinator'),
      apiName: 'ROLE_COORDINATOR'
    },
    { name: props.t('Administrator'), apiName: 'ROLE_ENCODER' },
    { name: props.t('Supervisor'), apiName: 'ROLE_SUPERVISOR' },
    {
      name: props.t('Validator'),
      apiName: 'ROLE_VALIDATOR'
    },
    {
      name: props.t('Administrator'),
      apiName: 'ROLE_HOSPITAL_ADMIN'
    },
    {
      name: props.t('Nurse'),
      apiName: 'ROLE_NURSE'
    },
    {
      name: props.t('Admin'),
      apiName: 'ROLE_ADMIN'
    }
  ], [props.t])
  const columns = useMemo(() => [
    { type: DATA_TYPE_ID, key: 'id' },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Lastname'),
      key: 'lastname'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Firstname'),
      key: 'firstname'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Email'),
      key: 'email'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Role'),
      key: 'role'
    }
  ], [props.t])

  useEffect(() => {
    if (props.entity) {
      setLoading(true)

      setTitle(props.getUser.context === 'HOSPITAL' ? props.t('Care unit users') : props.t('Section users'))
      getUsers()
    }
  }, [props.entity])

  const extractRoleName = roles => {
    const rawRole = roles[0]
    const foundRoleName = roleNames.find(item => item.apiName === rawRole)
    return foundRoleName ? foundRoleName.name : props.t('None')
  }

  const getUsers = async () => {
    const requestUrl = props.getUser.context === 'HOSPITAL'
      ? `/api/Sector/${props.entity.id}/all`
      : `/api/school-sections/${props.entity.id}?coordinators=true`

    try {
      const { data } = await request(requestUrl, 'GET', null, props.getUser)

      if (data) {
        const usersToShow = []

        if (props.getUser.context === 'HOSPITAL') {
          if (data.managedBy && data.managedBy.length) {
            data.managedBy.forEach(user => {
              if (!usersToShow.find(existingUser => existingUser.id === user.id)) {
                usersToShow.push({ ...user, role: extractRoleName(user.roles) })
              }
            })
          }
          if (data.supervisedBy && data.supervisedBy.length) {
            data.supervisedBy.forEach(user => {
              if (!usersToShow.find(existingUser => existingUser.id === user.id)) {
                usersToShow.push({ ...user, role: extractRoleName(user.roles) })
              }
            })
          }
        } else {
          if (data.coordinators && data.coordinators.length) {
            data.coordinators.forEach(user => {
              if (!usersToShow.find(existingUser => existingUser.id === user.id)) {
                usersToShow.push({ ...user, role: extractRoleName(user.roles) })
              }
            })
          }
        }

        setUsers(usersToShow)
      }

      setLoading(false)
    } catch (err) {
      generalErrorHandler(err)
      setLoading(false)
    }
  }

  const handleDrawerClose = () => {
    if (typeof props.onClose === 'function') {
      setUsers([])
      props.onClose()
    }
  }

  const secondaryButtons = useMemo(() => {
    const secondaryButtons = []

    if (props.getUser.context === INSTITUTION_CONTEXT) {
      secondaryButtons.push(
        <Button key='assign-users-button' onClick={() => setIsVisible(!isVisible)}>
          <Tooltip placement='right' title='Assign users'>
            <FontAwesomeIcon icon={faUserPlus} />
          </Tooltip>
        </Button>
      )
    }

    return secondaryButtons
  }, [setIsVisible, props.t, isVisible, props.getUser.context])

  return (
    <Drawer
      title={title}
      width='50%'
      onClose={handleDrawerClose}
      visible={props.entity !== null}
    >
      <SmartTable
        secondaryButtons={secondaryButtons}
        columns={columns}
        data={users}
        loading={loading}
      />
      {isVisible && (
        <UsersAssignModal sector={props.entity} visible={isVisible} onClose={() => setIsVisible(!isVisible)} onAssign={getUsers} careUnitUsers={users} />
      )}
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SectorSectionUsers)
