/**
 * This is ued to set custom start/end times when one or more shifts are selected on Shiftsmanager
 */
import React from 'react'
import PresetsTooltip from './ShiftTableParts/Tooltips/PresetsTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, Popconfirm, Tooltip } from 'antd'
import ReactInputMask from 'react-input-mask'
import { func, bool, number } from 'prop-types'
import debounce from 'lodash/debounce'
import '../../../assets/selection-custom-times.scss'
import EventCodesTooltip from './ShiftTableParts/Tooltips/EventCodesTooltip'
import { keyIncludes } from '../../../utils/arrayUtils'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const VALIDATION_DEBOUNCE_TIME = 500
const INITIAL_START_TIME = '00:00'
const INITIAL_END_TIME = '00:00'
const INITIAL_PERIOD_CODE = ''
const INITIAL_EVENT_CODE = ''
const INITIAL_PAUSE = null

class SelectionCustomTimes extends React.Component {
  static propTypes = {
    onSave: func,
    onRemove: func,
    hasShiftsSelected: bool,
    /**
     * this is how much the page was scrolled horizontally. It's used to always have the multi
     * selection items in sight
     */
    horizontalScroll: number
  };

  constructor (props) {
    super(props)

    this.state = {
      periodCode: INITIAL_PERIOD_CODE,
      startTime: INITIAL_START_TIME,
      endTime: INITIAL_END_TIME,
      pause: INITIAL_PAUSE,
      eventCode: INITIAL_EVENT_CODE,
      eventCodeObject: null,
      previousStartTime: INITIAL_START_TIME, // the following 2 values are used to know when the form is dirty or not
      previousEndTime: INITIAL_END_TIME,
      previousPeriodCode: INITIAL_PERIOD_CODE,
      previousEventCode: INITIAL_EVENT_CODE,
      errors: {
        periodCode: false,
        eventCode: false
      }
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (state.periodCode && props.availablePresets && props.availablePresets.length === 0) {
      return { periodCode: INITIAL_PERIOD_CODE }
    }

    return null
  }

  handleUpdatePeriodCode = e => {
    const { startTime, endTime } = this.state
    const inputValue = e.target.value.toUpperCase()
    const preset = this.props.availablePresets.find(
      item => item.periodCode === inputValue
    )

    this.setState({
      periodCode: inputValue,
      startTime: preset ? this._formatTime(preset.startTime) : startTime,
      endTime: preset ? this._formatTime(preset.endTime) : endTime
    })

    this._checkPeriodCodeDebounced(inputValue)
  };

  _validatePeriodCode = periodCode => {
    if (!periodCode.length) {
      this.setState({
        periodCode: '',
        errors: { ...this.state.errors, periodCode: false }
      })
      return true
    }
    const preset = this.props.availablePresets.find(
      item => item.periodCode === periodCode
    )
    if (!preset) {
      this.setState({ errors: { ...this.state.errors, periodCode: true } })
      return false
    } else {
      this.setState({ errors: { ...this.state.errors, periodCode: false } })
      return true
    }
  };

  _checkPeriodCodeDebounced = debounce(periodCode => {
    this._validatePeriodCode(periodCode)
  }, VALIDATION_DEBOUNCE_TIME);

  _updateTime = (e, type = 'start') => {
    const { startTime, endTime } = this.state
    const typedValue = e.target.value
    this.setState({
      startTime: type === 'start' ? typedValue : startTime,
      endTime: type === 'end' ? typedValue : endTime
    })
  };

  _formatTime = rawDate => {
    if (rawDate && rawDate.includes(':')) {
      const splittedTime = rawDate.split(':')
      if (splittedTime.length > 1) {
        return `${splittedTime[0]}:${splittedTime[1]}`
      }
    }
    return rawDate
  };

  /**
   * This validates the time. It should have a correct format HH:mm and the HH must be between -023, minutes between 0-59
   */
  _validateCustomTime = time => {
    if (!/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this._formatTime(time))) {
      // invalid time
      return false
    }
    return true
  };

  /**
   * This is a helping method to increment/decrement the time
   */
  _modifyTimeWithArrowKeys = (e, type) => {
    const inputValue = e.target.value
    if (
      ['ArrowUp', 'ArrowDown'].includes(e.key) &&
      this._validateCustomTime(inputValue)
    ) {
      const splittedTime = inputValue.split(':')
      let hours = +splittedTime[0]
      let modifyByKey = false
      if (e.key === 'ArrowUp') {
        if (hours === 23) {
          hours = 0
        } else {
          hours += 1
        }
        modifyByKey = true
      } else if (e.key === 'ArrowDown') {
        if (hours === 0) {
          hours = 23
        } else {
          hours -= 1
        }
        modifyByKey = true
      }
      if (modifyByKey) {
        hours = hours.toString()
        if (hours.length === 1) {
          hours = `0${hours}`
        }

        const finalValue = `${hours}:${splittedTime[1]}`
        const { startTime, endTime } = this.state
        this.setState({
          startTime: type === 'start' ? finalValue : startTime,
          endTime: type === 'end' ? finalValue : endTime
        })
      }
    }
  };

  _validateEventCode = eventCode => {
    if (!eventCode.length || keyIncludes(this.context.eventCodes, eventCode, 'code')) {
      this.setState({ errors: { ...this.state.errors, eventCode: false } })
      return true
    } else {
      this.setState({ errors: { ...this.state.errors, eventCode: true } })
      return false
    }
  };

  handleUpdateEventCode = e => {
    const eventCode = e.target.value.toUpperCase()
    const eventCodeObject = this.context.eventCodes.find(e => e.code === eventCode)

    this.setState({ eventCode, eventCodeObject })
    this._checkEventCodeDebounced(eventCode)
  };

  _checkEventCodeDebounced = debounce(eventCode => {
    this._validateEventCode(eventCode)
  }, VALIDATION_DEBOUNCE_TIME);

  _validatePauseDuration = pauseInput => {
    return /^[0-9]{0,2}$/.test(pauseInput)
  }

  handleUpdatePauseDuration = e => {
    const pause = e.target.value.toUpperCase()
    this.setState({ pause })
  };

  handleSave = () => {
    const { startTime, endTime, periodCode, eventCode, pause, eventCodeObject } = this.state

    // trigger another check to make sure we have correct values
    const formIsValid =
      this._validatePeriodCode(periodCode) &&
      this._validateEventCode(eventCode)
    if (formIsValid) {
      this.props.onSave({
        startTime: startTime.length ? startTime : null,
        endTime: endTime.length ? endTime : null,
        periodCode: periodCode.length ? periodCode : null,
        eventCode: eventCodeObject,
        pause: pause
      })
      // update the previous values to the new ones
      this.setState({
        startTime: INITIAL_START_TIME,
        endTime: INITIAL_END_TIME,
        periodCode: INITIAL_PERIOD_CODE,
        eventCode: INITIAL_EVENT_CODE,
        eventCodeObject: null,
        previousStartTime: startTime,
        previousEndTime: endTime,
        previousPeriodCode: periodCode,
        previousEventCode: eventCode,
        pause: pause
      })
    }
  };

  render () {
    const { periodCode, startTime, endTime, eventCode, pause } = this.state
    const {
      availablePresets,
      hasShiftsSelected,
      t,
      horizontalScroll
    } = this.props

    return (
      <div
        className='selection-custom-times'
        style={{
          maxWidth: window.innerWidth - 80,
          marginLeft: horizontalScroll
        }}
      >
        {!hasShiftsSelected && (
          <div className='no-shifts-selected'>{t('No shifts selected')}</div>
        )}
        {hasShiftsSelected && (
          <>
            {availablePresets && availablePresets.length > 0 && (
              <div
                className={`input-block ${
                  this.state.errors.periodCode ? 'invalid' : ''
                }`}
              >
                <label>
                  {this.props.t('Period Code')}
                  &nbsp;
                  <PresetsTooltip
                    presets={availablePresets}
                    placement='bottom'
                  />
                  :
                </label>
                <Input onChange={this.handleUpdatePeriodCode} value={periodCode} />
              </div>
            )}
            <div className='input-block'>
              <label>{this.props.t('Start time')}:</label>
              <ReactInputMask
                mask='99:99'
                maskChar={null}
                onChange={e => {
                  this._updateTime(e, 'start')
                }}
                value={startTime}
              >
                {inputProps => (
                  <Input
                    prefix={<FontAwesomeIcon icon='hourglass-start' />}
                    onKeyUp={e => {
                      this._modifyTimeWithArrowKeys(e, 'start')
                    }}
                    {...inputProps}
                  />
                )}
              </ReactInputMask>
            </div>
            <div className='input-block'>
              <label>{this.props.t('End time')}:</label>
              <ReactInputMask
                mask='99:99'
                maskChar={null}
                onChange={e => {
                  this._updateTime(e, 'end')
                }}
                value={endTime}
              >
                {inputProps => (
                  <Input
                    prefix={<FontAwesomeIcon icon='hourglass-start' />}
                    onKeyUp={e => {
                      this._modifyTimeWithArrowKeys(e, 'end')
                    }}
                    {...inputProps}
                  />
                )}
              </ReactInputMask>
            </div>
            <div className='input-block'>
              <label>{this.props.t('Pause duration')}:</label>
              <Input onChange={this.handleUpdatePauseDuration} value={pause} />
            </div>
            <div
              className={`input-block ${
                this.state.errors.eventCode ? 'invalid' : ''
              }`}
            >
              <label>
                {this.props.t('Event code')}&nbsp;
                <EventCodesTooltip
                  placement='bottom'
                />
                :
              </label>
              <Input onChange={this.handleUpdateEventCode} value={eventCode} />
            </div>
            <div className='buttons-wrapper'>
              <Button
                type='primary'
                disabled={this.saveDisabled}
                htmlType='submit'
                onClick={this.handleSave}
              >
                <FontAwesomeIcon icon='save' />
              </Button>
              <div className='h-spacing' />
              <Tooltip
                placement='top'
                title={this.props.t('Delete those shifts ?')}
              >
                <Popconfirm
                  cancelText={this.props.t('Cancel')}
                  okText={this.props.t('Yes')}
                  onConfirm={this.props.onRemove}
                  placement='top'
                  title={this.props.t('Delete those shifts ?')}
                >
                  <Button type='danger'>
                    <FontAwesomeIcon icon='trash' />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    )
  }
}

SelectionCustomTimes.contextType = GlobalContext

export default SelectionCustomTimes
