import React from 'react'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ValidationWarning = ({ t }, desiderataWarning = false) => {
  let content = (
    <Tooltip placement='right' title={t('Outside of internship schedule')}>
      <span className='outside-of-internship-time-icon'>
        <FontAwesomeIcon icon='exclamation-circle' />
      </span>
    </Tooltip>
  )

  if (desiderataWarning) {
    content = (
      <Tooltip placement='right' title={t('Student desiderata')}>
        <span className='desiderata-warning-icon'>
          <FontAwesomeIcon style={{ color: '#3c8dbc' }} icon='exclamation-circle' />
        </span>
      </Tooltip>
    )
  }

  return content
}

export default ValidationWarning
