import React, { Component } from 'react'

import Users from '../../../Components/institution/Users'
import FreeFieldsAdminPage from '../../../Components/institution/FreeFields/FreeFieldsAdminPage'
import InstitutionParameters from '../../../Components/institution/InstitutionParameters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faBookmark, faClinicMedical, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons'
import { Tabs } from 'antd'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import Analytics from '../../../utils/analytics'
import ManagingEmailsPage from '../../../Components/shared/ManagingEmailsPage'
import ExternalContactsView from '../../../Components/institution/ExternalContacts/ExternalContactsView'

import '../../../assets/institution-settings.scss'

const { TabPane } = Tabs

class InstitutionSettingsView extends Component {
  componentDidMount () {
    Analytics.pageView('/institution-settings')
  }

  render () {
    return (
      <Tabs defaultActiveKey='1' animated={false}>
        <TabPane
          key='1'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon icon={faUser} style={{ marginTop: '3px' }} />
              <div className='h-spacing' />
              {this.props.t('Manage users')}
            </div>
          }
        >
          <Users />
        </TabPane>
        <TabPane
          key='2'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon
                icon={faClinicMedical}
                style={{ marginTop: '3px' }}
              />
              <div className='h-spacing' />
              {this.props.t('Institution parameters')}
            </div>
          }
        >
          <InstitutionParameters />
        </TabPane>
        <TabPane
          key='3'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon
                icon={faBookmark}
                style={{ marginTop: '3px' }}
              />
              <div className='h-spacing' />
              {this.props.t('Manage Free Fields')}
            </div>
          }
        >
          <FreeFieldsAdminPage />
        </TabPane>
        <TabPane
          key='4'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ marginTop: '3px' }}
              />
              <div className='h-spacing' />
              {this.props.t('Managing emails')}
            </div>
          }
        >
          <ManagingEmailsPage />
        </TabPane>
        <TabPane
          key='5'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon
                icon={faAt}
                style={{ marginTop: '3px' }}
              />
              <div className='h-spacing' />
              {this.props.t('External contacts')}
            </div>
          }
        >
          <ExternalContactsView />
        </TabPane>
      </Tabs>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionSettingsView)
