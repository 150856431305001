import { isObject } from 'lodash'
import { CANNOT_BE_EMPTY } from '.'

export const VALIDATION_FIELDS = {
  ACRONYM: 'acronym',
  CATEGORIES: 'categories',
  COMMENT: 'comment',
  COUNTRY: 'country',
  DETAILS: 'details',
  EMAIL: 'email',
  EMPLACEMENT: 'emplacement',
  FIRSTNAME: 'firstname',
  GENDER: 'gender',
  INSTITUTIONS: 'institutions',
  INSTITUTION: 'institution',
  LANGUAGE: 'language',
  LASTNAME: 'lastname',
  NAME: 'name',
  ORIGINAL_NAME: 'originalName',
  ROLES: 'roles',
  SCHEDULE: 'schedule',
  SCHOOL: 'school',
  SECTION: 'section',
  SECTIONS: 'sections',
  SECTION_TO_YEAR: 'sectionToYear',
  SECTOR: 'sector',
  REQUEST_ORIGIN: 'requestOrigin',
  REQUEST_PERIODS: 'requestPeriods',
  TYPE: 'type',
  TYPES: 'types',
  USERNAME: 'username'
}

const validatorsFactory = validatorName => {
  return VALIDATOR_LIST[validatorName ?? 'DEFAULT']
}

const DEFAULT_VALIDATOR = data => {
  return { isValid: true, errors: {} }
}

const isValidSection = (section) => {
  const isValid = section.name && section.schoolYearName

  return {
    isValid: isValid,
    errors: isValid
      ? {}
      : {
        sectionId: section.name ? null : 'Section name cannot be null.',
        schoolYearId: section.schoolYearName ? null : 'Section school year cannot be null.'
      }
  }
}

export const isValid = (item, validators) => {
  const errors = {
    keys: [],
    messages: {}
  }

  validators.forEach(v => {
    const report = v.validator(item)

    if (!report) {
      errors.keys.push(v.key)
      errors.messages[v.key] = [CANNOT_BE_EMPTY]
    } else if ((isObject(report) && !report.valid)) {
      errors.keys.push(v.key)
      errors.messages[v.key] = report.errors
    }
  })

  return errors
}

const VALIDATOR_LIST = {
  DEFAULT: DEFAULT_VALIDATOR,
  Section: isValidSection
}

export {
  validatorsFactory,
  isValidSection
}
