import React, { useState, useEffect, useContext, useMemo } from 'react'

import { Select } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { arrayToObject } from '../../../../utils'

const Option = Select.Option
const STYLE = {
  width: '100%'
}
const PLACEHOLDER = 'Select a person'

const SectionsSelect = ({ data, disabled, valueKey, onBlur, onChange, onFocus }) => {
  const { sections } = useContext(GlobalContext)

  const [displayValue, setDisplayValue] = useState('')

  const sectionsList = useMemo(() => {
    return arrayToObject(sections.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation)), 'id')
  }, [sections])

  useEffect(() => {
    if (data && valueKey && data[valueKey]) {
      setDisplayValue('')
    }
  }, [data, valueKey])

  const handleChange = (value, Option) => {
    const newData = { ...data }

    newData[valueKey] = value
    newData.name = sectionsList[value].name

    if (newData.schoolYearName) {
      newData.schoolYearId = -1
      newData.schoolYearName = ''
    }

    onChange(newData)
  }

  const renderOptions = () => {
    const sections = sectionsList

    return Object.keys(sections).map(key => {
      const value = sections[key].id
      const content = sections[key].abbreviation
      const title = sections[key].name

      const newOption = renderOption(key, value, content, title)

      if (sections[key].id === data[valueKey] && displayValue === '') {
        setDisplayValue(sections[key].id)
      }

      return newOption
    })
  }

  const renderOption = (key, value, content, title) => {
    if (title) {
      return (
        <Option
          key={'option' + key}
          value={value}
          title={title}
          className='tutoriel-select-title'
        >
          {content}
        </Option>
      )
    }

    return (
      <Option
        key={'option' + key}
        value={value}
        className='tutoriel-select'
      >
        {content}
      </Option>
    )
  }

  const filterOptions = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return data[valueKey] !== undefined
    ? (
      <Select
        showSearch
        placeholder={PLACEHOLDER}
        optionFilterProp='children'
        onChange={handleChange}
        disabled={disabled}
        onFocus={() => onFocus()}
        onBlur={() => onBlur()}
        style={STYLE}
        value={displayValue}
        filterOption={filterOptions}
        className='tutoriel-select-quid'
      >
        {renderOptions()}
      </Select>
    )
    : (<div />)
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionsSelect)
