import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { request, generalErrorHandler } from '../../../utils'
import { getInternshipStateText } from '../../../utils/internship-utils'
import SatisfactionRatio from '../../../Components/shared/SatisfactionRatio'
import EvaluationQuestionsWithAnswers from './EvaluationQuestionsAndAnswers'

import '../../../assets/evaluation-details.scss'

const EvaluationDetails = (props) => {
  const [loading, setLoading] = useState(true)
  const [evaluationsList, setEvaluationsList] = useState([])

  const loadListOfEvaluations = async () => {
    try {
      setLoading(true)
      const { data } = await request(`/evaluation/student/${props.studentId}`, 'GET', null, props.getUser)

      if (data) {
        setEvaluationsList(data.map(item => {
          return {
            ...item,
            sectionName: getSectionName(item),
            individualDetailsDisplayed: false
          }
        }))
        setLoading(false)
      }
    } catch (err) {
      generalErrorHandler(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.studentId) {
      loadListOfEvaluations()
    }
  }, [props.studentId])

  const getSectionName = section => {
    return section.sectionName ?? section.deprecatedSectionName
  }

  const handleExtraDetailsDisplay = evaluationId => {
    const updatedEvaluationList = evaluationsList.slice()
    const itemToUpdate = updatedEvaluationList.find(item => item.evaluationId === evaluationId)
    if (itemToUpdate) {
      itemToUpdate.individualDetailsDisplayed = !itemToUpdate.individualDetailsDisplayed
    }
    setEvaluationsList(updatedEvaluationList)
  }

  return (
    <div className='evaluation-details-wrapper'>
      <div className='top-details'>
        <div className='student-details'>
          <div>
            <label>{props.t('Student name')}</label>
            <span>{props.studentName}</span>
          </div>
          <div>
            <label>{props.t('School')}</label>
            <span>{props.school}</span>
          </div>
          <div>
            <label>{props.t('Section(s)')}</label>
            <span>{props.sectionName}</span>
          </div>
        </div>
        <div className='general-satisfaction-ratios'>
          <div className='satisfaction-block'>
            <label>{props.t('Obtained by the student')}</label>
            <SatisfactionRatio ratio={props.studentEvaluationSatisfactionRatio} />
          </div>
          <div className='satisfaction-block'>
            <label>{props.t('Obtained by the care unit')}</label>
            <SatisfactionRatio ratio={props.institutionEvaluationSatisfactionRatio} />
          </div>
        </div>
      </div>
      <div className='evaluations-list'>
        {loading && <div className='loading-ring' />}
        {!loading && (
            <div>
              <div className='h-spacing' />
              <div className='student-evaluations-list-header'>{props.t('List of all evaluations')}</div>
            </div>
        )}
        {!loading && evaluationsList.map(item => (
          <div className='evaluation-item' key={`evaluation-internship-${item.internshipId}`}>
            <div className='evaluation-item-header'>
              <div className='internship-duration'>
                {`${props.t('From')} ${moment(item.startDate.date).format('DD/MM/YYYY')} 
                ${props.t('to')} ${moment(item.endDate.date).format('DD/MM/YYYY')}: 
                ${props.t(getInternshipStateText(item.internshipState))}`}
              </div>
              <div className='internship-info'>
                {`${props.t('Internship')} ${item.internshipId}:`}
              </div>
            </div>
            <div className='evaluation-item-subheader'>
              {item.sectorName}
            </div>
            <div className='evaluation-item-subheader'>
              {item.sectionName} - {item.internshipYearName}
            </div>
            <div className='evaluation-item-details'>
              {(item.studentEvaluationAnswers.length > 0 || item.institutionEvaluationAnswers.length > 0) && (
                <button type='button' className='expand-details' onClick={() => { handleExtraDetailsDisplay(item.evaluationId) }}>
                  <FontAwesomeIcon icon={item.individualDetailsDisplayed ? faChevronUp : faChevronDown} />
                </button>
              )}
              <div>
                <label>{props.t('Obtained by the student')}</label>
                <SatisfactionRatio ratio={item.studentEvaluationSatisfactionRatio} />
              </div>
              <div>
                <label>{props.t('Comments')}</label>
                <div className='evaluation-comment'>
                  {item.studentEvaluationComment && item.studentEvaluationComment.length
                    ? item.studentEvaluationComment
                    : props.t('None')}
                </div>
              </div>
              <div className='left-space'>
                <label>{props.t('Obtained by the care unit')}</label>
                <SatisfactionRatio ratio={item.institutionEvaluationSatisfactionRatio} />
              </div>
              <div>
                <label>{props.t('Comments')}</label>
                <div className='evaluation-comment'>
                  {item.institutionEvaluationComment && item.institutionEvaluationComment.length
                    ? item.institutionEvaluationComment
                    : props.t('None')}
                </div>
              </div>
            </div>
            <EvaluationQuestionsWithAnswers
              expanded={item.individualDetailsDisplayed}
              evaluationId={item.evaluationId}
              studentEvaluationAnswers={item.studentEvaluationAnswers}
              institutionEvaluationAnswers={item.institutionEvaluationAnswers}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationDetails)
