import React, { createContext, useState } from 'react'

import { Drawer } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import InternshipFilesManager from '../../Components/shared/FilesManager/InternshipFilesManager/InternshipFilesManager'
import InternshipFileModal from '../Forms/InternshipFileModal'

export const InternshipFilesDrawerContext = createContext()

export const DEFAULT_STATE = { data: null, readOnly: false }

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const InternshipFilesDrawer = ({ children, readOnly, t }) => {
  const [selectedInternship, setSelectedInternship] = useState(DEFAULT_STATE)

  return (
    <>
      <InternshipFilesDrawerContext.Provider value={{ setSelectedInternship }}>
        {children}
      </InternshipFilesDrawerContext.Provider>
      <Drawer
        title={t('internship_files_drawer.title')}
        width='650px'
        onClose={() => setSelectedInternship(DEFAULT_STATE)}
        visible={!!selectedInternship.data}
        zIndex={5000}
        destroyOnClose
      >
        <InternshipFileModal>
          <InternshipFilesManager internship={selectedInternship.data} readOnly={selectedInternship.readOnly} />
        </InternshipFileModal>
      </Drawer>
    </>
  )
}

export default connect(mapStateToProps)(InternshipFilesDrawer)
