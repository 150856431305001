import React, { useState } from 'react'

import { Tooltip, Select, List } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

import '../../../../assets/quotas.scss'

const Option = Select.Option
const Item = List.Item

const DEFAULT_SELECT_STYLE = { width: '100%' }
const DEFAULT_LIST_ITEM_STYLE = {
  paddingLeft: '5px',
  paddingRight: '5px'
}
const TOOLTIP_PLACEMENT = 'right'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const SelectList = ({ data, listStyle, t, onSelectedSectorsChanged, onSelectedSectorsDeleted }) => {
  const [selectedSectors, setSelectedSectors] = useState([])

  const onSectorSelected = (value, option) => {
    const sector = option.props.data

    if (!selectedSectorsContainSector(sector)) {
      setSelectedSectors([...selectedSectors, sector])
      onSelectedSectorsChanged(sector)
    }
  }

  const selectedSectorsContainSector = sectorToCheck => {
    return selectedSectors.find(sector => sector.id === sectorToCheck.id)
  }

  const removeTableData = sectorToRemoved => {
    setSelectedSectors(selectedSectors.filter(sector => sector.id !== sectorToRemoved.id))
    onSelectedSectorsDeleted(sectorToRemoved)
  }

  const renderSelectOptions = () => {
    if (!data) {
      return []
    }

    return data.map((sector, index) => {
      const value = sector.name

      return (
        <Option key={`option_${index}`} value={value} data={sector}>
          <Tooltip placement={TOOLTIP_PLACEMENT} title={value}>
            {value}
          </Tooltip>
        </Option>
      )
    })
  }

  const renderListContent = () => {
    return selectedSectors.map((sector, index) => {
      return (
        <Item key={`list-item-${index}`} style={DEFAULT_LIST_ITEM_STYLE}>
          <div className='select-list-item-body'>
            <Tooltip placement={TOOLTIP_PLACEMENT} title={sector.name}>
              <span> {sector.name} </span>
            </Tooltip>
            <div className='list-item-clickable-icon' onClick={() => removeTableData(sector)}>
              <FontAwesomeIcon icon={faTimes} color='red' />
            </div>
          </div>
        </Item>
      )
    })
  }

  const optionFilter = (input, option) => {
    const value = option.props.value

    return value.toLowerCase().includes(input.toLowerCase())
  }

  return (
    <div className='select-list-component'>
      <Select
        style={DEFAULT_SELECT_STYLE}
        placeholder={t('Search a unit care')}
        value={undefined}
        showSearch
        onSelect={onSectorSelected}
        filterOption={optionFilter}
      >
        {renderSelectOptions()}
      </Select>
      <div className='select-list-body'>
        <List style={listStyle}>
          {renderListContent()}
        </List>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(SelectList)
