import { APP_STATES } from './actionsType'

const { SET_PAUSE_ACTIVATION } = APP_STATES

const getAppStates = state => {
  return state.appStates
}

const setPauseActivationState = newState => {
  return {
    type: SET_PAUSE_ACTIVATION,
    payload: newState
  }
}

export {
  getAppStates,
  setPauseActivationState
}
