import React, { useEffect, useRef, useState } from 'react'

import { faDownload, faLock, faLockOpen, faPlus, faRedo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popconfirm, Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { downloadFile } from '../../utils'
import { getInternshipConvention, updateInternshipDocument } from '../../utils/api/internshipDocument'
import { ROLE_HOSPITAL_ADMIN } from '../../utils/constants'
import { getUser } from '../../reducers/UserReducer'
import { onError, onSuccess } from '../../utils/apiHelper'
import { uploadInternshipConvention } from '../../utils/api/internship'

const ACCEPTED_DOCUMENT_TYPES = [
  'text/csv', '.csv', '.xlsx',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ConventionHandler = ({ internship, user, t }) => {
  const [document, setDocument] = useState(null)
  const [uploading, setUploading] = useState(false)

  const uploaderRef = useRef(null)

  useEffect(() => {
    if (internship && user) {
      getInternshipConvention(internship, user).then(setDocument)
    } else {
      setDocument(null)
    }
  }, [internship, user])

  const handleDownload = () => {
    downloadFile('/internship/document/download/' + document.id, document.originalName, user)
  }

  const handleLock = () => {
    updateInternshipDocument(document, user).then(json => {
      setDocument({ ...document, locked: true })
    })
  }

  const handleUpload = e => {
    const file = e.target.files[0]

    if (file && ACCEPTED_DOCUMENT_TYPES.indexOf(file.type) > -1) {
      setUploading(true)

      uploadInternshipConvention(internship, file, user).then(json => {
        setDocument(json.data)
        setUploading(false)

        onSuccess(t('Internship agreement sent!'), 'bottomRight')
      })
    } else {
      onError(t('Only PDF, Word, Excel and CSV file types are supported.'), 'bottomRight')
    }
  }

  const renderLockButton = () => {
    let lockButton = (
      <Tooltip
        placement='top'
        title={t(
          'This internship convention has been locked, the student cannot edit it anymore.'
        )}
      >
        <Button type='default' size='small' disabled>
          <FontAwesomeIcon icon={faLock} />
        </Button>
      </Tooltip>
    )

    if (!document.locked) {
      lockButton = (
        <Popconfirm
          placement='top'
          title={t('Accept and definitively lock this internship convention ?')}
          okText={t('Accept and lock')}
          onConfirm={handleLock}
          cancelText={t('Cancel')}
        >
          <Tooltip
            placement='bottom'
            title={t(
              'Accept and lock this internship convention to avoid the student to edit it.'
            )}
          >
            <Button type='default' size='small'>
              <FontAwesomeIcon icon={faLockOpen} />
            </Button>
          </Tooltip>
        </Popconfirm>
      )
    }

    return lockButton
  }

  return (
    <div className='convention-handler'>
      {internship && (
        <div className='flex-row'>
          <Button
            type='default'
            size='small'
            onClick={handleDownload}
            disabled={!document}
          >
            <FontAwesomeIcon icon={faDownload} />
            {t('Download')}
          </Button>
          {!!document && (
            <>
              <div className='h-spacing' />
              {renderLockButton()}
            </>
          )}
        </div>
      )}
      {internship && user.roles.includes(ROLE_HOSPITAL_ADMIN) && (
        <>
          <Button
            disabled={document && document.locked}
            loading={uploading}
            size='small'
            style={{ marginTop: '5px' }}
            type='primary'
            onClick={() => uploaderRef.current.click()}
          >
            <FontAwesomeIcon icon={document ? faRedo : faPlus} /> &nbsp; {t(document ? 'Update' : 'Add')}
          </Button>
          <input type='file' ref={uploaderRef} onChange={handleUpload} accept={ACCEPTED_DOCUMENT_TYPES.join(',')} style={{ display: 'none' }} />
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(ConventionHandler)
