import React, { useEffect, useState } from 'react'

import IconTooltip from './IconTooltip'
import QuickDualDatePickers from '../shared/QuickDualDatePickers'
import { DEFAULT_DRAWER_WIDTH, TOOLTIP_LEFT } from '../utils/Constants'
import moment from 'moment'
import { Drawer, Button, Row, InputNumber } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'
import { getEndDateByType } from '../../../../utils/timePeriods'
import { BODY_STYLE } from '../allocation/AllocationForm'
import SearchCheckbox from '../../../antd/Selects/SearchCheckbox'

import '../../../../assets/quotas.scss'
import '../assets/places-definition.scss'

const TOOLTIP_TITLE = 'Define the total capacity of a care unit in terms of interns.'
const DRAWER_TITLE = 'Add free places'
const PLACES_TITLE = 'Places to add'
const SECTOR_TITLE = 'Care unit'
const SECTOR_KEY = 'name'

const PlacesFormDrawer = props => {
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [sectors, setSectors] = useState([])
  const [places, setPlaces] = useState(0)

  useEffect(() => {
    if (props.date) {
      setStartDate(props.date)
      setEndDate(getEndDateByType(props.date, props.period.type))
    }
  }, [props.date, props.period])

  const createPlaces = () => {
    props.onQuotaAdding(
      sectors.map(s => s.id),
      places,
      startDate,
      endDate
    )

    handleOnClose()
  }

  const handlePlacesAddition = places => {
    if (places === '') {
      setPlaces(0)
    }

    setPlaces(places)
  }

  const handleOnClose = () => {
    if (typeof props.onClose === 'function') {
      props.onClose()
    }

    setSectors([])
    setPlaces(0)
  }

  const handleDatesChange = (dates, dateStrings) => {
    setStartDate(dates[0])
    setEndDate(dates[1])
  }

  const renderDrawerTitle = () => {
    return (
      <div className='places-creater-drawer-title'>
        <IconTooltip
          title={TOOLTIP_TITLE}
          placement={TOOLTIP_LEFT}
        />
        <h3 style={{ textAlign: 'center', margin: '0px' }}> {props.t(DRAWER_TITLE)} </h3>
      </div>
    )
  }

  const handleSectorChecked = (sector, checked) => {
    const newSector = checked
      ? [...sectors, sector]
      : sectors.filter(s => s.id !== sector.id)

    setSectors(newSector)
  }

  return (
    <Drawer
      destroyOnClose
      title={renderDrawerTitle()}
      width={DEFAULT_DRAWER_WIDTH}
      onClose={handleOnClose}
      visible={props.visible}
      bodyStyle={BODY_STYLE}
    >
      <div className='allocate-quota-form'>
        <Row>
          <h4>
            <b>
              {props.t('Date')}
            </b>
          </h4>
        </Row>
        <Row>
          <QuickDualDatePickers
            style={{ backgroundColor: 'white', justifyContent: 'left', padding: '0px' }}
            startDate={startDate}
            endDate={endDate}
            period={props.period}
            onDatesChange={handleDatesChange}
          />
        </Row>
        <Row>
          <h4>
            <b>
              {props.t(PLACES_TITLE)}
            </b>
          </h4>
        </Row>
        <Row>
          <InputNumber value={places} onChange={handlePlacesAddition} />
        </Row>
        <Row>
          <h4>
            <b>
              {props.t(SECTOR_TITLE)}
            </b>
          </h4>
        </Row>
        <Row>
          <div style={{ height: '200px' }}>
            <SearchCheckbox
              style={{ margin: '0' }}
              checkAllOption
              items={props.sectors}
              labelKey={SECTOR_KEY}
              onCheck={handleSectorChecked}
              onCheckAll={setSectors}
              valueKey='id'
            />
          </div>
        </Row>
      </div>
      <div style={{ height: '10px' }} />
      <Button
        onClick={createPlaces}
        disabled={sectors.length === 0}
      >
        {props.t('Add places')}
      </Button>
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlacesFormDrawer)
