import { request, requestWithPromise } from '../../utils'
import { SUPPORTED_LANGUAGES } from '../../utils/constants'

const ADD_STUDENT_FREE_FIELD = 'ADD_STUDENT_FREE_FIELD'
const REMOVE_STUDENT_FREE_FIELD = 'REMOVE_STUDENT_FREE_FIELD'
const REMOVE_FREE_FIELD_TYPE = 'REMOVE_FREE_FIELD_TYPE'
const SET_STUDENT_FREE_FIELDS = 'SET_STUDENT_FREE_FIELDS'
const SET_STUDENTS_FREE_FIELDS = 'SET_STUDENTS_FREE_FIELDS'
const SET_INSTITUTION_FIELDS_TYPES = 'SET_INSTITUTION_FIELDS_TYPES'
const SET_FREE_FIELD_TYPE = 'SET_FREE_FIELD_TYPE'
const UPDATE_STUDENT_FREE_FIELDS = 'UPDATE_STUDENT_FREE_FIELDS'
const LOAD_MESSAGE_FREE_FIELDS_TYPES = 'LOAD_MESSAGE_FREE_FIELDS_TYPES'

/**
 * Get all the store data related to FreeFields
 *
 * @param {Object} state data in the global store related to FreeFields
 *
 * @returns Object
 */
export const getFreeFields = (state) => {
  return state
}

/**
 * Get all the studentfreeFields data contained in the global store
 *
 * @param {Object} state data in the global store related to FreeFields
 *
 * @returns Object containing all the studenFreeFields save in the store
 */
export const getStudentFreeFields = (state) => {
  return state.studentFreeFields
}

/**
 * Get all the FieldsTypes data contained in the global store related to the current institution
 *
 * @param {Object} state data in the global store related to FieldsTypes of the current institution
 *
 * @returns Object containing all the FieldsTypes save in the store for the current institution
 */
export const getInstititionFieldsTypes = (state) => {
  return state.freeFieldsTypes
}

/**
 * Get all the studentFreeFields for a particular student in the backend database
 * Save the getted studentFreeFields in the global store
 *
 * @param {int}    institutionId id of the current institution
 * @param {int}    studentId     id of the student for whom we want to retrieve studetFreeFields
 * @param {string} language      language for the traduction of FieldsTypes labels
 * @param {Object} user          current user
 *
 * @returns Thunk Function
 */
export const fetchStudentFreeField = (institutionId, studentId, language, user) => {
  return async function fetchStudentFreeFieldThunk (dispatch, getState) {
    const response = await request('/free-fields/' + institutionId +
                              '/' + studentId +
                              '/' + language,
    'GET',
    null,
    user,
    { catchError: false }
    )

    dispatch({ type: SET_STUDENT_FREE_FIELDS, payload: response.data })
  }
}

/**
 * Get all the FieldsTypes for a particular institution
 * Save the getted Fieldsrypes in the global store
 *
 * @param {int}    institutionId id of the institution
 * @param {string} language      language for the traduction of FieldsType labels
 * @param {Object} user          current user
 *
 * @returns Thunk Function
 */
export const fetchInstitutionFieldsTypes = (user) => {
  return async function (dispatch, getState) {
    const response = await request('/free-fields/all',
      'GET',
      null,
      user,
      { catchError: false }
    )

    dispatch({ type: SET_INSTITUTION_FIELDS_TYPES, payload: response.data })
  }
}

/**
 * Add a new studentFreeFields in the backend database
 * If the backend saving went well, save the new studentFreefields in the global store
 *
 * @param {int}    studentId   id of the student to add the FreeFields
 * @param {int}    fieldTypeId id of the studentFreeFields type
 * @param {string} fieldValue  studentFreeField value
 * @param {Object} user        current user
 *
 * @returns Thunk Function
 */
export const addNewStudentFreeField = (studentId, fieldType, fieldValue, user, language) => {
  return async function (dispatch, getState) {
    const requestBody = {
      studentId: studentId,
      fieldType: fieldType.id,
      fieldValue: fieldValue
    }
    const response = await request('/free-fields/',
      'POST',
      requestBody,
      user,
      { catchError: false }
    )
    const newStudentFreeField = {
      id: response.data.id,
      freeFieldsTypeId: response.data.fieldType,
      fieldValue: response.data.fieldValue,
      language: language,
      isEditable: fieldType.isEditable,
      isBadgeDisplayed: fieldType.isBadgeDisplayed
    }

    dispatch({ type: ADD_STUDENT_FREE_FIELD, payload: newStudentFreeField })
  }
}

/**
 * Update a studentFreeFields in the backend database
 * If the backend update went well, save the updated studentFreeFields in the global store
 * Else display an error in console
 *
 * @param {Object} studentFreeField updated studentFreefield to save in backend
 * @param {Object} user             current user
 *
 * @returns Thunk Function
 */
export const updateStudentFreeField = (studentFreeField, user) => {
  return async function updateStudentFreeFieldThunk (dispatch, getState) {
    const response = await request('/free-fields/', 'PATCH', studentFreeField, user)

    if (response.status) {
      dispatch({ type: UPDATE_STUDENT_FREE_FIELDS, payload: studentFreeField })
    } else {
      console.log('error when updating StudentFreeField')
    }
  }
}

/**
 * Delete a studentFreeField in the backend database
 * If the backend deletion went well, delete the studentFreeFields in the global store
 *
 * @param {int}    studentFreeFieldId id of the studentFreeFields to delete
 * @param {Object} user               current user
 *
 * @returns Thunk Function
 */
export const deleteStudentFreeFields = (studentFreeFieldId, user) => {
  return async function deleteStudentFreeFieldsThunk (dispatch, getState) {
    const response = await request('/api/StudentFreeFields/' + studentFreeFieldId,
      'DELETE',
      null,
      user
    )

    if (response.data) {
      dispatch({ type: REMOVE_STUDENT_FREE_FIELD, payload: studentFreeFieldId })
    } else {
      console.log('error when deleteting StudentFreeField with id : ' + studentFreeFieldId)
    }
  }
}

/**
 * Delete a freeFieldsType in the backend database
 * If the backend deletion went well, delete the freeFieldsType in the global store
 *
 * @param {int}    studentFreeFieldId id of the freeFieldsType to delete
 * @param {Object} user               current user
 *
 * @returns Thunk Function
 */
export const deleteFreeFieldType = (freeFieldTypeId, user) => {
  return async function deleteStudentFreeFieldsThunk (dispatch, getState) {
    const response = await request('/free-field-type/' + freeFieldTypeId,
      'DELETE',
      null,
      user
    )

    if (response.status === 'success') {
      dispatch(removeFreeFieldType(freeFieldTypeId))
    } else {
      console.log('error when deleteting StudentFreeField with id : ' + freeFieldTypeId)
    }
  }
}

/**
 * Create or update a freeFieldType entity in the backend database.
 * Find the freeFieldType to modify based on his fieldType value
 *
 * @param {Object} freeFieldType free field type to create or update in backend
 * @param {int}    institutionId the institution id in wich the free field must be added
 * @param {Object} user          current user
 *
 * @returns Thunk Function
 */
export const addOrUpdateFreeFieldType = (freeFieldType, institutionId, user) => {
  const formatedFreeField = {
    fieldType: freeFieldType.fieldType,
    institution: institutionId,
    isEditable: freeFieldType.isEditable,
    isBadgeDisplayed: freeFieldType.isBadgeDisplayed,
    isStudentDisplayed: freeFieldType.isStudentDisplayed,
    isEncryption: freeFieldType.isEncryption,
    languages: [],
    labels: {}
  }

  Object.keys(freeFieldType.translations).forEach(translationKey => {
    formatedFreeField.languages.push(translationKey)
    formatedFreeField.labels[translationKey] = freeFieldType.translations[translationKey]
  })

  return async function updateStudentFreeFieldThunk (dispatch, getState) {
    const response = await request('/api/FreeFieldsType/create-or-update', 'POST', formatedFreeField, user)

    if (response.status === 'success') {
      freeFieldType.id = response.data.id
      freeFieldType.institution = institutionId

      dispatch(setFreeFieldType(freeFieldType))
    } else {
      console.log('error when updating StudentFreeField')
    }
  }
}

/**
 * Update a particular freeFieldType entity in the backend
 * Must be used in place of addOrUpdateFreeFieldType in case of modifications made are directly
 *  linked to the freeFieldType entity and not his translation.
 * Can be used alse in order to modify the translation of the freeFieldType value
 *
 * @param {Object} freeFieldType free field type to create or update in backend
 * @param {int}    institutionId the institution id in wich the free field must be added
 * @param {Object} user          current user
 * @returns Thunk Function
 */
export const updateFreeFieldType = (freeFieldType, institutionId, user) => {
  const newFreeFieldType = { ...freeFieldType, translations: {} }

  SUPPORTED_LANGUAGES.forEach(language => {
    newFreeFieldType.translations[language] = newFreeFieldType[language]
    delete newFreeFieldType[language]
  })

  Object.keys(newFreeFieldType).forEach(k => {
    if (typeof newFreeFieldType[k + '_disabled'] !== 'undefined') {
      delete newFreeFieldType[k + '_disabled']
    }
  })

  return async function updateStudentFreeFieldThunk (dispatch, getState) {
    const jsonResponse = await requestWithPromise('/free-field-type/' + newFreeFieldType.id, 'PATCH', newFreeFieldType, user)

    if (jsonResponse) {
      dispatch(setFreeFieldType(jsonResponse.data))
    }
  }
}

/**
 * Build an action in order to add a studentFreeField to the global store
 *
 * @param {Object} studentFreeField studentFreeField to add to the store
 *
 * @returns redux action
 */
export const addStudentFreeField = (studentFreeField) => {
  return {
    type: ADD_STUDENT_FREE_FIELD,
    payload: studentFreeField
  }
}

/**
 * Build an action in order to save all the studentFreeFields in the global store
 *
 * @param {Object} studentFreeFields all the studentFreeFields to save in the store
 *
 * @returns redux action
 */
export const setStudentFreeFields = (studentFreeFields) => {
  return {
    type: SET_STUDENT_FREE_FIELDS,
    payload: studentFreeFields
  }
}

/**
 * Build an action in order to save all the studentFreeFields in the global store
 *
 * @param {Object} studentsFreeFields all the studentFreeFields to save in the store
 *
 * @returns redux action
 */
export const setStudentsFreeFields = (studentsFreeFields) => {
  return {
    type: SET_STUDENTS_FREE_FIELDS,
    payload: studentsFreeFields
  }
}

/**
 * Build an action in order to save all the freeFieldsType for an institution in the global store
 *
 * @param {Object} freeFieldsTypes all the freeFieldsTypes for an institution to save in the store
 *
 * @returns redux action
 */
export const setInstititionFieldsTypes = (freeFieldsTypes) => {
  return {
    type: SET_INSTITUTION_FIELDS_TYPES,
    payload: freeFieldsTypes
  }
}

/**
 * Build an action in order to save a freeFieldsType for an institution in the global store
 *
 * @param {Object} freeFieldtype a freeFieldType for an institution to save in the store
 *
 * @returns redux action
 */
export const setFreeFieldType = (freeFieldtype) => {
  return {
    type: SET_FREE_FIELD_TYPE,
    payload: freeFieldtype
  }
}

/**
 * Build an action in order to remove a studentFreeField from the global store
 *
 * @param {int} studentFreeFieldId id of the studentFreeField to delete to the store
 *
 * @returns redux action
 */
export const removeStudentFreeField = (studentFreeFieldId) => {
  return {
    type: REMOVE_STUDENT_FREE_FIELD,
    payload: studentFreeFieldId
  }
}

/**
 * Build an action in order to remove a freeFieldType from the global store
 *
 * @param {int} studentFreeFieldId id of the freeFieldType to delete to the store
 *
 * @returns redux action
 */
export const removeFreeFieldType = (freeFieldTypeId) => {
  return {
    type: REMOVE_FREE_FIELD_TYPE,
    payload: freeFieldTypeId
  }
}

export const loadMessageFieldsTypes = payload => {
  return {
    type: LOAD_MESSAGE_FREE_FIELDS_TYPES, payload
  }
}

export const getLoadMessageFieldsTypes = state => {
  return state.getFreeFields.loadMessage
}

/**
 * Reducer for the Free Fields
 */
export default (state = [], action) => {
  switch (action.type) {
    case ADD_STUDENT_FREE_FIELD: {
      const newStudentFreeFields = { ...state.studentFreeFields }
      const studentFreeFieldsType = { ...state.freeFieldsTypes[action.payload.freeFieldsTypeId] }

      newStudentFreeFields[action.payload.id] = {
        ...action.payload,
        fieldType: studentFreeFieldsType.type,
        freeFieldsLabel: studentFreeFieldsType.translations[action.payload.language]
      }

      return { ...state, studentFreeFields: newStudentFreeFields }
    }
    case REMOVE_STUDENT_FREE_FIELD: {
      const newStudentFreeFields = { ...state.studentFreeFields }

      delete newStudentFreeFields[action.payload]

      return { ...state, studentFreeFields: newStudentFreeFields }
    }
    case REMOVE_FREE_FIELD_TYPE: {
      const newFreeFieldsTypes = { ...state.freeFieldsTypes }

      delete newFreeFieldsTypes[action.payload]

      return { ...state, freeFieldsTypes: newFreeFieldsTypes }
    }
    case SET_STUDENT_FREE_FIELDS: {
      return { ...state, studentFreeFields: { ...action.payload } }
    }
    case SET_STUDENTS_FREE_FIELDS: {
      return { ...state, studentFreeFields: { ...action.payload } }
    }
    case SET_INSTITUTION_FIELDS_TYPES: {
      return { ...state, freeFieldsTypes: { ...action.payload } }
    }
    case SET_FREE_FIELD_TYPE: {
      const newFreeFieldsTypes = { ...state.freeFieldsTypes }

      newFreeFieldsTypes[action.payload.id] = { ...action.payload }

      return { ...state, freeFieldsTypes: newFreeFieldsTypes }
    }
    case UPDATE_STUDENT_FREE_FIELDS: {
      const newState = { ...state }
      const newStudentFreeField = action.payload

      newState.studentFreeFields[newStudentFreeField.id] = newStudentFreeField

      return { ...newState }
    }
    case LOAD_MESSAGE_FREE_FIELDS_TYPES: {
      if (state !== action.payload) {
        return {
          ...state,
          loadMessage: action.payload
        }
      }

      return state
    }
    default:
      return state
  }
}
