import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { connect, mapDispatchToProps, mapStateToProps } from '../../../reducers/Dispatchers'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import frLocale from '@fullcalendar/core/locales/fr'
import nlLocale from '@fullcalendar/core/locales/nl'
import DesiderataHeaderButtonBar from '../../shared/ShiftsManager/DesiderataHeaderButtonBar'
import { getPercentage, requestWithPromise } from '../../../utils'
import DesiderataCreationModal from './DesiderataCreationModal'
import DesiderataUpdateModal from './DesiderataUpdateModal'
import moment from 'moment'
import { internshipStates, LANGUAGE_LOCALE_TAG_FR_BE, LANGUAGE_LOCALE_TAG_FR_FR, LANGUAGE_LOCALE_TAG_NL } from '../../../utils/constants'
import { Tooltip } from 'antd'
import {
  buildTitle, formatDate, formatTime, getBorderColorByShiftAndPreset, getColorByPreset, getTextColorByShift
} from './utils'
import { API_DATE_FORMAT, POST, onWarning } from '../../../utils/apiHelper'
import { getInternshipInternshipYearActTypes, getInternshipShifts } from '../../../utils/api/internship'
import { getSectorPresets } from '../../../utils/api/sector'
import { getDesiderataLimitValue } from '../../../utils/api/institution'

import '../../../assets/student-desiderata.scss'
import { getTimezoneLessMoment } from '../../../utils/momentjs'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const { ACCEPTED, ACCEPTED_UNMANAGED, AUTO_ACCEPTED } = internshipStates

const BREAK_POINT = 280

const alert = window.alert

const ShiftsCalendar = props => {
  const { internship, getActiveLanguage } = props

  const initialDate = formatDate(internship.startDate)
  const internshipToEvent = {
    title: props.t('Internship'),
    start: initialDate,
    end: formatDate(internship.endDate),
    display: 'background'
  }

  const [shifts, setShifts] = useState([])
  const [loading, setLoading] = useState(true)
  const [actTypes, setActTypes] = useState([])
  const [creationModalVisible, setCreationModalVisible] = useState(false)
  const [areDatesSelected, setAreDatesSelected] = useState(false)
  const [selectedDates, setSelectedDates] = useState('')
  const [selectedShift, setSelectedShift] = useState()
  const [shiftPresets, setShiftPresets] = useState([])
  const [events, setEvents] = useState([internshipToEvent])
  const [width, setWidth] = useState(window.innerWidth)
  const [maxDesiderata, setMaxDesiderata] = useState(null)
  const [warning, setWarning] = useState(false)

  const { eventCodes } = useContext(GlobalContext)

  const isUpdatable = useMemo(() => internship && [ACCEPTED, ACCEPTED_UNMANAGED, AUTO_ACCEPTED].includes(internship.state), [internship?.state])
  const desiderataLimit = useMemo(() => maxDesiderata - shifts.length, [maxDesiderata, shifts.length])
  const desiderataInfo = useMemo(() => {
    return {
      daysLeft: desiderataLimit,
      percentage: getPercentage(desiderataLimit, maxDesiderata),
      useLimitation: maxDesiderata !== null && desiderataLimit <= 0 && !(internship.school?.managed)
    }
  }, [desiderataLimit, maxDesiderata])

  let locale
  switch (getActiveLanguage) {
    case LANGUAGE_LOCALE_TAG_FR_BE:
      locale = frLocale
      break
    case LANGUAGE_LOCALE_TAG_FR_FR:
      locale = frLocale
      break
    case LANGUAGE_LOCALE_TAG_NL:
      locale = nlLocale
      break
    default:
      locale = null
  }

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (internship && props.getUser) {
      const parameters = { internship: internship.id }
      getDesiderataLimitValue(internship.institution, props.getUser, parameters).then(json => {
        if (json?.data) {
          setMaxDesiderata(json.data)
        }
      })

      getSectorPresets(props.getUser, internship.sector).then(json => {
        if (json?.data) {
          setShiftPresets(json.data)
          buildInternshipInformation(
            props.internship,
            json.data
          )
        }
      })
    }
  }, [internship, props.getUser])

  const addEvent = (newEvent) => {
    setEvents(state => [...state, newEvent])
  }

  const makeNewEvent = shift => {
    if (shift.shiftPreset) {
      return {
        id: shift.id,
        title: JSON.stringify(buildTitle(shift)),
        start: formatDate(shift.startDate),
        end: formatDate(shift.endDate),
        display: 'auto',
        color: getColorByPreset(shift.shiftPreset),
        textColor: getTextColorByShift(shift),
        borderColor: getBorderColorByShiftAndPreset(shift, shift.shiftPreset)
      }
    }

    return {
      id: shift.id,
      title: JSON.stringify(buildTitle(shift)),
      start: formatDate(shift.startDate),
      end: formatDate(shift.endDate),
      display: 'auto',
      color: 'white',
      borderColor: 'black',
      textColor: 'black'
    }
  }

  const buildInternshipInformation = async (oldInternship, presets) => {
    setLoading(true)

    const events = [{ ...internshipToEvent }]
    const json = await requestWithPromise('/internship/' + oldInternship.id, 'GET', null, props.getUser)

    const internship = json?.data.internship ?? { id: 0 }

    const response = await getInternshipShifts(internship, props.getUser)
    const shifts = response?.data ?? []

    shifts.map(s => {
      let shift = null

      if (s.eventCodeType !== null) {
        shift = { ...s, eventCode: { code: props.t('event.code.' + s.eventCodeType.type) } }
      } else {
        shift = { ...s, eventCode: { code: '' } }
      }

      const title = buildTitle(shift)
      const preset = shift.periodCode && shift.shiftPreset ? presets[shift.periodCode] : null
      const shiftToEvent = {
        id: shift.id,
        title: JSON.stringify(title),
        start: formatDate(shift.startDate),
        end: formatDate(shift.endDate),
        display: 'auto'
      }

      shiftToEvent.color = getColorByPreset(preset)
      shiftToEvent.textColor = getTextColorByShift(shift)
      shiftToEvent.borderColor = getBorderColorByShiftAndPreset(shift, preset)

      events.push(shiftToEvent)
    })

    let actTypes = []

    if (internship.section.actsMandatory) {
      const json = await getInternshipInternshipYearActTypes(internship, props.getUser)
      if (json?.data) {
        actTypes = json.data
      }
    }

    setActTypes(actTypes)
    setShifts(shifts)
    setLoading(false)
    setEvents(events)
  }

  const onDateClick = dates => {
    setAreDatesSelected(true)
    setSelectedDates(dates)
  }

  const onUnselect = () => {
    if (width < BREAK_POINT) {
      setAreDatesSelected(false)
      setSelectedDates('')
      setWarning(false)
    }
  }

  const onEventClick = eventInfo => {
    // workaround fullcalendar bug : when short press on background event, nothing should happen
    if (eventInfo.event.display === 'background') {
      return
    }

    setSelectedDates(eventInfo.event.startStr)

    requestWithPromise('/shift/' + eventInfo.event.id, 'GET', null, props.getUser)
      .then(({ data }) => {
        let eventCode = { code: null }
        let eventCodeType = null

        if (data.eventCodeType !== null) {
          eventCodeType = eventCodes.find(e => e.id === data.eventCodeType)
          eventCode = { code: props.t('event.code.' + eventCodeType.type) }
        }

        const shift = {
          id: data.id,
          start: data.startDate.date,
          end: data.endDate.date,
          pause: data.pause,
          eventCode,
          eventCodeType: eventCodeType,
          deleted: data.deleted
        }

        setSelectedShift(shift)
      })
  }

  const handleModalClose = () => {
    setCreationModalVisible(false)
    setAreDatesSelected(false)
    setSelectedDates('')
    setSelectedShift(undefined)
    setWarning(false)
  }

  const handleCreationModalSubmit = values => {
    if (values.preset === undefined || (values.preset.length <= 0 && !values.customSchedule)) {
      alert(props.t('shifts_calendar.alerts.no_schedule_selected'))
      return
    }

    setCreationModalVisible(false)

    const endDate = moment(selectedDates.end)
    let startDate = moment(selectedDates.start)

    let eventCodeType = null

    if (!Array.isArray(values.eventCodeType) && values.eventCodeType !== undefined && values.eventCodeType !== null && values.eventCodeType.type !== null) {
      eventCodeType = values.eventCodeType
    }

    const body = {
      internship: internship.id,
      periodCode: values.preset.periodCode ?? null,
      startTime: values.customSchedule.startTime ?? null,
      endTime: values.customSchedule.endTime ?? null,
      pause: values.customSchedule.pause ?? null,
      eventCodeType,
      isDesiderata: true
    }

    const promises = []
    let count = 0

    while (startDate < endDate && (maxDesiderata === null || count < desiderataLimit || internship.school.managed)) { //eslint-disable-line
      count++
      body.startDate = startDate.format('YYYY-MM-DD')

      promises.push(requestWithPromise('/shift/create-from-preset', POST, body, props.getUser).then(
        ({ data }) => data.shift)
      )
      startDate = startDate.add(1, 'd')
    }

    Promise.all(promises).then((newShifts) => {
      const localShifts = [...shifts]

      newShifts.forEach((s) => {
        if (s.eventCodeType !== null) {
          s.eventCode = { code: props.t('event.code.' + s.eventCodeType.type) }
        }

        addEvent(makeNewEvent(s))
        localShifts.push(s)
      })

      setShifts(localShifts)
    })

    setAreDatesSelected(false)
    setSelectedDates('')
    setWarning(false)
  }

  const handleUpdateModalSubmit = (values, isDelete = false) => {
    setSelectedShift(undefined)

    if (isDelete) {
      requestWithPromise('/api/Shift/' + values.id, 'DELETE', null, props.getUser)
        .then(() => {
          setEvents(events.filter(event => event.id !== values.id))
          setShifts(shifts.filter(shift => shift.id !== values.id))
        })
    } else {
      // If the desiderata is longer than 24 hours

      const dateStartTime = values.startTime.toDate()
      const dateEndTime = values.endTime.toDate()

      const msBetweenDates = Math.abs(dateStartTime.getTime() - dateEndTime.getTime())

      // convert ms to hours                      min  sec  ms
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000)

      let endDate = moment(dateEndTime)

      if (hoursBetweenDates > 24) {
        dateEndTime.setDate(dateEndTime.getDate() - 1)
        endDate = moment(dateEndTime)
      }

      const body = {
        startDate: values.startTime.format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
        endDate: endDate.format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
        startTime: values.startTime.format('HH:mm:ss'),
        endTime: endDate.format('HH:mm:ss'),
        pause: values.pause,
        eventCodeType: !values.eventCodeType || values.eventCodeType.type === null ? null : values.eventCodeType.id,
        deleted: values.deleted,
        isDesiderata: true
      }

      if (
        body.deleted !== selectedShift.deleted ||
        body.endDate !== selectedShift.end ||
        body.startDate !== selectedShift.start ||
        body.pause !== selectedShift.pause ||
        body.eventCodeType !== selectedShift.eventCodeType
      ) {
        requestWithPromise('/api/Shift/' + values.id, 'PATCH', body, props.getUser)
          .then(({ data }) => {
            const startTime = formatTime(data.startDate.date)
            const endTime = formatTime(data.endDate.date)

            if (data.eventCodeType !== null) {
              const eventCodeType = eventCodes.find(e => e.id === data.eventCodeType)
              data = { ...data, eventCodeType: eventCodeType, eventCode: { code: props.t('event.code.' + eventCodeType.type) } }
            } else {
              data = { ...data, eventCode: { code: '' }, eventCodeType: null }
            }

            const { main } = buildTitle(data)

            const preset = shiftPresets[data.periodCode]
            const title = setTitle(startTime, endTime, main)

            const updatedEvent = {
              id: data.id,
              title: JSON.stringify(title),
              start: formatDate(data.startDate.date),
              end: formatDate(data.endDate.date),
              display: 'auto',
              color: getColorByPreset(preset),
              textColor: getTextColorByShift(data),
              borderColor: getBorderColorByShiftAndPreset(data, preset)
            }

            const updatedEvents = events.map(event => {
              if (event.id === data.id) {
                data.deleted === false ? event = updatedEvent : event = {}
              }
              return event
            })

            setEvents(updatedEvents)
          })
      }
    }
  }

  const setTitle = (startTime, endTime, main) => {
    const time = startTime + ' - ' + endTime

    const title = {
      main: main ?? time,
      subtitle: time
    }

    if (title.main === title.subtitle) {
      return { main: time }
    } else {
      return { main: main, subtitle: time }
    }
  }

  const renderEventContent = eventInfo => {
    if (!eventInfo.event.title || eventInfo.event.title === 'Internship' || eventInfo.event.title === 'Stage') {
      return (<div />)
    }

    const title = JSON.parse(eventInfo.event.title)
    const tooltip = (
      <div>
        {title.main}
        <br />
        {title.subtitle}
      </div>
    )

    return (
      <Tooltip title={tooltip}>
        <div>
          <b>{title.main}</b>
          <br />
          <i>{title.subtitle}</i>
        </div>
      </Tooltip>
    )
  }

  const customButtonClick = useCallback(() => {
    if (desiderataInfo.useLimitation) {
      onWarning(props.t('The desiderata limit has been reached for this internship'))

      return
    }

    let shiftExists = false

    if (areDatesSelected) {
      const start = moment(selectedDates.startStr, API_DATE_FORMAT)
      const end = moment(selectedDates.endStr, API_DATE_FORMAT).subtract(1, 'days')
      const nbOfDays = end.diff(start, 'days') + 1

      if (nbOfDays > desiderataLimit) {
        setWarning(true)
      }

      shifts.map(s => {
        if (getTimezoneLessMoment(s.startDate).isBetween(start, end, 'day', '[]')) {
          shiftExists = true
        }
      })
    } else {
      alert(props.t('shifts_calendar.alerts.no_date(s)_selected'))
    }

    if (shiftExists) {
      alert(props.t('shifts_calendar.alerts.shift(s)_already_exist(s)'))
    } else {
      setCreationModalVisible(true)
    }
  }, [props.t, areDatesSelected, desiderataLimit, shifts, setCreationModalVisible, desiderataInfo.useLimitation, selectedDates, setWarning])

  const customButton = useMemo(() => {
    return {
      text: props.t(desiderataInfo.useLimitation ? 'No more days available' : 'Submit my schedule'),
      click: customButtonClick
    }
  }, [props.t, desiderataInfo.useLimitation, customButtonClick])

  const toolbars = useMemo(() => {
    const toolbars = {
      header: { start: 'title', end: 'prev,next' },
      footer: null
    }

    if (areDatesSelected && width > BREAK_POINT && isUpdatable) {
      toolbars.header.center = 'customButton'
    }

    return toolbars
  }, [areDatesSelected, width, isUpdatable])

  const contentHeight = useMemo(() => width < BREAK_POINT ? 'auto' : null, [width])
  const unselectAuto = useMemo(() => width >= BREAK_POINT, [width])

  return (
    <div className={'shifts-calendar-container' + (desiderataInfo.useLimitation ? ' calendar-disable' : '')}>
      <DesiderataHeaderButtonBar
        desiderataInfo={desiderataInfo}
        internship={internship}
        loading={loading}
        shifts={shifts}
        onBack={props.onBack}
        onInternshipUpdate={props.onInternshipUpdate}
        useDesiderataLimitation={maxDesiderata !== null}
      />
      <FullCalendar
        // listPlugin allows the calendar to function correctly - do not delete
        plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
        locale={locale}
        initialView='dayGridMonth'
        firstDay={1}
        selectable
        initialDate={initialDate}
        events={events}
        eventClick={onEventClick}
        select={onDateClick}
        unselectAuto={unselectAuto}
        unselect={onUnselect}
        customButtons={{ customButton: customButton }}
        headerToolbar={toolbars.header}
        footerToolbar={toolbars.footer}
        handleWindowResize
        contentHeight={contentHeight}
        selectLongPressDelay={1}
        longPressDelay={1}
        eventContent={renderEventContent}
      />
      <DesiderataCreationModal
        visible={creationModalVisible}
        dates={selectedDates}
        warning={maxDesiderata !== null && warning && !internship.school.managed}
        desiderataLimit={desiderataLimit}
        presets={shiftPresets}
        actTypes={actTypes}
        internshipState={internship.state}
        onCancel={() => handleModalClose()}
        onSubmit={handleCreationModalSubmit}
      />
      <DesiderataUpdateModal
        visible={!!selectedShift}
        date={selectedDates}
        shift={selectedShift}
        isUpdatable={isUpdatable}
        actTypes={actTypes}
        internshipState={internship.state}
        internship={internship}
        onCancel={() => handleModalClose()}
        onSubmit={handleUpdateModalSubmit}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsCalendar)
