import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import TableHeader from '../shared/Tables/TableHeader'
import TableFooter from '../shared/Tables/TableFooter'
import { Button, Input, Popconfirm, Select, Tag, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../assets/user-sector-table.scss'
import CheckboxUserSectorTable from '../shared/CheckboxUserSectorTable'
import { canonizeString } from '../../utils'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const DEFAULT_LINE_SHOWN = 20
const SEARCH_DEFAULT_TIMER = 500
const DATA_TYPE_SELECT = 0
const DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR = 1
const DATA_TYPE_CHECK_CONTACT_PERSON = 2
const DATA_TYPE_TAG = 3
const UNSELECT_TEXT = 'Unselect all'
const SELECT_TEXT = 'Select all'

const Option = Select.Option

const UserSectorTable = props => {
  const [pageIndex, setPageIndex] = useState(1)
  const [maxPageIndex, setMaxPageIndex] = useState(1)
  const [linesCount, setLinesCount] = useState(0)
  const [checkboxList, setCheckboxList] = useState([])
  const [checkAllCheckboxText, setCheckAllCheckboxText] = useState({
    contactPerson: SELECT_TEXT,
    admin: SELECT_TEXT
  })
  const [numberCheckedBox, setNumberCheckedBox] = useState({
    contactPerson: 0,
    admin: 0
  })
  const [isAdmin, setIsAdmin] = useState(false)
  const [isDataAvailable, setIsDataAvailable] = useState(false)
  const timer = useRef(null)

  useEffect(() => {
    if (props.data.length > 0) {
      setMaxPageIndex(props.data.length % DEFAULT_LINE_SHOWN === 0
        ? Math.floor(props.data.length / DEFAULT_LINE_SHOWN)
        : Math.floor(props.data.length / DEFAULT_LINE_SHOWN) + 1
      )
      setLinesCount(props.data.length)

      if (pageIndex !== 1) {
        setPageIndex(1)
      }

      const checkboxList = []
      let countContactPerson = 0
      let countAdmin = 0

      props.data.forEach(sector => {
        const isCheckedContactPerson = props.userIsContactPerson(sector.id)
        const isCheckedAdmin = props.userIsAdmin(sector.id)

        if (isCheckedContactPerson) {
          countContactPerson++
        }

        if (isCheckedAdmin) {
          countAdmin++
        }

        checkboxList.push({
          ...sector,
          isCheckedContactPerson: isCheckedContactPerson,
          isCheckedAdmin: isCheckedAdmin
        })
      })

      checkboxList.sort((a, b) => {
        return b.isCheckedAdmin - a.isCheckedAdmin || b.isCheckedContactPerson - a.isCheckedContactPerson
      })

      setCheckboxList(checkboxList)
      setNumberCheckedBox({
        contactPerson: countContactPerson,
        admin: countAdmin
      })
    } else {
      setCheckboxList([])
    }
  }, [props.data])

  useEffect(() => {
    setIsDataAvailable(checkboxList.length > 0 || props.data.length > 0)
  }, [props.data, checkboxList])

  useEffect(() => {
    const contactPerson = checkboxList.length === numberCheckedBox.contactPerson ? UNSELECT_TEXT : SELECT_TEXT
    const admin = checkboxList.length === numberCheckedBox.admin ? UNSELECT_TEXT : SELECT_TEXT

    setCheckAllCheckboxText({ admin: admin, contactPerson: contactPerson })
  }, [checkboxList.length, numberCheckedBox])

  useEffect(() => {
    const userRole = props.user === null ? [] : props.user.roles

    setIsAdmin(userRole.includes('ROLE_HOSPITAL_ADMIN'))
  }, [props.user])

  const handleIndexChange = increment => {
    let newPageIndex = pageIndex + increment

    if (newPageIndex < 1) {
      newPageIndex = 1
    } else if (newPageIndex > maxPageIndex) {
      newPageIndex = maxPageIndex
    }

    setPageIndex(newPageIndex)
  }

  const handleSearchFieldChange = value => {
    if (pageIndex !== 1) {
      setPageIndex(1)
    }

    if (timer.current !== null) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      props.searchWithFilter(value, isAdmin)
    }, SEARCH_DEFAULT_TIMER)
  }

  const renderContainer = () => {
    if (isDataAvailable) {
      return (
        <table>
          <thead>
            {renderHead()}
          </thead>
          <tbody>
            {renderBodyAllCheck()}
            {renderBody()}
          </tbody>
        </table>
      )
    }

    return <span> {props.t('No data are actually available for this page.')} </span>
  }

  const renderHead = () => {
    return (
      <tr>
        {props.columns ? renderHeadDetails() : undefined}
        {props.additionalActions && !isAdmin && <th style={{ width: '100px' }} />}
      </tr>
    )
  }

  const renderHeadDetails = () => {
    return props.columns.map((column, index) => {
      if (column.type === DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR) {
        if (isAdmin) {
          return (
            <th key={index} style={{ cursor: 'default', textAlign: 'center' }}>
              {props.t(column.name)}
            </th>
          )
        }
      } else {
        return (
          <th key={index} style={{ cursor: 'default', textAlign: 'center' }}>
            {props.t(column.name)}
          </th>
        )
      }
    })
  }

  const renderBodyAllCheck = () => {
    if (props.columns) {
      return (
        <>
          <tr key='row_all' className='bordered' style={{ height: props.density ?? '32px' }}>
            <td key='col_all_title' id='col_all_title'>
              {props.t('Select all')}
            </td>
            {isAdmin && (
              <td key='col_all_local_administrator' id='col_all_local_administrator'>
                <input className='checkbox' type='checkbox' onChange={checkAllCheckboxAdmin} checked={checkAllCheckboxText.admin === UNSELECT_TEXT} />
              </td>
            )}
            <td key='col_all_contact_person' id='col_all_contact_person'>
              <input className='checkbox' type='checkbox' onChange={checkAllCheckboxContactPerson} checked={checkAllCheckboxText.contactPerson === UNSELECT_TEXT} />
            </td>
          </tr>
        </>
      )
    }
  }

  const checkAllCheckboxContactPerson = () => {
    const newCheckboxList = [...checkboxList]
    const newChangesIndex = []

    switch (checkAllCheckboxText.contactPerson) {
      case SELECT_TEXT:
        setCheckAllCheckboxText({ ...checkAllCheckboxText, contactPerson: UNSELECT_TEXT })

        Object.keys(newCheckboxList).forEach(key => {
          if (!newCheckboxList[key].isCheckedContactPerson) {
            newCheckboxList[key].isCheckedContactPerson = true
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox({ ...numberCheckedBox, contactPerson: checkboxList.length })
        setCheckboxList(newCheckboxList)
        props.setMultipleUserContactPersons(newChangesIndex)
        break
      case UNSELECT_TEXT:
        setCheckAllCheckboxText({ ...checkAllCheckboxText, contactPerson: SELECT_TEXT })

        Object.keys(newCheckboxList).forEach(key => {
          if (newCheckboxList[key].isCheckedContactPerson) {
            newCheckboxList[key].isCheckedContactPerson = false
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox({ ...numberCheckedBox, contactPerson: 0 })
        setCheckboxList(newCheckboxList)
        props.setMultipleUserContactPersons(newChangesIndex)
        break
      default:
        break
    }
  }

  const checkAllCheckboxAdmin = () => {
    const newCheckboxList = [...checkboxList]
    const newChangesIndex = []

    switch (checkAllCheckboxText.admin) {
      case SELECT_TEXT:
        setCheckAllCheckboxText({ ...checkAllCheckboxText, admin: UNSELECT_TEXT })

        Object.keys(newCheckboxList).forEach(key => {
          if (!newCheckboxList[key].isCheckedAdmin) {
            newCheckboxList[key].isCheckedAdmin = true
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox({ ...numberCheckedBox, admin: checkboxList.length })
        setCheckboxList(newCheckboxList)
        props.setMultipleManagedUsers(newChangesIndex)
        break
      case UNSELECT_TEXT:
        setCheckAllCheckboxText({ ...checkAllCheckboxText, admin: SELECT_TEXT })

        Object.keys(newCheckboxList).forEach(key => {
          if (newCheckboxList[key].isCheckedAdmin) {
            newCheckboxList[key].isCheckedAdmin = false
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox({ ...numberCheckedBox, admin: 0 })
        setCheckboxList(newCheckboxList)
        props.setMultipleManagedUsers(newChangesIndex)
        break
      default:
        break
    }
  }

  const renderBody = () => {
    if (props.columns) {
      const minIndex = DEFAULT_LINE_SHOWN * (pageIndex - 1)
      const maxIndex = minIndex + DEFAULT_LINE_SHOWN

      return checkboxList.slice(minIndex, maxIndex).map((data, index) => {
        return (
          <tr key={'row_' + index} style={{ height: props.density ?? '32px' }}>
            {props.columns.map((column, index) => {
              if (column.type === DATA_TYPE_SELECT) return renderSelect(data, index, column.options)
              if (column.type === DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR && isAdmin) return renderCheckbox(data, index, 0)
              if (column.type === DATA_TYPE_CHECK_CONTACT_PERSON) return renderCheckbox(data, index, 1)
              if (column.type === DATA_TYPE_TAG) return renderTag(data, index)
            })}
            {props.additionalActions && !isAdmin && renderAdditionalActions(data)}
          </tr>
        )
      })
    }

    return <tr> {props.t('No columns have been founded')} </tr>
  }

  const renderTag = (data, index) => {
    return (
      <td key={`col_${index}`}>
        <Tag color={!data.archived ? 'green' : 'red'}> {props.t(data.archived ? 'Archived' : 'Active')} </Tag>
      </td>
    )
  }

  const renderSelect = (data, index, options) => {
    const noDataSelected = props.t('Select a data')

    if (isAdmin) {
      return (
        <td key={`col_${index}`}>
          <Tooltip placement='top' title={data.name}>
            <div>{data.name}</div>
          </Tooltip>
        </td>
      )
    } else {
      return (
        <td key={`col_${index}`}>
          <Tooltip placement='top' title={data.name}>
            {data.archived && (
              <Input value={data.name} disabled />
            )}
            {!data.archived && (
              <Select
                showSearch
                value={data.id === null ? noDataSelected : data.id}
                filterOption={(input, option) =>
                  canonizeString(option.props.children).indexOf(
                    canonizeString(input)
                  ) > -1}
                onChange={e => {
                  updateDataValue(data, e)
                }}
              >
                {options.map(o => {
                  return (
                    <Option key={o.id} value={o.id} title={o.name}>
                      {o.name}
                    </Option>
                  )
                })}
              </Select>
            )}
          </Tooltip>
        </td>
      )
    }
  }

  const updateDataValue = (prevData, value) => {
    const sector = {
      prevId: prevData.id,
      newId: value
    }

    if (prevData.id === null) {
      props.onDataAdd(sector)
    } else {
      props.onDataEdit(sector)
    }
  }

  const renderCheckbox = (data, index, type) => {
    let isChecked = false
    let indexSector = 0

    for (const index in checkboxList) {
      if (data.sector === checkboxList[index].id) {
        if (type === 0) {
          isChecked = checkboxList[index].isCheckedAdmin
        } else {
          isChecked = checkboxList[index].isCheckedContactPerson
        }
        indexSector = index
      }
    }

    return (
      <td key={`col_contact_person${index}`}>
        <CheckboxUserSectorTable
          setUserContactPerson={setUserContactPerson}
          setManagedUser={setManagedUser}
          sectorId={data.sector}
          isChecked={isChecked}
          handleChangeChecked={handleChangeChecked}
          indexSector={indexSector}
          type={type}
        />
      </td>
    )
  }

  const setUserContactPerson = (sectorId, isChecked) => {
    if (isChecked) {
      setNumberCheckedBox({ ...numberCheckedBox, contactPerson: numberCheckedBox.contactPerson - 1 })
    } else {
      setNumberCheckedBox({ ...numberCheckedBox, contactPerson: numberCheckedBox.contactPerson + 1 })
    }

    props.setUserContactPerson(sectorId)
  }

  const setManagedUser = (sectorId, isChecked) => {
    if (isChecked) {
      setNumberCheckedBox({ ...numberCheckedBox, admin: numberCheckedBox.admin - 1 })
    } else {
      setNumberCheckedBox({ ...numberCheckedBox, admin: numberCheckedBox.admin + 1 })
    }

    props.setManagedUser(sectorId)
  }

  const handleChangeChecked = (indexSector, type) => {
    const newCheckboxList = [...checkboxList]

    if (type === 0) {
      newCheckboxList[indexSector].isCheckedAdmin = !checkboxList[indexSector].isCheckedAdmin
    } else {
      newCheckboxList[indexSector].isCheckedContactPerson = !checkboxList[indexSector].isCheckedContactPerson
    }

    setCheckboxList(newCheckboxList)
  }

  const renderAdditionalActions = rowData => {
    const additionalActionsJSX = props.additionalActions.map(action => {
      return (
        <Tooltip
          placement='top'
          title={props.t(action.title)}
          key={'action-' + action.title}
        >
          {typeof action.titlePopconfirm !== 'undefined'
            ? buttonConfirmAction(action, rowData)
            : buttonAction(action, rowData)}
        </Tooltip>
      )
    })

    return (
      <td className='buttons'>
        <div className='flex-row'>
          {additionalActionsJSX}
        </div>
      </td>
    )
  }

  const buttonAction = (action, rowData) => {
    return (
      <Button
        type={action.type}
        size='small'
        className='button'
        onClick={() => action.handleOnClick(rowData)}
        disabled={action.disabled ? action.disabled(rowData) : false}
      >
        <FontAwesomeIcon icon={action.icon} />
      </Button>
    )
  }

  const buttonConfirmAction = (action, rowData) => {
    return (
      <Popconfirm
        placement='top'
        okType={action.type}
        title={action.titlePopconfirm}
        okText={props.t('Yes')}
        cancelText={props.t('Cancel')}
        onConfirm={() => action.handleOnClick(rowData)}
      >
        <Button
          type={action.type}
          size='small'
          className='button'
          disabled={action.disabled ? action.disabled(rowData) : false}
        >
          <FontAwesomeIcon icon={action.icon} />
        </Button>
      </Popconfirm>
    )
  }

  const addNewLine = () => {
    const newCheckboxList = [...checkboxList]
    const newSector = {
      ...checkboxList[0],
      name: '',
      sector: null,
      id: null,
      isCheckedContactPerson: false,
      archived: false
    }

    newCheckboxList.unshift(newSector)
    setCheckboxList(newCheckboxList)

    const newNumberCheckedBox = numberCheckedBox + 1
    setNumberCheckedBox(newNumberCheckedBox)
  }

  return (
    <div className='interactive-table'>
      {isAdmin
        ? (
          <TableHeader
            onDataFilter={handleSearchFieldChange}
            disableAdd='true'
            disableSettings='true'
          />
        )
        : (
          <TableHeader
            onDataAdd={addNewLine}
            onDataFilter={handleSearchFieldChange}
            addButtonText='Add a care unit'
            disableSettings='true'
          />
        )}

      {renderContainer()}

      {isDataAvailable && (
        <TableFooter
          pageIndex={pageIndex}
          maxPageIndex={maxPageIndex}
          linesCount={linesCount}
          onIndexChange={handleIndexChange}
        />
      )}
    </div>
  )
}

export {
  DATA_TYPE_SELECT,
  DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR,
  DATA_TYPE_CHECK_CONTACT_PERSON,
  DATA_TYPE_TAG
}
export default connect(mapStateToProps)(UserSectorTable)
