import React, { useEffect, useState } from 'react'

import { Modal, Tag } from 'antd'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { isObject } from '../../utils'
import { getStudentBadges, getStudentFreeFields } from '../../utils/api/student'
import Loading from './Loading'

import '../../assets/internship-details-modal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons'
import { ACTIVE, INACTIVE, TERMINATED } from '../../utils/entities/badge'

const mapStateToProps = state => {
  return {
    currentLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const StudentAdditionalInfo = ({ currentLanguage, internship, user, onCancel, onOk, t }) => {
  const [badges, setBadges] = useState([])
  const [freefields, setFreeFields] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (internship?.student && user) {
      setLoading(true)

      const promiseSB = getStudentBadges(internship.student, user).then(json => {
        if (json?.data) {
          setBadges(json.data)
        }
      })
      const promiserSF = getStudentFreeFields(internship.student, user, { badgeDisplay: true }).then(json => {
        if (json?.data) {
          setFreeFields(json.data)
        }
      })

      Promise.all([promiseSB, promiserSF]).then(() => setLoading(false))
    }
  }, [user, internship])

  const getBadgeStatusTag = status => {
    let StatusTag = <Tag color='magenta'> {t('Unknow status')} </Tag>

    if (status === TERMINATED) {
      StatusTag = <Tag color='volcano'> {t('Terminated')} </Tag>
    } else if (status === INACTIVE) {
      StatusTag = <Tag color='geekblue'> {t('Inactive')} </Tag>
    } else if (status === ACTIVE) {
      StatusTag = <Tag color='green'> {t('Active')} </Tag>
    }

    return StatusTag
  }

  return (
    <Modal
      title={t('Internship additional information')}
      visible={isObject(internship)}
      onCancel={onCancel}
      onOk={onOk}
      destroyOnClose
    >
      {!loading && internship && (
        <div className='internship-extra-info-container'>
          {badges.length > 0 && (
            <div className='internship-badges'>
              <h3> {t('Badges')} </h3>
              {badges.map(b => {
                return (
                  <div key={b.id} className='badge-details'>
                    <FontAwesomeIcon icon={faIdBadge} size='2x' />

                    <div className='numbers'>
                      <span> <b> {t('Number')} </b>: {b.badgeNumber} </span>
                      <span> <b> {t('RFID')} </b>: {b.rfidNumber} </span>
                    </div>

                    <b> {t('Status')} </b> : &nbsp; {getBadgeStatusTag(b.status)}
                  </div>
                )
              })}
            </div>
          )}
          {freefields.length > 0 && (
            <div className='internship-free-fields'>
              <h3> {t('FreeFields')} </h3>
              <div className='free-fields-container'>
                {freefields.map(ff => {
                  const typeLabel = ff.fieldType.fieldTypelabels.find(ftl => ftl.language.languageLocales.find(llc => llc.languageTag === currentLanguage))

                  return (
                    <div key={ff.id} className='free-field-details'>
                      <b> {typeLabel?.label ?? t('Unknow')} </b>: {ff.fieldValue}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {badges.length === 0 && freefields.length === 0 && (
            <div className='empty-body'>
              {t('No available information for this internship')}
            </div>
          )}
        </div>
      )}
      {loading && <Loading size='3x' />}
    </Modal>
  )
}

export default connect(mapStateToProps)(StudentAdditionalInfo)
