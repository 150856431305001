import React, { useState, useEffect } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import TableFooter from './TableFooter'
import SearchField from '../../antd/Inputs/SearchField'
import { SUPPORTED_FORMATS } from '../../antd/Buttons/ExportButton'
import TableConfig from './TableConfig'
import { isArray } from 'lodash'
import { Button, DatePicker, Dropdown, Menu, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faEdit, faFileExport, faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { DIRECTION_ASC, DIRECTION_DESC } from '../../../utils/constants'
import moment from 'moment'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { URIDecode, URIEncode } from '../../../utils/apiHelper'
import { isObject } from '../../../utils'
import TableFilters from './TableFilters'
import Loading from '../Loading'
import TableExternalActions from './TableExternalActions'
import { FA_2X } from '../../institution/Quotas/utils/Constants'
import { COLORS_PALETTE } from '../../../config/colors'

import '../../../assets/data-table.scss'

export const TIVOLI_EXPORT = 'Tivoli'

const ACTION_TD_STYLE = { position: 'sticky', right: '0px', zIndex: '2', display: 'flex', flexDirection: 'row', justifyContent: 'center' }

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const DataTable = (
  {
    actions, columns, data, disabledAdd = false, display = {}, externalActions = [], filters = [], footerStyle = { paddingRight: '120px' }, help, loading,
    metadata, parameters, period, useCustomExport, onExport, onBasicExport, onCreate, onParametersChange, onPeriodChange, onSearch, t, customLineStyle = {}
  }
) => {
  const [hiddenColumns, setHiddenColumns] = useState([])
  const [currentColumns, setCurrentColumns] = useState([])
  const [tableOptions, setTableOptions] = useState({ tooltip: true })

  useEffect(() => {
    if (columns) {
      setCurrentColumns(columns.filter(c => !hiddenColumns.includes(c.title)))
    }
  }, [columns, hiddenColumns])

  const handlePageChange = newPage => {
    onParametersChange(new TableParameters({ ...parameters, page: newPage }))
  }

  const handleConfigSelect = ({ value, type }) => {
    const newMetadata = new TableParameters({ ...parameters })

    newMetadata[type] = value
    newMetadata.page = 1

    onParametersChange(newMetadata)
  }

  const getValueByKey = (d, key, translate) => {
    if (typeof key === 'string') {
      return translate ? t(d[key]) : d[key]
    }

    if (typeof key === 'function') {
      return translate ? t(key(d)) : key(d)
    }

    return translate ? t('Invalid key type') : 'Invalid key type'
  }

  return (
    <div className='data-table'>
      <div className='data-table-header flex-row'>
        {parameters.search && (
          <SearchField
            value={URIDecode(parameters.search ?? '')}
            className='assistant-reporting-search-field'
            placeholder='Search'
            onChange={search => onParametersChange(new TableParameters({ ...parameters, search: URIEncode(search) }))}
          />
        )}
        <TableConfig
          columns={columns}
          metadata={metadata}
          parameters={tableOptions}
          onSelect={handleConfigSelect}
          onHiddenColumnsChange={setHiddenColumns}
          onParametersChange={setTableOptions}
          columnsSelector
        />
        {onExport && (
          <Dropdown
            overlay={
              <Menu onClick={e => onExport(e.item.props.data)}>
                {SUPPORTED_FORMATS.map((f, index) => {
                  return <Menu.Item key={index} data={f.value}>{f.label}</Menu.Item>
                })}
                {useCustomExport && (
                  <Menu.Item key='custom' data={TIVOLI_EXPORT}>{t(TIVOLI_EXPORT)}</Menu.Item>
                )}
              </Menu>
            }
            trigger={['click']}
          >
            <Button>
              <FontAwesomeIcon icon={faFileExport} />
            </Button>
          </Dropdown>
        )}
        {onBasicExport && (
          <Button onClick={onBasicExport}>
            <FontAwesomeIcon icon={faFileExport} />
          </Button>
        )}
        {filters.length > 0 && (
          <TableFilters filters={filters} parameters={parameters} onParametersChange={onParametersChange} />
        )}
        {externalActions.length > 0 && (
          <TableExternalActions actions={externalActions} />
        )}
        {help && (
          <Tooltip title={t(help)}>
            <FontAwesomeIcon icon={faQuestionCircle} size={FA_2X} color={COLORS_PALETTE.SECOND} style={{ marginLeft: '5px' }} />
          </Tooltip>
        )}
        <div className='flex-fill' />
        {display.period && (
          <DatePicker.RangePicker
            value={[moment(parameters.period.startDate), moment(parameters.period.endDate)]}
            onChange={dates => onParametersChange(new TableParameters({ ...parameters, period: { startDate: dates[0], endDate: dates[1] } }))}
          />
        )}
        {typeof onCreate === 'function' && (
          <Button type='primary' onClick={onCreate} disabled={disabledAdd}>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            {t('Add')}
          </Button>
        )}
      </div>
      <div className='data-table-container'>
        <div className='table-container'>
          <table className='table'>
            <thead>
              <tr>
                {currentColumns.map((c, index) => {
                  const props = { style: { ...c.style } ?? {} }
                  let isSelectedOrder = false

                  if (!props.style.cursor) {
                    props.style.cursor = 'default'
                  }

                  if (c.orderBy && parameters.orderBy) {
                    isSelectedOrder = c.orderBy === parameters.orderBy

                    props.style.cursor = 'pointer'

                    props.onClick = () => onParametersChange(new TableParameters({
                      ...parameters,
                      orderBy: c.orderBy,
                      orderDirection: isSelectedOrder && parameters.orderDirection === DIRECTION_ASC ? DIRECTION_DESC : DIRECTION_ASC
                    }))
                  }

                  return (
                    <th key={'col-' + index} {...props}>
                      <div className='data-table-title'>
                        {t(c.title)}
                        {isSelectedOrder && (
                          <>
                            <div className='flex-fill' />
                            <FontAwesomeIcon
                              icon={parameters.orderDirection === DIRECTION_ASC ? faCaretUp : faCaretDown}
                              size='2x'
                            />
                          </>
                        )}
                      </div>
                    </th>
                  )
                })}
                {isArray(actions) && actions.length > 0 && (
                  <th key='actions' style={{ cursor: 'default', position: 'sticky', right: '0px', zIndex: '2', background: 'white' }}>
                    {t('Actions')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {!loading && data.map((d, rindex) => {
                return (
                  <tr key={'row-' + rindex}>
                    {currentColumns.map((c, cindex) => {
                      let content = getValueByKey(d, c.key, c.translate)

                      if (c.tooltip && tableOptions.tooltip) {
                        const tooltipProps = {}

                        if (typeof c.tooltip === 'function') {
                          tooltipProps.title = getValueByKey(d, c.tooltip, c.translate)
                        } else if (isObject(c.tooltip)) {
                          tooltipProps.title = getValueByKey(d, c.tooltip.getter, c.translate)
                          tooltipProps.placement = c.tooltip.placement ?? 'top'
                        }

                        content = <Tooltip {...tooltipProps}> {content} </Tooltip>
                      }

                      return (
                        <td key={`cell-${rindex}.${cindex}`} style={{ ...c.style ?? {}, ...customLineStyle }}>
                          {content}
                        </td>
                      )
                    })}
                    {isArray(actions) && actions.length > 0 && (
                      <td style={{ ...ACTION_TD_STYLE, ...customLineStyle, background: rindex % 2 ? 'white' : 'whitesmoke' }}>
                        {actions.map((a, index) => {
                          if (typeof a.toRender === 'function' && !a.toRender(d)) {
                            return null
                          } else if (typeof a.render === 'function') {
                            const props = { key: `actions-${index}` }

                            if (a.containerClassName) {
                              props.className = a.containerClassName
                            }

                            return (
                              <div {...props}> {a.render(d, index, t)} </div>
                            )
                          } else {
                            return (
                              <Tooltip key={`actions-${index}`} placement='top' title={t(a.title)}>
                                <Button onClick={() => a.onClick(d)}>
                                  <FontAwesomeIcon icon={a.icon ?? faEdit} />
                                </Button>
                              </Tooltip>
                            )
                          }
                        })}
                      </td>
                    )}
                  </tr>
                )
              })}
              {loading && (
                <tr>
                  <td colSpan={columns.length + 1} style={{ backgroundColor: 'white' }}>
                    <Loading size='2x' style={{ marginBottom: '30px' }} />
                  </td>
                </tr>
              )}
              {!loading && data.length === 0 && (
                <tr>
                  <td colSpan={columns.length + 1} style={{ backgroundColor: 'white' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontWeight: 'bold' }}>
                      {t('data_table.no-data-available')}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {!loading && metadata?.total > 0 && (
          <TableFooter
            className='data-table-footer'
            style={footerStyle}
            metadata={metadata}
            deprecatedPage={parameters.page}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(DataTable)
