import React, { useCallback, useMemo } from 'react'

import IconLabel from '../../icons/IconLabel'
import { faClinicMedical, faClock, faCompass, faEnvelope, faNotesMedical, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import { isObject } from '../../../utils'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import Switch from '../Switch'
import { getFullNameOrNull } from '../../../utils/entities/user'
import Italic from '../Italic'
import { List, Tag, Tooltip } from 'antd'
import { getFullAbbreviation } from '../../../utils/entities/sectionToYear'
import { isArray } from 'lodash'
import { EUROPEAN_FULL_DATE, getMoment } from '../../../utils/momentjs'

const { Item } = List

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const SectorIdCard = ({ sector, t }) => {
  const updatedAt = sector?.updatedAt ? ` (${getMoment(sector.updatedAt).format(EUROPEAN_FULL_DATE)})` : ''

  const compositions = useMemo(() => {
    const compositions = { length: 0, parts: [] }

    if (sector?.composition) {
      Object.keys(sector.composition).forEach(k => {
        if (sector.composition[k] > 0) {
          compositions.length++
          compositions.parts.push({ value: sector.composition[k], key: k })
        }
      })
    }

    return compositions
  }, [sector?.composition])

  const renderTutors = useCallback(t => {
    const fullname = getFullNameOrNull(t)

    return (
      <Item>
        <div>
          <Switch on={fullname}> <IconLabel icon={faUser} text={<Italic text={fullname} />} /> </Switch>
          <IconLabel icon={faEnvelope} text={t.email} />
          <Switch on={t.phoneNumber}> <IconLabel icon={faPhone} text={t.phoneNumber} /> </Switch>
        </div>
      </Item>
    )
  }, [])

  const scheduleText = useMemo(() => sector.schedule ? t(`sector_schedules.${sector.schedule.toLowerCase()}`) : '', [sector.schedule, t])
  const typeText = useMemo(() => sector.type ? t(`sector_types.${sector.type.toLowerCase()}`) : '', [sector.type, t])

  return (
    <div className='sector-id-card-container flex-row'>
      <div className='flex-col w-50'>
        <div className='service-informations-container mb-8'>
          <b> {t('sector_id_card.service_informations_title')}</b>
          <Switch on={isObject(sector.institution)}> <IconLabel icon={faClinicMedical} text={sector.institution.name} /> </Switch>
          <Switch on={sector.emplacement}> <IconLabel icon={faCompass} text={sector.emplacement} /> </Switch>
          <Switch on={sector.phoneNumber}> <IconLabel icon={faPhone} text={sector.phoneNumber} /> </Switch>
          <Switch on={sector.schedule}> <IconLabel icon={faClock} text={scheduleText} /> </Switch>
          <Switch on={sector.type}> <IconLabel icon={faNotesMedical} text={typeText} /> </Switch>
        </div>
        {sector.manager && (
          <div className='service-manager-container mb-8'>
            <b> {t('sector_id_card.service_manager')} :</b>
            <Switch on={getFullNameOrNull(sector.manager)}> <IconLabel icon={faUser} text={<Italic text={getFullNameOrNull(sector.manager)} />} /> </Switch>
            <IconLabel icon={faEnvelope} text={sector.manager.email} />
            <Switch on={sector.manager.phoneNumber}> <IconLabel icon={faPhone} text={sector.manager.phoneNumber} /> </Switch>
          </div>
        )}
        {!!sector.tutors?.length && (
          <div className='service-tutors-container mb-8'>
            <b> {t('sector_id_card.tutors')} :</b>
            <div style={{ maxHeight: '200px' }} className='ovy-a'>
              <List
                size='small'
                dataSource={sector.tutors}
                renderItem={renderTutors}
              />
            </div>
          </div>
        )}
        <div className='flex-col mb-8'>
          <b> {t('sector_id_card.last_update')} :</b>
          <span className='ft-sm'> {updatedAt} </span>
        </div>
      </div>
      <div className='flex-col w-50'>
        <Switch on={sector.comment}>
          <div className='flex-col mb-8'>
            <b> {t('sector_id_card.comment')}</b>
            <span className='pdl-8 mh-100p ovy-a ft-sm'> {sector.comment} </span>
          </div>
        </Switch>
        <Switch on={sector.institution.globalMessage}>
          <div className='flex-col global-message-container'>
            <b> {t('sector_id_card.global_message')}</b>
            <span className='pdl-8 mh-24p ovy-a ft-sm'> {sector.institution.globalMessage} </span>
          </div>
        </Switch>
        <Switch on={!!compositions.length}>
          <div className='flex-col composition-container'>
            <b> {t('sector_id_card.composition')}</b>
            <div className='flex-row pdl-8'>
              {compositions.parts.map(part => (
                <span className='ft-sm' key={part.key} style={{ marginRight: '20px' }}> {t('composition.' + part.key)} : {part.value} </span>
              ))}
            </div>
          </div>
        </Switch>
        {isArray(sector.actTypes) && sector.actTypes.length > 0 && (
          <div className='flex-col global-message-container'>
            <b> {t('sector_id_card.internship_types')} </b>
            <div>
              {sector.actTypes.map((at, index) => (
                <Tooltip key={at.acronym + index} title={at.name}>
                  <Tag> {at.acronym} </Tag>
                </Tooltip>
              ))}
            </div>
          </div>
        )}
        {isArray(sector.studentPathways) && sector.studentPathways.length > 0 && (
          <div className='flex-col mt-8'>
            <div className='flex-row'>
              <span className='w-33'> <b> {t('sector_id_card.student_pathway')}</b> </span>
              <span className='w-67'> <b> {t('sector_id_card.pathway_details')}</b> </span>
            </div>
            <div className='scroll-overflow' style={{ maxHeight: '280px' }}>
              {sector.studentPathways.map(sp => (
                <div key={'line_' + sp.id} className='flex-row'>
                  <span className='w-33 text-ellipsis ft-sm pdl-8'> {getFullAbbreviation(sp.sectionToYear)} </span>
                  <span className='w-67 text-ellipsis ft-sm'> <Tooltip title={sp.details} placement='topLeft'> {sp.details} </Tooltip> </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(SectorIdCard)
