import { requestMultipart, requestWithPromise } from '..'
import { DELETE, GET, PATCH, POST, makeQueryStringFromObject, makeQueryString } from '../apiHelper'
import { TYPES } from '../entities/internshipDocument'
import { PeriodExportParameters } from './export' //eslint-disable-line

export const acceptInternship = (internship, user) => {
  return requestWithPromise('/internship/accept',
    POST,
    { internship: internship.id },
    user
  )
}

export const cancelInternship = (internship, user) => {
  return requestWithPromise('/internship/cancel',
    POST,
    { internship: internship.id },
    user
  )
}

export const deleteInternship = (internship, user) => {
  return requestWithPromise(
    `/api/internships/${internship.id}`,
    DELETE,
    null,
    user
  )
}

export const duplicateInternship = (internship, user) => {
  return requestWithPromise(
    '/internship/duplicate',
    POST,
    { internship: internship.id },
    user
  )
}

export const getAllInternships = (user, queryParameters) => {
  return requestWithPromise(
    `/api/internships${makeQueryString(queryParameters)}`,
    GET,
    null,
    user
  )
}

export const preAccept = (internship, user) => {
  return requestWithPromise(`/internship/${internship.id}/pre-accept`,
    PATCH,
    {},
    user
  )
}

export const refuseInternship = (user, internship, reasons, comment) => {
  return requestWithPromise('/internship/refuse',
    POST,
    { internship: internship.id, reasons, comment },
    user
  )
}

export const resetToCreated = (internship, user) => {
  return requestWithPromise(`/internship/${internship.id}/created`,
    PATCH,
    {},
    user
  )
}

export const resetToPending = (internship, user) => {
  return requestWithPromise('/internship/reset-state',
    POST,
    { internship: internship.id },
    user
  )
}

export const updateInternship = (internship, body, user) => {
  return requestWithPromise(
    `/api/internships/${internship.id}`,
    PATCH,
    body,
    user
  )
}

export const oldCreateInternship = (internshipData, user) => {
  return requestWithPromise(
    '/api/internship',
    POST,
    internshipData,
    user
  )
}

export const linkInternshipToAct = (internship, act, user, customErrorHandling = false) => {
  return requestWithPromise(
    `/api/internships/${internship}/act-types`,
    POST,
    { actType: act },
    user,
    true,
    customErrorHandling
  )
}

export const unlinkInternshipFromAct = (internship, act, user) => {
  return requestWithPromise(
    `/api/internships/${internship}/act-types/${act}`,
    DELETE,
    null,
    user
  )
}

export const getLinkedActs = (internship, user) => {
  return requestWithPromise(
    `/api/internships/${internship}/act-types`,
    GET,
    null,
    user
  )
}

export const getInternshipShifts = (internship, user) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/shifts`,
    GET,
    null,
    user
  )
}

export const uploadInternshipConvention = (internship, convention, user) => {
  const body = {
    'document[internship]': internship.id,
    file: convention,
    type: TYPES.CONVENTION
  }

  return requestMultipart(
    '/internship/document/upload',
    POST,
    body,
    user
  )
}

export const uploadInternshipDocument = (user, internship, file) => {
  return requestMultipart(
    '/internship/document/upload',
    POST,
    {
      'document[internship]': internship.id,
      file,
      type: TYPES.DOCUMENT
    },
    user
  )
}

export const getTotalHoursActsByInternship = (internship, user) => {
  return requestWithPromise(
    `/internship/${internship.id}/schedule`,
    GET,
    null,
    user
  ).then(json => {
    return json?.data ?? {}
  })
}

export const addInternshipScheduleComplements = (internship, scheduleComplement, user) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/schedule-complements`,
    POST,
    scheduleComplement.toRequestBody(),
    user
  )
}

export const getScheduleComplements = (internship, user) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/schedule-complements`,
    GET,
    null,
    user
  )
}

export const deleteScheduleComplement = (internship, scheduleComplement, user) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/schedule-complements/${scheduleComplement.id}`,
    DELETE,
    null,
    user
  )
}

export const updateInternshipScheduleComplement = (internship, scheduleComplement, user) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/schedule-complements/${scheduleComplement.id}`,
    PATCH,
    scheduleComplement.toRequestBody(),
    user
  )
}

export const getInternshipDocuments = (user, internship) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/documents`,
    GET,
    null,
    user
  )
}

export const getInternshipInternshipYearActTypes = (internship, user, parameters = {}) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/internship-year/act-types${makeQueryStringFromObject(parameters)}`,
    GET,
    null,
    user
  )
}

export const getRelatedInternships = (internship, user, parameters) => {
  return requestWithPromise(
    `/internship/${internship.id}/related-internships${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const validateInternship = (user, internship) => {
  return requestWithPromise(
    '/internship/validate-schedule',
    POST,
    { internship: internship.id },
    user,
    true,
    true
  )
}

export const validateInternshipDesideratas = (user, internship) => {
  return requestWithPromise(
    `/internship/${internship.id}/shifts/desiderata`,
    PATCH,
    { isDesiderata: false },
    user
  )
}

/**
 * @param {PeriodExportParameters} parameters
 */
export const exportInternshipsByPeriod = (user, parameters) => {
  return requestWithPromise(
    '/internship/export-internships-daterange',
    POST,
    parameters,
    user,
    true,
    true
  )
}

export const exportInternshipsByPeriodTivoli = (user, parameters) => {
  return requestWithPromise(
    '/export/internships-reporting/custom/tivoli',
    POST,
    parameters,
    user,
    true,
    true
  )
}

export const getInternshipsByPeriod = (user, parameters) => {
  return requestWithPromise(
    `/api/internships/date-range${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const updateInternshipDraftProperty = (user, internship, isDraft) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/is-draft`,
    PATCH,
    { value: isDraft },
    user
  )
}

export const updateInternshipsDraftProperty = (user, internshipIds, isDraft) => {
  return requestWithPromise(
    '/internship/is-draft',
    PATCH,
    {
      ids: internshipIds,
      value: isDraft
    },
    user
  )
}

export const deleteInternshipShifts = (user, internship) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/shifts`,
    DELETE,
    null,
    user
  )
}

export const deleteInternshipsShifts = (user, internshipIds) => {
  return requestWithPromise(
    `/internship/shifts${makeQueryString({ ids: internshipIds })}`,
    DELETE,
    null,
    user
  )
}

export const exportTimeline = (user, parameters) => {
  return requestWithPromise(
    '/internship/export-timeline',
    POST,
    parameters,
    user,
    true,
    true
  )
}
