import React, { useEffect, useState, useRef, Suspense, useContext } from 'react'

import moment from 'moment-timezone'
import { Button, Drawer, notification, Popconfirm, Tabs, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'
import { request, generalErrorHandler, downloadFile, formatTimeFromMinutes, hoursToMinutes, requestWithPromise } from '../../../../utils'
import { recruitmentModuleEnabled } from '../../../../utils/internship-utils'
import { ACT_HOURS_VERIFICATION, INSTITUTION_CONTEXT, SCHOOL_CONTEXT, STUDENT_CONTEXT, internshipStates } from '../../../../utils/constants'
import ActsTable from './ActsTable'
import InvalidateInternshipModal from '../../InvalidateInternshipModal'
import ValidationWarning from './ValidationWarning'
import { getTotalHoursActsByInternship, getScheduleComplements, getInternshipShifts, getInternshipInternshipYearActTypes, validateInternship, getInternshipDocuments } from '../../../../utils/api/internship'
import { ABSENCE_CATEGORY } from '../constants'
import { convertTime, getTimezoneLessMoment, HOURS_SPLIT_CHARACTER, secondsToTime, timeToSeconds } from '../../../../utils/momentjs'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { fetchSchoolOptions } from '../../../../utils/api/school'
import { HTTP_BAD_REQUEST } from '../../../../utils/http'
import { GET, onError } from '../../../../utils/apiHelper'

import '../../../../assets/internship-validation-summary.scss'

const { TabPane } = Tabs
const HOVERING_BUTTON_STYLE = {
  backgroundColor: '#FF7F7F',
  fontWeight: 'bold',
  color: 'white'
}

const INITIAL_VALIDATION_BUTTON_TEXT = 'Internship validated'
const HOVERING_VALIDATION_BUTTON_TEXT = 'Invalidate?'
const INITIAL_VALIDATION_BUTTON_ICON = faCheck
const HOVERING_VALIDATION_BUTTON_ICON = faTimes
const HOVERING_VALIDATION_BUTTON_STYLE = HOVERING_BUTTON_STYLE
const INITIAL_VALIDATION_BUTTON_STYLE = {
  backgroundColor: 'rgb(126,183,62)',
  fontWeight: 'bold',
  color: 'white'
}

const INITIAL_VALIDATE_BUTTON_TEXT = 'Validate'
const HOVERING_VALIDATE_BUTTON_TEXT = 'Warning'
const INITIAL_VALIDATE_BUTTON_ICON = faCheck
const HOVERING_VALIDATE_BUTTON_ICON = faExclamationTriangle
const HOVERING_VALIDATE_BUTTON_STYLE = HOVERING_BUTTON_STYLE
const INITIAL_VALIDATE_BUTTON_STYLE = {
  backgroundColor: '#3c8dbc',
  fontWeight: 'bold',
  color: 'white'
}

const renderButtonProps = (text, style, icon) => {
  return {
    text,
    icon,
    style: {
      ...style,
      justifyContent: 'center',
      width: '150px'
    }
  }
}

// use React.lazy to load the student validation component and to have code splitting
const StudentEvaluationByInstitution = React.lazy(() => import('../../StudentEvaluationByInstitution'))

const InternshipValidation = props => {
  const { internship, onClose, t, getUser, onValidate, onInvalidate, addTaggedEntity, getSchools } = props
  const { eventCodes } = useContext(GlobalContext)

  const [loading, setLoading] = useState(true)
  const [validating, setValidating] = useState(false)
  const [validationEnabledOn, setValidationEnabledOn] = useState(null)
  const [printing, setPrinting] = useState(false)
  const [shifts, setShifts] = useState([])
  const [scheduleComplements, setScheduleComplements] = useState([])
  const [totalTime, setTotalTime] = useState(0)
  const [totalPause, setTotalPause] = useState(0)
  const [tableMaxHeight, setTableMaxHeight] = useState(null)
  const [currentInternship, setCurrentInternship] = useState({})
  const [internshipDisplayState, setInternshipDisplayState] = useState(null)
  const [actsTableData, setActsTableData] = useState(null)
  const [feedbackEnabled, setFeedbackEnabled] = useState(false)
  const [studentName, setStudentName] = useState('')
  const [displayedTabKey, setDisplayedTabKey] = useState('1')
  const [hasGenericSector, setHasGenericSector] = useState(false)
  const [validationButton, setValidationButton] = useState(renderButtonProps(
    INITIAL_VALIDATION_BUTTON_TEXT,
    INITIAL_VALIDATION_BUTTON_STYLE,
    INITIAL_VALIDATION_BUTTON_ICON
  ))
  const [validateButton, setValidateButton] = useState(renderButtonProps(
    INITIAL_VALIDATE_BUTTON_TEXT,
    INITIAL_VALIDATE_BUTTON_STYLE,
    INITIAL_VALIDATE_BUTTON_ICON
  ))
  const [isInvalidationModalOpen, setIsInvalidationModalOpen] = useState(false)
  const [validationViewBottom, setValidationViewBottom] = useState(true)
  const [invalidTotalCategories, setInvalidTotalCategories] = useState([])
  const [totalHoursActs, setTotalHoursActs] = useState({})
  const [recruitementModule, setRecrutementModule] = useState(false)
  const [actHoursVerificationEnabled, setActHoursVerificationEnabled] = useState(false)
  const [hasActs, setHasActs] = useState(false)
  const [validationDocumentDetails, setValidationDocumentDetails] = useState(null)
  const [showDownloadButton, setShowDownloadButton] = useState(internship.studentValidationDocumentId !== null)

  const bodyRef = useRef(null)

  let longPollingIntervalExport = null

  useEffect(() => {
    if (typeof props.validationViewBottom !== 'undefined') {
      setValidationViewBottom(props.validationViewBottom)
    }

    request(`/internship/${internship.id}`, GET, null, getUser).then(({ data }) => {
      // get the section details
      if (data.internship) {
        const { internship, acts } = data
        setStudentName(internship.student.firstname + ' ' + internship.student.lastname)

        if (internship.sector.genericSector) {
          setHasGenericSector(true)
        }

        if (getUser.context !== INSTITUTION_CONTEXT) {
          getInternshipInternshipYearActTypes(internship, getUser, { contexts: ['act-type-category'] }).then(json => {
            if (json?.data) {
              const actTypes = json.data.map(item => {
                return {
                  id: item.id,
                  name: item.name,
                  hoursFormat: item.hoursFormat,
                  category: item.actTypeCategory
                    ? item.actTypeCategory.name
                    : null
                }
              })

              if (actTypes.length > 0) {
                parseActs(acts, actTypes)
              } else {
                setActsTableData(null)
              }
            }
          })
        }

        parseShifts(internship)
      }
    })
  }, [])

  useEffect(() => {
    if (internship?.studentValidationDocumentId && getUser) {
      getInternshipDocuments(getUser, internship).then(json => {
        if (json?.data) {
          setValidationDocumentDetails(json.data.find(d => d.id === internship.studentValidationDocumentId))
          setShowDownloadButton(true)
        }
      })
    } else {
      setShowDownloadButton(false)
    }
  }, [internship?.studentValidationDocumentId, getUser])

  useEffect(() => {
    if (internship) {
      const institutionId = internship.institution.id ?? internship.institution

      setCurrentInternship({ ...internship, institution: institutionId })

      if ([SCHOOL_CONTEXT, STUDENT_CONTEXT].includes(props.getUser.context)) {
        getTotalHoursActsByInternship(internship, getUser).then(data => {
          const newInvalidTotalCategories = []
          const totalHoursActs = {}

          for (const actCategoryIndex in data.totalHoursByActs) {
            totalHoursActs[data.totalHoursByActs[actCategoryIndex].categoryName] = data.totalHoursByActs[actCategoryIndex].totalHours

            if (data.totalHoursByActs[actCategoryIndex].totalHours !== data.totalHours) {
              newInvalidTotalCategories.push(data.totalHoursByActs[actCategoryIndex].categoryName)
            }
          }

          setInvalidTotalCategories(newInvalidTotalCategories)
          setTotalHoursActs(totalHoursActs)
        })
      }
    }
  }, [internship, props.getUser.context])

  useEffect(() => {
    if (getUser !== undefined && getSchools !== undefined && getUser.context === SCHOOL_CONTEXT) {
      fetchSchoolOptions(getSchools[0], getUser).then(json => {
        let permissionEnabled = false

        if (json?.data) {
          for (const option of json.data) {
            if (option.optionType.type === ACT_HOURS_VERIFICATION) {
              permissionEnabled = option.optionEnabled
              break
            }
          }
        }

        setActHoursVerificationEnabled(permissionEnabled)
      })
    }
  }, [getUser, getSchools])

  useEffect(() => {
    getScheduleComplements(internship, props.getUser).then(json => {
      if (json && json.data) {
        setScheduleComplements(json.data.map(d => {
          if (d.value.includes(':')) {
            const valueParts = d.value.split(':')
            const hours = valueParts[0][0] === '0' ? valueParts[0][1] : valueParts[0]

            d.value = hours + ':' + valueParts[1]
          }

          return d
        }))
      }
    })
  }, [internship.id, props.getUser.id])

  useEffect(() => {
    setTimeout(() => {
      computeTableHeight()
    }, 0)
  }, [actsTableData, feedbackEnabled, hasGenericSector])

  useEffect(() => {
    // set the state name
    for (const stateName in internshipStates) {
      if (internshipStates[stateName] === internship.state) {
        const loweredCaseState = stateName.toLowerCase()
        setInternshipDisplayState(`${loweredCaseState.charAt(0).toUpperCase()}${loweredCaseState.slice(1)}`)
        break
      }
    }
  }, [internship.state])

  useEffect(() => {
    if (
      getUser.context === INSTITUTION_CONTEXT &&
      recruitmentModuleEnabled(getUser.institutions[0], props.getInstitutionOptionTypes.optionTypes)
    ) {
      setRecrutementModule(true)
    } else {
      setRecrutementModule(false)
    }
  }, [getUser, props.getInstitutionOptionTypes])

  useEffect(() => {
    if (recruitementModule && internship.state === internshipStates.SCHEDULE_VALIDATED) {
      setFeedbackEnabled(true)
    } else {
      setFeedbackEnabled(false)
    }
  }, [recruitementModule, internship])

  const handleInternshipInvalidation = () => {
    requestWithPromise(`/api/internships/${internship.id}/invalidate`, 'PATCH', {}, getUser)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          onInvalidate(jsonResponse.data[0].state)

          notification.success({ message: t('De-validated'), placement: 'bottomRight' })

          request('/internship-message/send', 'POST', { internship: internship.id, content: getInvalidationMessageContent(getUser) }, getUser)
        }
      })
    setIsInvalidationModalOpen(false)
  }

  const handleInternshipInvalidationCancelation = () => {
    setIsInvalidationModalOpen(false)
  }

  const getInvalidationMessageContent = user => {
    return t('The internship has been de-validated by') + ` ${user.username}`
  }

  const parseShifts = (internship) => {
    let totalPause = 0
    let totalTime = 0
    const shifts = []
    const internshipStartMoment = moment(internship.startDate).startOf('day')
    const internshipEndMoment = moment(internship.endDate).endOf('day')

    getInternshipShifts(internship, props.getUser).then(json => {
      const rawShifts = json?.data ?? []
      const lastShiftDate = rawShifts.length > 0
        ? getTimezoneLessMoment(rawShifts[rawShifts.length - 1].endDate)
        : moment(internship.endDate)

      rawShifts.forEach(shift => {
        const shiftStartMoment = getTimezoneLessMoment(shift.startDate)
        const shiftEndMoment = getTimezoneLessMoment(shift.endDate)

        if (shift.id > -1) {
          const parsedShift = {
            id: shift.id,
            date: shiftStartMoment.format('DD/MM'),
            outsideOfInternshipDates: shiftStartMoment.clone().startOf('day') < internshipStartMoment || shiftEndMoment.clone().endOf('day') > internshipEndMoment,
            isDesiderata: shift.isDesiderata
          }

          if (shift.eventCodeType && shift.eventCodeType.category === ABSENCE_CATEGORY) {
            parsedShift.absentLabel = props.t('event.code.label.' + shift.eventCodeType.type)
          } else {
            parsedShift.startTime = shiftStartMoment.format('HH:mm')
            parsedShift.endTime = shiftEndMoment.format('HH:mm')
            parsedShift.pause = shift.pause > 0 ? shift.pause : 0
            totalPause += parsedShift.pause
            // compute the time difference in hours

            if (shiftStartMoment > shiftEndMoment) {
              /**
               * because the backend returns the same date even if the actual end date is
               * passed midnight, we add one day to the end time so we can have a correct computation
               * of the duration
               */
              parsedShift.duration = shiftEndMoment.add(1, 'day').diff(shiftStartMoment, 'minutes')
            } else {
              parsedShift.duration = shiftEndMoment.diff(shiftStartMoment, 'minutes')
            }

            // subtract the duration of the pause the pause
            parsedShift.duration -= parsedShift.pause
            if (parsedShift.duration < 0) {
              parsedShift.duration = 0
            }
            // increment total hours
            totalTime += parsedShift.duration

            // format the row total time
            parsedShift.duration = formatTimeFromMinutes(parsedShift.duration)

            // format the pause
            parsedShift.pause = formatTimeFromMinutes(parsedShift.pause)
            parsedShift.exactLocation = shift.exactLocation
          }

          shifts.push(parsedShift)
        }
      })

      setShifts(shifts)

      if (lastShiftDate && lastShiftDate.startOf('day') > moment().startOf('day')) {
        setValidationEnabledOn(lastShiftDate.format('DD/MM/YYYY'))
      }

      // now format the total time & total pause
      const totalTimeHours = parseInt(totalTime / 60)
      let totalTimeMinutes = parseInt(totalTime % 60)
      if (totalTimeMinutes < 10) {
        totalTimeMinutes = `0${totalTimeMinutes}`
      }

      const totalPauseHours = parseInt(totalPause / 60)
      let totalPauseMinutes = parseInt(totalPause % 60)
      if (totalPauseMinutes < 10) {
        totalPauseMinutes = `0${totalPauseMinutes}`
      }

      setTotalTime(`${totalTimeHours}:${totalTimeMinutes}`)
      setTotalPause(`${totalPauseHours}:${totalPauseMinutes}`)
      setLoading(false)
      computeTableHeight()
    })
  }

  const parseActs = (acts, actTypes) => {
    // first group them by date
    const actsTotals = []

    acts.forEach(item => {
      if (item.shift.eventCodeType?.category !== ABSENCE_CATEGORY && item.startDate) {
        let actTypeName = null
        let actTypeCategory = null
        let actValue = null

        const actType = actTypes.find(actItem => actItem.id === item.actType.id)

        if (actType) {
          actTypeName = actType.name
          actTypeCategory = actType.actTypeCategory ? actType.actTypeCategory.name : null
        }
        try {
          actValue = actType && actType.hoursFormat ? hoursToMinutes(item.description) : parseInt(item.description)
        } catch (err) {
          actValue = null
        }
        if (actTypeName && actValue !== null) {
          // we have all the mandatory data so we can move forward
          // increase the totals
          const existingActTotals = actsTotals.find(actTotalItem => actTotalItem.id === item.actType.id)
          if (existingActTotals) {
            existingActTotals.value += actValue
          } else {
            actsTotals.push({ id: item.actType.id, value: actValue, name: actTypeName, category: actTypeCategory })
          }
        }
      }
    })

    // set the final table data
    let totals = actTypes.map(item => {
      const totalValue = actsTotals.find(totalItem => totalItem.id === item.id)
      let value = 0

      if (totalValue) {
        value = item.hoursFormat ? formatTimeFromMinutes(totalValue.value, true) : totalValue.value
      }

      return { id: item.id, value, name: item.name, category: item.category }
    })

    // order & group
    totals = sortBy(totals, ['category', 'name'])
    totals = groupBy(totals, 'category')

    setActsTableData(Object.entries(totals))
    setHasActs(acts.length > 0)
  }

  const computeTableHeight = () => {
    if (bodyRef.current) {
      let percentageHeight = feedbackEnabled ? 55 : 56
      if (actsTableData) {
        percentageHeight = feedbackEnabled ? 20 : 30
      }
      setTableMaxHeight(bodyRef.current.clientHeight * percentageHeight / 100)
    }
  }

  const handleValidation = () => {
    setValidating(true)
    validateInternship(getUser, internship).then(({ data, firstInternshipTag }) => {
      if (!data.status || data.status !== 'error') {
        onValidate()
      }
      setValidating(false)

      if (recruitementModule) {
        setTimeout(() => {
          setDisplayedTabKey('2')
        }, 10)
      }

      if (firstInternshipTag && firstInternshipTag.tagId) {
        addTaggedEntity(firstInternshipTag, 'internship')
      }

      notification.success({ message: t('Validated'), placement: 'bottomRight' })
    }).catch(error => {
      if (error.status === HTTP_BAD_REQUEST) {
        onError(t('This internship cannot be validated because some data are missing'))
      } else {
        onError(t('error_handler.unknow_error'))
      }

      setValidating(false)
    })
  }

  const handleDocumentPrint = () => {
    setPrinting(true)
    if (validationDocumentDetails) {
      // student already uploaded a document, we have to download it
      try {
        downloadFile(`/internship/document/download/${internship.studentValidationDocumentId}`, validationDocumentDetails.originalName, getUser)
        setPrinting(false)
      } catch (err) {
        setPrinting(false)
        generalErrorHandler(err)
      }
    } else {
      const body = {
        internship: internship.id,
        timezone: moment.tz.guess(),
        eventCodesTypesLabels: {}
      }

      eventCodes.forEach(e => { body.eventCodesTypesLabels[e.type] = props.t('event.code.label.' + e.type) })

      request(
        '/internship/export-validated-internship',
        'POST',
        body,
        getUser
      ).then(({ job, status }) => {
        if (status === 'success') {
          longPollingIntervalExport = setInterval(() => {
            request('/job-result/' + job, 'GET', null, getUser, {
              catchError: false
            }).then(json => {
              if (
                (typeof json.error === 'undefined' || json.error === false) &&
                typeof json.data !== 'undefined'
              ) {
                clearInterval(longPollingIntervalExport)
                setPrinting(false)
                const fileName = json.data.result
                try {
                  downloadFile(
                    '/validation-docs/download/' + fileName,
                    fileName,
                    getUser,
                    'GET'
                  )
                } catch (err) {
                  generalErrorHandler(err)
                  clearInterval(longPollingIntervalExport)
                  setPrinting(false)
                }
              }
            })
          }, 1500)
        }
      })
    }
  }

  const handleTabChange = tabKey => {
    setDisplayedTabKey(tabKey)
  }

  const getInternshipDate = date => {
    return date.date ?? date
  }

  const renderValidationScreen = () => {
    const showShiftsTable = !loading && shifts.length > 0
    let totalTimeValue = totalTime
    let globalComment = ''
    let globalBonus = 0

    scheduleComplements.forEach(sc => {
      if (sc.actType === null) {
        globalBonus += timeToSeconds(sc.value, HOURS_SPLIT_CHARACTER, sc.positive)

        const comment = sc.commentary.trim()

        if (comment !== '') {
          globalComment += globalComment === '' ? comment : (' - ' + comment)
        }
      }
    })

    if (globalBonus && typeof totalTimeValue === 'string') {
      totalTimeValue = secondsToTime(timeToSeconds(totalTimeValue) + globalBonus)
    }

    return (
      <div className={`internship-validation-summary ${actsTableData ? 'has-two-tables' : ''}`} ref={bodyRef}>
        <div className='internship-details'>
          <div>{t('Internship details')}</div>
          <div>
            <span>{t('Start date')}: <b>{getTimezoneLessMoment(getInternshipDate(internship.startDate)).format('DD/MM/YYYY')}</b></span>, {' '}
            <span>{t('End date')}: <b>{getTimezoneLessMoment(getInternshipDate(internship.endDate)).format('DD/MM/YYYY')}</b></span>
            {internshipDisplayState && <span>, {t('Status')}: <b>{(t(internshipDisplayState))}</b></span>}
          </div>
        </div>
        <h3>{t('Shifts summary table')}</h3>
        <div className='table-wrapper'>
          {showShiftsTable &&
            <table className={hasGenericSector ? 'has-exact-location' : ''}>
              <thead>
                <tr>
                  <th className='col-date'>{t('Date')}</th>
                  {hasGenericSector && <th className='col-exact-location'>{t('Location')}</th>}
                  <th className='col-start'>{t('Start time')}</th>
                  <th className='col-end'>{t('End time')}</th>
                  <th className='col-pause'>{t('Pause')}</th>
                  <th className='col-total'>{t('Total (hours)')}</th>
                </tr>
              </thead>
            </table>}
          {loading && <div className='loading-ring' />}
          {!loading && shifts.length === 0 && <div className='no-shifts-warning'>{t('No shifts for this internship, it can\'t be validated')}</div>}
          <div className={`shifts-table ${!showShiftsTable ? 'no-border' : ''}`} style={{ maxHeight: tableMaxHeight || 'unset' }}>
            {showShiftsTable &&
              <table className={hasGenericSector ? 'has-exact-location' : ''}>
                {shifts.length && (
                  <tbody>
                    {shifts.map(shift => {
                      if (shift.absentLabel) {
                        return (
                          <tr key={shift.id}>
                            <td className='col-date'>
                              {shift.date}
                              {shift.outsideOfInternshipDates && <ValidationWarning t={t} />}
                              {shift.isDesiderata && <ValidationWarning t={t} desiderataWarning />}
                            </td>
                            {hasGenericSector &&
                              <td className='col-exact-location'>
                                {shift.exactLocation && shift.exactLocation.length ? shift.exactLocation : t('None')}
                              </td>}
                            <td className='absent' colSpan='4'>{shift.absentLabel}</td>
                          </tr>)
                      }

                      return (
                        <tr key={shift.id}>
                          <td className='col-date'>
                            {shift.date}
                            {shift.outsideOfInternshipDates && <ValidationWarning t={t} />}
                            {shift.isDesiderata && <ValidationWarning t={t} desiderataWarning />}
                          </td>
                          {hasGenericSector &&
                            <td className='col-exact-location'>
                              {shift.exactLocation && shift.exactLocation.length ? shift.exactLocation : t('None')}
                            </td>}
                          <td className='col-start'>{shift.startTime}</td>
                          <td className='col-end'>{shift.endTime}</td>
                          <td className='col-pause'>{shift.pause}</td>
                          <td className='col-total'>{shift.duration}</td>
                        </tr>
                      )
                    })}
                    {globalBonus !== 0 && (
                      <tr>
                        <td style={{ fontWeight: 'bold' }}> {t('Hour bonus')} </td>
                        <td colSpan='3' style={{ color: 'red' }}> {globalComment} </td>
                        <td className='col-total'> {convertTime(secondsToTime(globalBonus), HOURS_SPLIT_CHARACTER, HOURS_SPLIT_CHARACTER)} </td>
                      </tr>
                    )}
                  </tbody>)}
              </table>}
          </div>
          {showShiftsTable &&
            <table className={`total-summary ${hasGenericSector ? 'has-exact-location' : ''}`}>
              <thead>
                <tr>
                  <td className='col-total-sum'>{t('Total (hours)')}</td>
                  <td className='col-total-pause'>{totalPause}</td>
                  <td className='col-total'>{totalTimeValue}</td>
                </tr>
              </thead>
            </table>}
        </div>
        {!loading && (hasActs || Object.keys(totalHoursActs).length > 0) && (
          <ActsTable
            actHoursVerificationEnabled={actHoursVerificationEnabled}
            data={actsTableData ?? []}
            invalidTotalCategories={invalidTotalCategories}
            scheduleComplements={scheduleComplements.filter(s => s.actType !== null)}
            tableMaxHeight={tableMaxHeight}
            totalHoursActs={totalHoursActs}
          />
        )}
        {validationViewBottom && renderValidationViewBottom(showShiftsTable)}
      </div>
    )
  }

  const renderValidationViewBottom = enable => {
    if (!enable) {
      return (<div />)
    }

    return (
      <div className='buttons-wrapper'>
        {renderValidationsButtons()}
        <Button
          disabled={validating || (showDownloadButton && !validationDocumentDetails)}
          onClick={handleDocumentPrint}
          loading={printing}
        >
          <FontAwesomeIcon icon='file-alt' />&nbsp;
          {t(showDownloadButton ? 'Download signed validation document' : 'Print Validation document')}
        </Button>
      </div>
    )
  }

  const renderValidationsButtons = () => {
    if (internship.state === internshipStates.SCHEDULE_VALIDATED) {
      return (
        <Button
          style={validationButton.style}
          disabled={false}
          loading={validating}
          onClick={() => setIsInvalidationModalOpen(true)}
          onMouseEnter={() => setValidationButton(renderButtonProps(
            HOVERING_VALIDATION_BUTTON_TEXT,
            HOVERING_VALIDATION_BUTTON_STYLE,
            HOVERING_VALIDATION_BUTTON_ICON
          ))}
          onMouseLeave={() => setValidationButton(renderButtonProps(
            INITIAL_VALIDATION_BUTTON_TEXT,
            INITIAL_VALIDATION_BUTTON_STYLE,
            INITIAL_VALIDATION_BUTTON_ICON
          ))}
        >
          <FontAwesomeIcon icon={validationButton.icon} />&nbsp;
          {t(validationButton.text)}
        </Button>
      )
    }

    if (validationEnabledOn) {
      return (
        <div className='validated-enabled-on'>
          {`${t('Validation will be enabled on')}: ${validationEnabledOn}`}
        </div>
      )
    }

    if (invalidTotalCategories.length > 0 && actHoursVerificationEnabled) {
      return (
        <Popconfirm
          cancelText={t('Cancel')}
          okText={t('Yes')}
          onConfirm={handleValidation}
          placement='top'
          title={t('Do you confirm internship validation ?')}
        >
          <Tooltip placement='bottom' title={t('The total number of hours worked during the internship is not equivalent to the total number of hours worked in one or more act categories.')}>
            <Button
              style={validateButton.style}
              disabled={false}
              loading={validating}
              onMouseEnter={() => setValidateButton(renderButtonProps(
                HOVERING_VALIDATE_BUTTON_TEXT,
                HOVERING_VALIDATE_BUTTON_STYLE,
                HOVERING_VALIDATE_BUTTON_ICON
              ))}
              onMouseLeave={() => setValidateButton(renderButtonProps(
                INITIAL_VALIDATE_BUTTON_TEXT,
                INITIAL_VALIDATE_BUTTON_STYLE,
                INITIAL_VALIDATE_BUTTON_ICON
              ))}
            >
              <FontAwesomeIcon icon={validateButton.icon} />&nbsp;
              {t(validateButton.text)}
              {validating && <div className='loading-icon black' />}
            </Button>
          </Tooltip>
        </Popconfirm>
      )
    }

    return (
      <Popconfirm
        cancelText={t('Cancel')}
        okText={t('Yes')}
        onConfirm={handleValidation}
        placement='top'
        title={t('Do you confirm internship validation ?')}
      >
        <Button
          disabled={false}
          type='primary'
          loading={validating}
        >
          <FontAwesomeIcon icon='check' />&nbsp;
          {t('Validate')}
        </Button>
      </Popconfirm>
    )
  }

  const titleDrawer = () => {
    if (typeof props.titleDrawer !== 'undefined') {
      return props.titleDrawer
    }

    return t('Internship validation for') + ' ' + studentName
  }

  return (
    <Drawer
      title={titleDrawer()}
      width={hasGenericSector ? '950px' : '550px'}
      onClose={onClose}
      visible
      className='internship-validation-drawer'
    >
      {feedbackEnabled && !loading ? (
        <Tabs tabPosition='bottom' defaultActiveKey='1' activeKey={displayedTabKey} onChange={handleTabChange}>
          <TabPane tab={t('Validation')} key='1'>{renderValidationScreen()}</TabPane>
          <TabPane tab={t('Feedback')} key='2'>
            <Suspense fallback={<div>Loading...</div>}>
              <StudentEvaluationByInstitution internship={currentInternship} />
            </Suspense>
          </TabPane>
        </Tabs>
      ) : renderValidationScreen()}

      <InvalidateInternshipModal
        visible={isInvalidationModalOpen}
        onOk={handleInternshipInvalidation}
        onCancel={handleInternshipInvalidationCancelation}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InternshipValidation)
