import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { caseInsensitiveInclude } from '../../../utils'
import { Checkbox, Input, Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { isArray } from 'lodash'

import '../../../assets/search-checkbox.scss'

const { Search } = Input
const CheckboxGroup = Checkbox.Group

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const SearchCheckbox = ({ checkAllOption, initCheckAll, initChecked = [], items, labelKey, style = {}, tooltipKey, title, valueKey, t, onCheck, onCheckAll }) => {
  const [filter, setFilter] = useState('')
  const [checked, setChecked] = useState(initChecked)

  useEffect(() => {
    if (initCheckAll && isArray(items) && items.length > 0 && valueKey) {
      setChecked(items.map(i => i[valueKey]))
      onCheckAll(items)
    }
  }, [initCheckAll, items, valueKey])

  const displayedItems = useMemo(() => items.filter(i => {
    return filter === '' || caseInsensitiveInclude(typeof i[labelKey ?? valueKey] === 'string' ? i[labelKey ?? valueKey] : 'Invalid label', filter)
  }), [items, filter, labelKey, valueKey])

  const allChecked = useMemo(() => displayedItems.length === checked.length, [displayedItems, checked])

  const renderOptions = () => {
    const options = []

    if (valueKey) {
      displayedItems.forEach((i, index) => {
        const label = typeof i[labelKey ?? valueKey] === 'string' ? i[labelKey ?? valueKey] : 'Invalid label'

        if (filter === '' || caseInsensitiveInclude(label, filter)) {
          let option = (
            <Checkbox
              key={index}
              data={i}
              value={i[valueKey]}
              onChange={handleChange}
            >
              {t(label)}
            </Checkbox>
          )

          if (tooltipKey && typeof i[tooltipKey] === 'string') {
            option = (
              <Tooltip key={`t${index}`} placement='right' title={t(i[tooltipKey])}>
                {option}
              </Tooltip>
            )
          }

          options.push(option)
        }
      })
    }

    return options
  }

  const handleChange = ({ target }) => {
    const newChecked = target.checked
      ? [...checked, target.value]
      : checked.filter(e => e !== target.value)

    setChecked(newChecked)
    onCheck(target.data, target.checked)
  }

  const onAllCheck = useCallback(() => {
    if (allChecked) {
      setChecked([])
      onCheckAll([])
    } else {
      setChecked(displayedItems.map(i => i[valueKey]))
      onCheckAll(displayedItems)
    }
  }, [allChecked, onCheckAll, displayedItems])

  return (
    <div className='search-checkbox' style={style}>
      {title && <h4><b>{t(title)}</b></h4>}
      <Search
        placeholder={t('search_checkbox.search')}
        value={filter}
        onChange={e => setFilter(e.target.value)}
      />
      <div
        className={'search-checkbox-borders' + (title ? '' : '-without-title')}
        style={{
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
          borderTop: '0'
        }}
      >
        <div className='search-checkbox-box'>
          {checkAllOption && valueKey && (
            <Checkbox checked={allChecked} onChange={onAllCheck}>
              <b>
                {t('Select all')}
              </b>
            </Checkbox>
          )}
          <CheckboxGroup value={checked}>
            {renderOptions()}
          </CheckboxGroup>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(SearchCheckbox)
