import React, { useContext, useEffect, useState } from 'react'

import { Form, Popconfirm, Button, Modal, InputNumber, TimePicker, Divider } from 'antd'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../reducers/Dispatchers'
import moment from 'moment'
import EventCodeSelect from './Components/EventCodeSelect'
import ActsButton from './Components/Acts/ActsButton'
import { FULL_DATE_FORMAT, HOURS_MINUTES_FORMAT } from '../../../utils/momentjs'
import { createShiftAct, getShiftActs } from '../../../utils/api/shift'
import ActsTable from './ActsTable'
import { onError, onSuccess } from '../../../utils/apiHelper'
import { deleteAct } from '../../../utils/api/act'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const DesiderataUpdateModal = ({
  actTypes, date, getUser, internship, internshipState, isUpdatable, shift, visible, onCancel, onSubmit, t
}) => {
  const [values, setValues] = useState({})
  const [acts, setActs] = useState([])
  const [loading, setLoading] = useState(true)

  const { eventCodes } = useContext(GlobalContext)

  useEffect(() => {
    if (shift) {
      setValues({
        id: shift.id,
        startTime: moment(shift.start, FULL_DATE_FORMAT),
        endTime: moment(shift.end, FULL_DATE_FORMAT),
        pause: shift.pause,
        eventCodeType: shift.eventCodeType,
        eventCode: shift.eventCode,
        deleted: shift.deleted
      })
      setLoading(true)

      getShiftActs(shift, getUser).then(json => {
        if (json && json.data) {
          setActs(json.data)
        }

        setLoading(false)
      })
    }
  }, [shift, getUser])

  const addActs = (createdActs) => {
    setLoading(true)
    const promises = []

    createdActs.forEach(a => {
      const value = a.hoursFormat ? a.values.hours + 'h' + a.values.minutes : a.values.unit
      const body = {
        shift: shift.id,
        actType: a.actType,
        description: value,
        startDate: shift.start,
        endDate: shift.end
      }

      promises.push(createShiftAct(shift, body, getUser))
    })

    Promise.all(promises).then(rawActs => {
      const newActs = [...acts]
      let count = 0

      rawActs.forEach(a => {
        if (a && a.data) {
          newActs.push(a.data)
          count++
        }
      })

      if (count === createdActs.length) {
        onSuccess(count === 1
          ? t('The act has been successfully created')
          : t('The acts have been successfully created')
        )
      } else {
        onError('One or more acts could not be created')
      }

      setActs(newActs)
      setLoading(false)
    })
  }

  const handleDeleteAct = act => {
    deleteAct(act, getUser).then(json => {
      if (json && json.data) {
        setActs(acts.filter(a => a.id !== act.id))
        onSuccess(t('The act has been successfully deleted'))
      } else {
        onError(t('The act could not be deleted'))
      }
    })
  }

  const handleDelete = () => {
    setValues({ ...values, deleted: true })
    handleClose(true, { ...values, deleted: true }, true)
  }

  const handleClose = (isSubmit, newValues = null, isDelete = false) => {
    if (isSubmit) {
      newValues ? onSubmit(newValues, isDelete) : onSubmit(values, isDelete)
    } else {
      onCancel()
    }
  }

  const title = isUpdatable
    ? t('Modify the schedule for ') + date
    : t('Schedule for ') + date
  const okButtonProps = isUpdatable ? { htmlType: 'submit', form: 'shiftUpdateForm' } : null
  const footer = isUpdatable
    ? (
      <div>
        <Button key='back' onClick={() => handleClose(false)}>
          {t('Cancel')}
        </Button>
        <Popconfirm
          title={t('Are you sure you want to delete this shift?')}
          onConfirm={handleDelete}
          onCancel={() => handleClose(false)}
        >
          <Button type='danger'>
            {t('Delete shift')}
          </Button>
        </Popconfirm>
        <Button
          key='submit'
          type='primary'
          onClick={() => handleClose(true)}
        >
          {t('Submit')}
        </Button>
      </div>
    )
    : null

  const renderUpdateContent = () => {
    return (
      <Form
        id='shiftUpdateForm'
        layout='vertical'
        initialvalues={values}
      >
        <Form.Item name='startTime' label={t('Start time')}>
          <TimePicker
            size='large'
            minuteStep={5}
            defaultOpenValue={values.startTime}
            placeholder={moment(values.startTime).format(HOURS_MINUTES_FORMAT)}
            format='HH:mm'
            onChange={(value) => {
              const startDate = values.startTime.clone()
              startDate.hours(value.format('HH'))
              startDate.minutes(value.format('mm'))
              startDate.seconds(0)

              setValues({ ...values, startTime: moment(startDate.format(FULL_DATE_FORMAT)) })
            }}
          />
        </Form.Item>
        <Form.Item name='endTime' label={t('End time')}>
          <TimePicker
            size='large'
            minuteStep={5}
            defaultOpenValue={values.endTime}
            placeholder={moment(values.endTime).format(HOURS_MINUTES_FORMAT)}
            format='HH:mm'
            onChange={(value) => {
              const endDate = values.endTime.clone()
              endDate.hours(value.format('HH'))
              endDate.minutes(value.format('mm'))
              endDate.seconds(0)
              if (endDate < values.startTime) {
                endDate.add(1, 'day')
              }

              setValues({ ...values, endTime: moment(endDate.format(FULL_DATE_FORMAT)) })
            }}
          />
        </Form.Item>
        <Form.Item name='pause' label={t('Pause')}>
          <InputNumber
            size='large'
            min={0}
            step={5}
            defaultValue={values.pause}
            placeholder={values.pause}
            onChange={(value) => {
              setValues({ ...values, pause: value })
            }}
          />
        </Form.Item>
        <Form.Item name='eventCode' label={t('Event code')} style={{ marginBottom: 0 }}>
          <EventCodeSelect
            update
            value={values.eventCodeType ? values.eventCodeType.type : null}
            handleEventCodeSelection={(value, option) => {
              setValues({ ...values, eventCodeType: eventCodes.find(e => e.type === option.props.data.type ?? value), eventCode: { code: t('event.code.' + option.props.data.type ?? value) } })
            }}
          />
        </Form.Item>
      </Form>
    )
  }

  const renderShowContent = () => {
    return (
      <div>
        <h3>{t('Start time')}</h3>
        <p>{moment(values.startTime).format(HOURS_MINUTES_FORMAT)}</p>
        <h3>{t('End time')}</h3>
        <p>{moment(values.endTime).format(HOURS_MINUTES_FORMAT)}</p>
        <h3>{t('Pause')}</h3>
        <p>{values.pause} min</p>
        <h3>{t('Event code')}</h3>
        <p>{values.eventCode ? values.eventCode.code : '/'}</p>
      </div>
    )
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={() => handleClose(false)}
      okButtonProps={okButtonProps}
      footer={footer}
      afterClose={() => {
        setValues({})
        setActs([])
      }}
    >
      {shift && isUpdatable ? renderUpdateContent() : renderShowContent()}
      {internship.school && internship.school.managed && (
        <>
          <ActsButton shift={shift} actTypes={actTypes} acts={acts} onSubmit={addActs} internshipState={internshipState} />
          <Divider />
          <ActsTable acts={acts} onDelete={handleDeleteAct} loading={loading} />
        </>
      )}
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DesiderataUpdateModal)
