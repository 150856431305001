import React, {useEffect, useMemo, useState} from 'react'
import Loading from '../../../HOC/Loading'
import Analytics from '../../../utils/analytics'
import { getInstitutions } from '../../../reducers/InstitutionsReducer'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { isArray, isObject } from 'lodash'
import { getSectorPresets } from '../../../utils/api/sector'
import { getInstitutionShiftPresets } from '../../../utils/api/institution'
import SectorShiftPresetsManager from './SectorShiftPresetsManager'

import '../../../assets/shifts-presets-manager.scss'
import {BLOCK_SCHEDULE_CODE_EDITION} from "../../../utils/constants";
import {getInstitutionOptions} from "../../../reducers/InstitutionOptionsReducer/actions";

const mapStateToProps = state => {
  return {
    institutions: getInstitutions(state),
    modules: getInstitutionOptions(state.institutionOptions),
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ShiftPresetsManager = ({ institutions, modules, sector, user, t }) => {
  const [sectors, setSectors] = useState([])
  const [shiftPresets, setShiftPresets] = useState({})
  const [loading, setLoading] = useState(true)

  const disabled = useMemo(() => {
    const useBlockPresetEdition = modules.find(m => (m.optionType.type === BLOCK_SCHEDULE_CODE_EDITION && m.optionEnabled))

    return !!(user.roles.includes('ROLE_NURSE') && useBlockPresetEdition);
  }, [modules, user])

  useEffect(() => {
    Analytics.pageView('/shifts-presets-manager')
  }, [])

  useEffect(() => {
    if (isObject(sector)) {
      setSectors([sector])
      setLoading(true)

      getSectorPresets(user, sector).then(json => {
        if (json?.data) {
          const presets = {}

          presets[sector.id] = json.data

          setShiftPresets(presets)
        }

        setLoading(false)
      })
    } else if (isArray(institutions) && institutions[0]) {
      setLoading(true)

      getInstitutionShiftPresets(user, institutions[0]).then(json => {
        if (json?.data) {
          setShiftPresets(json.data)
          setSectors(institutions[0].sectors)
        }

        setLoading(false)
      })
    }
  }, [sector?.id, institutions, user.id])

  const title = disabled ?
    (
      <div >
        <h3>{t('Manage presets')}</h3>
        <p style={{color: "grey"}}>{t('unauthorized.edition.code.message')}</p>
      </div>
    ): <h4>{t('Manage presets')}</h4>

  return (
    <div>
      <Loading loading={loading} size='6x'>
        {title}
        <ul className='presets-list'>
          {sectors.map(s => (
            <li key={`shift-preset-item-${s.id}`}>
              <SectorShiftPresetsManager disabled={disabled} shiftPresets={shiftPresets} sector={s} />
            </li>
          ))}
        </ul>
      </Loading>
    </div>
  )
}

export default connect(mapStateToProps)(ShiftPresetsManager)
