import React, { useState, useEffect } from 'react'

import moment from 'moment'
import momentTimezone from 'moment-timezone'
import InformationDisplay from './InformationDisplay'
import InternshipInfo from './InternshipInfo'
import InternshipStatePellet from './InternshipStatePellet'
import LoadingSkeleton from './LoadingSkeleton'
import OptInInfo from './OptInInfo'
import FreeFields from './FreeFields'
import FilesManager from './FilesManager'
import { Drawer, Card, Button, Avatar } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { request, formatTimeFromMinutes, generalErrorHandler } from '../../utils'
import { AGGREGATION_STRING, dataAggregationFactory } from '../../utils/dataFactories'
import { INSTITUTION_CONTEXT, internshipStates } from '../../utils/constants'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import { ABSENCE_CATEGORY } from './ShiftsManager/constants'
import { exportStudentInternshipsHistory } from '../../utils/api/student'
import { getDocument } from '../../utils/api/export'
import { onError, onSuccess } from '../../utils/apiHelper'

import '../../assets/shifts-manager.scss'
import '../../assets/student-sheet.scss'
import { getFullName } from '../../utils/entities/sectionToYear'

const StudentInfo = (props) => {
  const [student, setStudent] = useState(null)
  const [shifts, setShifts] = useState([])
  const [sections, setSections] = useState(null)
  const [selectedInternshipInfo, setSelectedInternshipInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [extraInformationsLoaded, setExtraInformationsLoaded] = useState(false)
  const [exportInternshipsLoading, setExportInternshipsLoading] = useState(false)
  const [loadFiles, setLoadFiles] = useState(false)
  const [profileImage, setProfileImage] = useState(props.getStudentProfileImage ? props.getStudentProfileImage : null)

  useEffect(() => {
    savePropsToState(props)

    return () => {
      setProfileImage(null)
      props.removeStudentProfileImage()
    }
  }, [])

  useEffect(() => {
    if (props.getInstitutions[0] || props.getUser.context !== INSTITUTION_CONTEXT) {
      savePropsToState(props)
    }
  }, [props.student, props.getInstitutions[0], props.getUser.context])

  useEffect(() => {
    if (student) {
      props.fetchStudentExtraInformationsById(student.id, props.getUser)

      if (student.profileImageName) {
        props.fetchStudentProfileImage(student.profileImageName, props.getUser)
      }
    }
  }, [student])

  useEffect(() => {
    if (student && props.getStudentsExtraInformations[student.id] &&
      props.getStudentsPrivacyInformations &&
      typeof (props.getStudentsPrivacyInformations[student.id]) === 'boolean'
    ) {
      setExtraInformationsLoaded(true)
    }
  }, [props.getStudentsPrivacyInformations, props.getStudentsExtraInformations])

  useEffect(() => {
    if (props.getStudentProfileImage) {
      setProfileImage(props.getStudentProfileImage)
      setLoading(false)
    }
  }, [props.getStudentProfileImage])

  const savePropsToState = async props => {
    if (props.student === null) {
      setStudent(null)

      return
    }
    setLoading(true)

    const id = typeof props.student.id === 'undefined'
      ? props.student
      : props.student.id

    if (props.getUser.context === INSTITUTION_CONTEXT) {
      props.fetchStudentFreeField(
        props.getInstitutions[0].id,
        id,
        props.getActiveLanguage,
        props.getUser
      )
    }

    request(`/internship/student/${id}`, 'GET', null, props.getUser)
      .then(json => {
        const student = json.data.student
        const shifts = json.data.shifts
        const sections = json.data.sections
        let internships = json.data.internships

        if (props.getUser.context === INSTITUTION_CONTEXT) {
          internships = internships.filter(
            i => i.institution.id === props.getInstitutions[0].id
          )
        }
        student.internships = internships

        setLoading(false)
        setStudent(student)
        setShifts(shifts)
        setSections(sections)
      })
      .catch(error => {
        generalErrorHandler(error)
        setLoading(false)
        setStudent(props.student)
      })
  }

  /**
   * This method returns the summed duration of the shifts for the internships which are validated
   */
  const computeTotalTimeInInternship = internship => {
    if (internship.state !== internshipStates.SCHEDULE_VALIDATED || !shifts || !shifts[internship.id]) {
      return null
    }

    let totalTime = null

    shifts[internship.id].forEach(shift => {
      if (shift.id > -1) {
        let shiftMinutesDuration = 0
        const shiftStartMoment = moment(shift.startDate.date)
        const shiftEndMoment = moment(shift.endDate.date)

        if (shift.eventCodeType !== null && shift.eventCodeType.category === ABSENCE_CATEGORY) {
          return
        }

        if (shiftStartMoment > shiftEndMoment) {
          /**
           * because the backend returns the same date even if the actual end date is
           * passed midnight, we add one day to the end time so we can have a correct computation
           * of the duration
           */
          shiftMinutesDuration = shiftEndMoment.add(1, 'day').diff(shiftStartMoment, 'minutes') - shift.pause
        } else {
          shiftMinutesDuration = shiftEndMoment.diff(shiftStartMoment, 'minutes') - shift.pause
        }

        if (!totalTime) {
          totalTime = 0
        }

        totalTime += shiftMinutesDuration
      }
    })

    internship.scheduleComplements.forEach(c => {
      if (c.actType === null) {
        const timeParts = c.value.split(':')

        if (timeParts.length === 3) {
          const time = timeParts[0] * 60 + timeParts[1] * 1

          if (totalTime === null) {
            totalTime = 0
          }

          totalTime += time
        }
      }
    })

    if (totalTime !== null) {
      // format the minutes to H:MM
      totalTime = formatTimeFromMinutes(totalTime)
    }

    return totalTime
  }

  const handleExportInternships = async () => {
    setExportInternshipsLoading(true)

    const { student, getUser } = props
    const id = typeof student.id === 'undefined'
      ? student
      : student.id

    exportStudentInternshipsHistory(getUser, { id }, { language: props.getActiveLanguage, timezone: momentTimezone.tz.guess() }).then(json => {
      if (json.status === 'success') {
        getDocument(
          json.job,
          getUser,
          () => {
            onError(props.t('student_info.actions.export_history.error'))
            setExportInternshipsLoading(false)
          },
          () => {
            onSuccess(props.t('student_info.actions.export_history.success'))
            setExportInternshipsLoading(false)
          }
        )
      }
    })
  }

  const handleOnClose = () => {
    setExtraInformationsLoaded(false)
    setProfileImage(null)
    setStudent(null)
    props.onClose()
  }

  const handleInternshipValidation = updatedInternship => {
    const updatedInternshipsList = student.internships.map(internship => {
      if (internship.id === updatedInternship.id) {
        return updatedInternship
      }

      return internship
    })

    setStudent({ ...student, internships: updatedInternshipsList })
  }

  const renderProfileHeader = () => {
    return (
      <Card
        cover={
          <Meta
            avatar={<Avatar size={60} src={profileImage || student.profileImageName ? profileImage : '/assets/default_profile_img.png'} />}
            title={student.lastname + ' ' + student.firstname}
            description={displaySectionsAndSchoolYears()}
            style={{ margin: '24px 0px 0px 24px' }}
          />
        }
        actions={[
          <Button
            key='student_files_button'
            onClick={() => setLoadFiles(!loadFiles)}
          >
            {props.t('Student files')}
          </Button>
        ]}
      >
        <div>
          {renderStudentInfo()}
        </div>
      </Card>
    )
  }

  const displaySectionsAndSchoolYears = () => {
    return sections ? sections.map(section => section.name ?? getFullName(section.sectionToYear)).join(', ') : ''
  }

  const renderStudentInfo = () => {
    const studentInfo = [
      {
        id: 3,
        icon: 'id-badge',
        title: props.t('Registration number'),
        caption: student.matricule
      },
      {
        id: 4,
        icon: 'birthday-cake',
        title: props.t('Birthdate'),
        caption:
            student.birthdate === null
              ? '?'
              : moment(student.birthdate.date).format('DD/MM/YY')
      },
      {
        id: 5,
        icon: 'at',
        title: props.t('Email'),
        caption: student.email
      },
      {
        id: 6,
        icon: 'phone',
        title: props.t('Phone'),
        caption: student.phoneNumber !== null && student.phoneNumber !== ''
          ? student.phoneNumber
          : '?'
      }
    ]

    if (extraInformationsLoaded && props.getStudentsExtraInformations[student.id].phones.length > 0) {
      const title = !props.getStudentsPrivacyInformations[student.id]
        ? 'WARNING : Personnal phones numbers BUT this student doesn’t agree to be contacted for recruitment but for necessities ONLY'
        : 'Student\'s personal phone numbers'
      studentInfo.push({
        id: 9,
        icon: 'phone',
        title: props.t(title),
        color: !props.getStudentsPrivacyInformations[student.id] ? 'red' : undefined,
        caption: dataAggregationFactory(props.getStudentsExtraInformations[student.id].phones, AGGREGATION_STRING)
      })
    }

    studentInfo.push({
      id: 7,
      icon: faFileSignature,
      title: props.t('Data use'),
      caption:
  <OptInInfo
    studentId={student ? student.id : null}
  />
    })

    if (props.getUser.context === 'HOSPITAL') {
      const caption = props.selectConcatStudentsBadges[student.id]

      studentInfo.push({
        id: 8,
        icon: 'id-badge',
        title: props.t('Active Badges'),
        caption: caption === undefined ? props.t('No badge is linked to this student') : caption
      })
    }

    return (
      <InformationDisplay information={studentInfo} hideTitle />
    )
  }

  const renderBody = () => {
    let content = <LoadingSkeleton />

    if (student !== null && !loading && props.getDataReady.institutions) {
      const internships = student.internships || []

      content = (
        <div className='flex-column'>
          {renderProfileHeader()}
          {props.getUser.context !== 'SCHOOL' &&
            <div className='free-fields-section'>
              <FreeFields studentId={student.id} />
            </div>}
          {renderExportsButtons(internships)}

          {internships.map(i => {
            const internshipInfo = [
              {
                id: 1,
                icon: 'map-marker',
                title: props.t('Location'),
                caption: (i.institution === null
                  ? props.t('Without institution')
                  : i.institution.name) +
                      ' - ' +
                      (i.sector === null
                        ? props.t('Without care unit')
                        : i.sector.name)
              },
              {
                id: 2,
                icon: 'cog',
                title: props.t('Internship year'),
                caption:
                    i.internshipYear === null
                      ? props.t('Without internship year')
                      : props.t('Internship year') +
                        ' : ' + i.internshipYear.schoolYearName
              },
              {
                id: 3,
                icon: 'calendar-alt',
                title: props.t('Dates'),
                caption:
                      props.t('From') +
                      ' ' +
                      moment(i.startDate.date).format('DD MMMM YYYY') +
                      ' ' +
                      props.t('to') +
                      ' ' +
                      moment(i.endDate.date).format('DD MMMM YYYY')
              }
            ]

            // compute total
            const totalTime = computeTotalTimeInInternship(i)

            return (
              <Card
                className='fake-button'
                key={i.id}
                style={{ marginTop: '15px' }}
                onClick={() => setSelectedInternshipInfo(i)}
              >
                <div className='flex-row'>
                  <InformationDisplay
                    information={internshipInfo}
                    hideTitle
                  />
                  <div className='flex-fill' />
                  <div className='student-fiche-right-side'>
                    <InternshipStatePellet internship={i} />
                    {totalTime &&
                      <div className='internship-total-time'>
                        <label>{props.t('Total (hours)')}</label>
                        <p>{totalTime}</p>
                      </div>}
                  </div>

                </div>
              </Card>
            )
          })}
        </div>
      )
    }

    return content
  }

  const renderExportsButtons = internships => {
    if (props.getUser.context === 'SCHOOL' && props.getUser.school.managed && internships.length > 0) {
      if (props.getActiveLanguage.toUpperCase() === 'NL') {
        const className = 'standard-top-margin flex-column'

        return (
          <div className={className} style={{ justifyContent: 'space-around' }}>
            <Button
              type='default'
              onClick={handleExportInternships}
              loading={exportInternshipsLoading}
            >
              {props.t('Export internships history')}
            </Button>
          </div>
        )
      }

      const className = 'standard-top-margin flex-row'

      return (
        <div className={className} style={{ justifyContent: 'space-around' }}>
          <Button
            type='default'
            onClick={handleExportInternships}
            loading={exportInternshipsLoading}
          >
            {props.t('Export internships history')}
          </Button>
        </div>
      )
    }

    return (<div />)
  }

  return (
    <Drawer
      title={props.t('Student sheet')}
      width='550px'
      onClose={handleOnClose}
      visible={props.student !== null}
    >
      {renderBody()}
      <InternshipInfo
        internship={selectedInternshipInfo}
        onClose={() => setSelectedInternshipInfo(null)}
        onInternshipValidate={handleInternshipValidation}
      />
      <FilesManager
        entity={loadFiles ? student : null}
        entityName='Student'
        onClose={() => setLoadFiles(false)}
        userContextFiltered
        preventChanges
      />
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentInfo)
