import React, { useContext } from 'react'

import OptionsSelector from './OptionsSelector'
import SelectList from './SelectList'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'
import TitledSwitch from './TitledSwitch'
import { QUOTA_PER_PAUSE } from '../../../../utils/constants'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'

import '../../../../assets/quotas.scss'

export const SECTIONS_TITLE = 'Sections'
const PAUSES_TITLE = 'Pauses'
const SWITCH_TITLE = 'Display by pause'

const Settings = ({ getAppStates, getInstitutionOptions, selectorData, t, onCheckChanged, onSelectedSectorsChanged, onSelectedSectorsDeleted, setPauseActivationState }) => {
  const { sectors } = useContext(QuotasContext)

  const { pauseActivated } = getAppStates
  const institutionOptions = getInstitutionOptions
  let quotaPerPauseEnable = false

  const handleChange = checked => {
    setPauseActivationState(checked)
  }

  institutionOptions.forEach(option => {
    if (option.optionType.type === QUOTA_PER_PAUSE) {
      quotaPerPauseEnable = true
    }
  })

  return (
    <div className='definition-settings'>
      <SelectList
        data={sectors}
        onSelectedSectorsChanged={onSelectedSectorsChanged}
        onSelectedSectorsDeleted={onSelectedSectorsDeleted}
      />
      {quotaPerPauseEnable && (
        <TitledSwitch
          title={t(SWITCH_TITLE)}
          checked={pauseActivated}
          onChange={handleChange}
        />
      )}
      <OptionsSelector
        treeData={selectorData ?? []}
        title={pauseActivated ? PAUSES_TITLE : SECTIONS_TITLE}
        onCheckChanged={onCheckChanged}
        checkAll
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
